import { YANADOO_CDN_HOST } from 'common/config'

import LottieConversation from './assets/lottie-conversation-button.json'
import LottieQuiz from './assets/lottie-quiz-button.json'

export const levelTestAiValues = {
  HOST_CDN: `${YANADOO_CDN_HOST}/levelTestAi/`,
  metaInfo: {
    site_name: '오드리쌤과 1:1로 영어 실력 측정하기! 야나두 AI 레벨테스트 (with 오드리)',
    title: '오드리쌤과 1:1로 영어 실력 측정하기! 야나두 AI 레벨테스트 (with 오드리)',
    description:
      '개인별 맞춤 학습 피드백 제공! AI 오드리와 1:1로 대화하며 나의 영어 실력을 정확하게 체크해 보세요.',
    imgsrc: `${YANADOO_CDN_HOST}/levelTestAi/img-OG.png`
  },
  klleon: {
    scriptSrc: 'https://web.sdk.klleon.io/1.0.0/klleon-chat.umd.js',
    sdkKey: 'APP-5WIW3jDkHAndbrBRGCLU',
    avatarId: {
      quiz: '33584001-7b41-43c5-961b-da59b8d2591d',
      conversation: 'd8728de9-63f9-4fce-88ed-f02f423a1f54'
    }
  },
  lottieDefaultOptions: {
    loop: true,
    play: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  },
  selectType: [
    {
      id: 'quiz',
      lottie: LottieQuiz,
      time: 2,
      name: '퀴즈형'
    },
    {
      id: 'conversation',
      lottie: LottieConversation,
      time: 3,
      name: '대화형'
    }
  ],
  note: {
    quiz: {
      title: '퀴즈형 레벨테스트',
      list: [
        '공공장소에 있어서 말하기 어려운 분 😷',
        '아직은 스피킹에 자신 없는 분 🫢',
        '객관식이나 빠른 테스트를 선호하는 분 ✅'
      ]
    },
    conversation: {
      title: '대화형 레벨테스트',
      list: [
        '나만의 공간에 있어 대화가 가능한 분 👌',
        '스피킹 테스트에 도전하고 싶은 분 💪🏻',
        '내 실력을 정확히 테스트 해보고 싶은 분 💯'
      ]
    }
  },
  quiz: [
    {
      type: 'OX',
      timeLimit: 8,
      question: `Choose O for correct, 
      X for incorrect.`,
      kor: '나는 정말 지쳤어.',
      eng: "I'm feeling so fired up.",
      answer: 'X'
    },
    {
      type: 'OX',
      timeLimit: 8,
      question: `Choose O for correct, 
      X for incorrect.`,
      kor: '나는 정말 기뻐.',
      eng: 'I’m over the moon.',
      answer: 'O'
    },
    {
      type: 'BLANK',
      timeLimit: 10,
      question: `Choose the correct word 
      for the blank.`,
      kor: '출근할 때 지하철을 타고 가.',
      eng: 'I go to work _____ subway.',
      answers: ['on', 'by', 'with', 'in'],
      answer: 'by'
    },
    {
      type: 'BLANK',
      timeLimit: 10,
      question: `Choose the correct word 
      for the blank.`,
      kor: '기억이 나지 않아요.',
      eng: 'It doesn’t ring a _______.',
      answers: ['phone', 'call', 'light', 'bell'],
      answer: 'bell'
    },
    {
      type: 'BLANK',
      timeLimit: 10,
      question: `Choose the correct word 
      for the blank.`,
      kor: '이 커피는 내가 쏠게!',
      eng: 'This coffee is my _______!',
      answers: ['reward', 'shot', 'treat', 'thing'],
      answer: 'treat'
    },
    {
      type: 'BLANK',
      timeLimit: 10,
      question: `Choose the correct word 
      for the blank.`,
      kor: '내 화장이 떴어.',
      eng: 'My make up is _______.',
      answers: ['baked', 'cracked', 'cooked', 'caked'],
      answer: 'caked'
    },
    {
      type: 'BLANK',
      timeLimit: 10,
      question: `Choose the correct word 
      for the blank.`,
      kor: '1 달러가 부족해.',
      eng: "I'm a dollar _______ .",
      answers: ['short', 'wide', 'deep', 'long'],
      answer: 'short'
    },
    {
      type: 'BLANK',
      timeLimit: 10,
      question: `Choose the correct word 
      for the blank.`,
      kor: '몸이 조금 안 좋아요.',
      eng: "I'm feeling a bit under the _______.",
      answers: ['sea', 'sun', 'weather', 'earth'],
      answer: 'weather'
    },
    {
      type: 'TRANSLATE',
      timeLimit: 10,
      question: `Translate the following 
      sentence correctly.`,
      eng: "I'm feeling blue.",
      answers: ['나는 기분이 좋아졌어.', '나는 우울해.'],
      answer: '나는 우울해.'
    },
    {
      type: 'TRANSLATE',
      timeLimit: 10,
      question: `Translate the following 
      sentence correctly.`,
      eng: "I'm going to hit the sack.",
      answers: ['이제 자러 갈거야.', '이제 운동하러 갈거야.'],
      answer: '이제 자러 갈거야.'
    }
  ],
  quizStepDescription: [
    {
      eng: 'Hello, I’m Audrey. Let’s figure out your English level and find the perfect lessons and study tips just for you. Shall we get started?',
      kor: '안녕하세요. 오드리입니다. 여러분의 영어 레벨을 알아보고 딱 맞는 강의와 학습 팁도 알려드릴게요. 그럼, 시작해 볼까요?'
    },
    {
      title: 'OX 퀴즈',
      eng: 'Choose O if the translation is correct, or X if it is incorrect.',
      kor: `바르게 번역이 되었으면 O, 틀리면 X를 
      선택해 주세요.`
    },
    {
      title: '빈칸 채우기',
      eng: 'Questions 3 to 8 are fill-in-the-blank questions. What word fits in the blank?',
      kor: `3번부터 8번까지는 빈칸 채우기 문제입니다.
빈칸에 들어갈 단어는 무엇일까요?`
    },
    {
      title: '번역 문제',
      eng: 'The last two questions require translating English sentences into Korean.Choose the correctly interpreted sentence.',
      kor: `마지막 2개 문제는 영어로 된 문장을
한국어로 번역하는 문제입니다. 
올바르게 해석한 문장을 골라주세요.`
    }
  ],
  quizDescription: [
    'Question 1. Choose O for correct, X for incorrect.',
    'Question 2. ',
    'Question 3. Choose the correct word for the blank.',
    'Question 4.',
    'Question 5.',
    'Question 6.',
    'Question 7.',
    'Question 8.',
    'Question 9. Translate the following sentence correctly.',
    'Question 10.'
  ],
  conversationStepDescription: [
    {
      eng: 'Hello, I’m Audrey. Let’s figure out your English level and find the perfect lessons and study tips just for you. Shall we get started?',
      kor: '안녕하세요. 오드리입니다. 여러분의 영어 레벨을 알아보고 딱 맞는 강의와 학습 팁도 알려드릴게요. 그럼, 시작해 볼까요?'
    },
    {
      eng: 'Feel free to answer my questions in English!',
      kor: '제가 하는 질문에 영어로 자유롭게 답해주세요!'
    },
    {
      eng: 'For questions 7 and 8, please answer my questions and explain your reasons. Try to answer as thoroughly as possible.',
      kor: '7번과 8번 문제는 제가 하는 질문에 답변하고, 그 이유까지 설명해 주시면 됩니다. 최대한 자세히 대답해 보세요.'
    },
    {
      eng: "Only two questions left now. Keep going, you're almost there!",
      kor: '이제 단 2개의 질문만 남았습니다. 조금만 더 힘내세요!'
    }
  ],
  conversationDescription: [
    { text: 'Question 1. Where are you from?', timeLimit: 10 },
    { text: 'Question 2. What time do you usually wake up?', timeLimit: 10 },
    { text: 'Question 3. What’s the weather like today?', timeLimit: 10 },
    { text: "Question 4. What's your favorite food?", timeLimit: 10 },
    { text: 'Question 5. What do you do on weekends?', timeLimit: 17 },
    { text: 'Question 6. How do you usually spend your evenings?', timeLimit: 17 },
    { text: "Question 7. What's your favorite place to travel, and why?", timeLimit: 18 },
    { text: 'Question 8. What’s your favorite season, and why?', timeLimit: 18 },
    { text: 'Question 9. What kind of sports or exercise do you enjoy?', timeLimit: 15 },
    { text: 'Question 10. If you could try a new hobby, what would it be?', timeLimit: 15 }
  ],
  analyzing: {
    quiz: ['OX 퀴즈 분석중', '빈칸 채우기 분석중', '번역 문제 분석중'],
    conversation: [
      'CEFR 기준으로 레벨 측정중',
      '문항별 피드백 문구 작성중',
      '추천 학습 방법 분석중'
    ]
  },
  survey: {
    commons: [
      {
        index: 1,
        id: 1,
        name: 'content',
        question: (
          <>
            <em>정확한 진단을 위해</em>
            성별을 선택해 주세요.
          </>
        ),
        textType: 'image',
        options: [
          {
            value: 'male',
            image: `${YANADOO_CDN_HOST}/levelTestAi/image-survey-male.png`,
            text: '남자',
            nextQuestionId: 2
          },
          {
            value: 'female',
            image: `${YANADOO_CDN_HOST}/levelTestAi/image-survey-female.png`,
            text: '여자',
            nextQuestionId: 2
          }
        ]
      },
      {
        index: 2,
        id: 2,
        name: 'userAge',
        question: (
          <>
            <em>정확한 진단을 위해</em>
            연령대를 선택해 주세요.
          </>
        ),
        options: [
          {
            value: '10s',
            text: '10대',
            nextQuestionId: 3
          },
          {
            value: '20s',
            text: '20대',
            nextQuestionId: 3
          },
          {
            value: '30s',
            text: '30대',
            nextQuestionId: 3
          },
          {
            value: '40s',
            text: '40대',
            nextQuestionId: 3
          },
          {
            value: '50s',
            text: '50대 이상',
            nextQuestionId: 3
          }
        ]
      }
    ],
    method: {
      textType: 'description',
      index: 3,
      id: 3,
      question: `선호하는 영어 공부 
       방법은 무엇인가요?`,
      options: [
        {
          value: '아이패드',
          text: (
            <>
              공부도 최신 트렌드에 맞게!
              <b>아이패드로 AI와 스마트하게 공부하기</b>
            </>
          ),
          nextQuestionId: 'M1'
        },
        {
          value: '전용 학습 App',
          text: (
            <>
              태플이 인정한 PPP 학습법!
              <b>전용 학습 App으로 공부하기</b>
            </>
          ),
          recommended: 'aiRealTalk'
        },
        {
          value: '학습 교재',
          text: (
            <>
              역시 공부는 손필기!
              <b>학습 교재로 공부하기</b>
            </>
          ),
          recommended: 'srrFullPackage'
        },
        {
          value: '야나두 강의',
          text: (
            <>
              기본이 최고!
              <b>야나두 강의로만 공부하기</b>
            </>
          ),
          nextQuestionId: 'M4'
        }
      ]
    },
    additionals: [
      {
        index: 4,
        id: 'M1',
        question: `아이패드를 보유하고 
        있나요?`,
        options: [
          { value: 'N', text: '아니요, 아직 없어요.', recommended: 'applePackage' },
          { value: 'Y', text: '네, 가지고 있어요!', nextQuestionId: 'M11' }
        ]
      },

      {
        index: 5,
        id: 'M11',
        question: (
          <>
            <em>강의 수강을 위한</em>
            노트북이 필요하신가요?
          </>
        ),
        options: [
          { value: 'Y', text: '네, 노트북이 있으면 좋겠어요!', nextQuestionId: 'M111' },
          { value: 'N', text: '아니요, 아이패드로 충분해요.', recommended: 'newChallenge' }
        ]
      },
      {
        index: 6,
        id: 'M111',
        textType: 'image',
        question: `어떤 노트북을 
        선호하시나요?`,
        options: [
          {
            value: 'LG그램',
            image: `${YANADOO_CDN_HOST}/levelTestAi/image-survey-laptop.png`,
            text: 'LG gram',
            recommended: 'laptopPackage'
          },
          {
            value: '맥북',
            image: `${YANADOO_CDN_HOST}/levelTestAi/image-survey-mac.png`,
            text: 'MacBook Air',
            recommended: 'macbookPackage'
          }
        ]
      },
      {
        index: 4,
        id: 'M4',
        textType: 'description',
        question: `내가 생각하는 
        나의 영어 학습 수준은?`,
        options: [
          {
            value: '왕초보',
            text: (
              <>
                <b>왕초보</b>뼈대부터 튼튼하게, 새로운 마음으로 시작할래요!
              </>
            ),
            recommended: 'frontEnglish'
          },
          {
            value: '초보',
            text: (
              <>
                <b>초보</b>기초를 탄탄히, 영어 습관을 만들어 볼래요!
              </>
            ),
            recommended: 'bestAwards'
          },
          {
            value: '초중급',
            text: (
              <>
                <b>초중급</b>원어민의 영어 표현들을 배워보고 싶어요!
              </>
            ),
            recommended: 'buddytalk'
          }
        ]
      }
    ]
  },
  form: {
    COUNSEL_TIME: [
      '상담 시간',
      '10시 ~ 11시',
      '11시 ~ 12시',
      '12시 ~ 13시',
      '13시 ~ 14시',
      '14시 ~ 15시',
      '15시 ~ 16시',
      '16시 ~ 17시',
      '17시 ~ 18시',
      '아무때나'
    ]
  }
}
