import React, { useState } from 'react'

import cx from 'classnames'
import useGetFaqCategory from 'pages/service/hooks/useGetFaqCategory'
import useGetFaqList from 'pages/service/hooks/useGetFaqList'

import BtnMore from 'components/service/BtnMore'
import FaqCategory from 'components/service/faq/FaqCategory'
import FaqList from 'components/service/faq/FaqList'
import InnerCol from 'components/service/InnerCol'
import ServiceTabNav from 'components/service/ServiceTabNav'

import s from './styles/CustomerServiceFaq.module.scss'

export default function CustomerServiceFaq() {
  const [tab, setTab] = useState({ yanadoo: true, yafit: false })
  const [selected, setSelected] = useState('BEST')
  const groupCode = tab.yanadoo ? 'YANADOO_FAQ_GROUP' : 'YAFIT_FAQ_GROUP'
  const { category } = useGetFaqCategory(groupCode)
  const { faqList } = useGetFaqList({ cate: selected, group: groupCode, page: 1, size: 5 })

  const handleTab = (key) => {
    setTab((prevTab) => ({
      yanadoo: key === 'yanadoo' ? !prevTab.yanadoo : false,
      yafit: key === 'yafit' ? !prevTab.yafit : false
    }))
    setSelected('BEST')
  }

  const handleCategory = (code) => {
    setSelected(code)
  }

  return (
    <section className={s.csFaq}>
      <InnerCol innerClass={s.csInner}>
        <h3>자주 묻는 질문</h3>
        <ServiceTabNav handleTab={handleTab} tab={tab} />
        <FaqCategory category={category} selected={selected} handleCategory={handleCategory} />
        <div className={s.csTabContent}>
          <FaqList faqList={faqList} />
          <div className={s.wrapBtnMore}>
            <BtnMore title="더보기" link="/service/faq" btnClass={s.btnMore} />
          </div>
        </div>
      </InnerCol>
    </section>
  )
}
