import classNames from 'classnames'

export default function QuizDescription({ contents, classes }) {
  return (
    <div className={classNames(...classes)}>
      <b>{contents?.eng}</b>
      <p>{contents?.kor}</p>
    </div>
  )
}
