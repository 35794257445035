import { useState } from 'react'

import api from 'common/api'

export default function useGetClass() {
  const [isLoading, setIsLoading] = useState(true)
  const [classData, setClassData] = useState([])

  const getClass = (seq) => {
    api
      .get(`/v2/store/sale/package/${seq}/detail`)
      .then((response) => {
        setClassData(response?.data?.data)
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return {
    isLoading,
    classData,
    getClass
  }
}
