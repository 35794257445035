import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import useHideHeader from 'hooks/useHideHeader'

import CourseItem from './CourseItem'

import 'assets/styles/courseList.scss'

export default function CourseList() {
  const { top } = useHideHeader()
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const tabMenuLists = [
    {
      menu: '기초',
      class: '.move1'
    },
    {
      menu: '실전',
      class: '.move2'
    },
    {
      menu: '여행',
      class: '.move3'
    },
    {
      menu: '비즈니스',
      class: '.move4'
    },
    {
      menu: '오디오',
      class: '.move5'
    },
    {
      menu: '스르르',
      class: '.move6'
    },
    {
      menu: '주니어',
      class: '.move7'
    },
    {
      menu: '제 2외국어',
      class: '.move8'
    }
  ]

  // 스크롤 시 탭 메뉴 활성화 함수
  const scrollCheck = () => {
    const scrollChk = window.scrollY || document.documentElement.scrollTop
    const fixedCheck = document.querySelector('.product-tab')
    const itemList = document.querySelectorAll('.product-item')
    const tabNavList = document.querySelector('.product-tab').querySelectorAll('li')
    let windowWidth = window.innerWidth
    let headerHeight = document.querySelector('header').offsetHeight + fixedCheck.offsetHeight
    let activeCheck = false
    let beforeNavWidth = 0
    let aftereNavWidth = 0

    scrollChk >= 120 ? fixedCheck.classList.add('active') : fixedCheck.classList.remove('active')

    for (let i = itemList.length - 1; i >= 0; i--) {
      // 컨텐츠 개수만큼 반복 6 -> 0
      if (itemList[i].getBoundingClientRect().top + itemList[i].offsetHeight - headerHeight > 0) {
        // 해당 컨텐츠 위치와 window top의 거리가 0보다 크면 그 메뉴를 active
        for (let j = 0; j < tabNavList.length; j++) {
          tabNavList[j].classList.remove('active')
        }
        tabNavList[i].classList.add('active')
      }
    }

    for (let i = itemList.length - 1; i >= 0; i--) {
      if (tabNavList[i].classList.value.indexOf('active') !== -1) {
        activeCheck = true
      }
      activeCheck
        ? (beforeNavWidth += tabNavList[i].offsetWidth)
        : (aftereNavWidth += tabNavList[i].offsetWidth)
    }
    windowWidth - beforeNavWidth < 0
      ? fixedCheck.querySelector('ul').scrollTo(windowWidth, 0)
      : fixedCheck.querySelector('ul').scrollTo(0, 0)
  }

  const moveScroll = (idx) => {
    window.scrollTo(
      0,
      document.querySelectorAll('.product-item')[idx].offsetTop - `${deviceCheck ? 100 : 130}`
    )
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollCheck)

    return () => {
      window.removeEventListener('scroll', scrollCheck)
    }
  }, [])

  return (
    <>
      <div className="course-col">
        <div className="inner-col">
          <strong className="course-title">야나두 영어 강의 자세히 보기</strong>
          <p className="course-desc">하루 10분으로 완성하는 야나두 영어</p>
        </div>
        <div className={'product-tab'} style={{ top }}>
          <div className="inner-col">
            <ul>
              {tabMenuLists.map((tabMenu, idx) => {
                return (
                  <li key={idx}>
                    <button onClick={() => moveScroll(idx)} className="btn-product">
                      {tabMenu.menu}
                    </button>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div>
          <div
            className={
              'move1 product-item ' + (deviceCheck ? 'product-list-slide' : 'product-list')
            }
          >
            <CourseItem
              title={'기초'}
              subTitle={'기초 영어 말하기'}
              type={'basic'}
              subType={'speak'}
            />
            <CourseItem subTitle={'발음, 단어, 영작, 쉐도잉'} type={'basic'} subType={'etc'} />
          </div>
          <div
            className={
              'move2 product-item ' + (deviceCheck ? 'product-list-slide' : 'product-list')
            }
          >
            <CourseItem title={'실전'} subTitle={'리얼 대화영어'} type={'field'} subType={'real'} />
            <CourseItem subTitle={'미국 현지영어'} type={'field'} subType={'usa-local'} />
            <CourseItem subTitle={'원어민 상황영어'} type={'field'} subType={'native-speaker'} />
          </div>
          <div
            className={
              'move3 product-item ' + (deviceCheck ? 'product-list-slide' : 'product-list')
            }
          >
            <CourseItem
              title={'여행'}
              subTitle={'여행 영어/컬쳐 톡'}
              type={'travel'}
              subType={'culture'}
            />
          </div>
          <div
            className={
              'move4 product-item ' + (deviceCheck ? 'product-list-slide' : 'product-list')
            }
          >
            <CourseItem
              title={'비즈니스'}
              subTitle={'비즈니스 영어'}
              type={'business'}
              subType={'english'}
            />
          </div>
          <div
            className={
              'move5 product-item ' + (deviceCheck ? 'product-list-slide' : 'product-list')
            }
          >
            <CourseItem
              title={'오디오'}
              subTitle={'오디오 영어'}
              type={'audio'}
              subType={'english'}
            />
          </div>
          <div
            className={
              'move6 product-item ' + (deviceCheck ? 'product-list-slide' : 'product-list')
            }
          >
            <CourseItem
              title={'스르르 학습지'}
              subTitle={'스르르 영어회화'}
              type={'srr'}
              subType={'workbook'}
            />
          </div>
          <div
            className={
              'move7 product-item ' + (deviceCheck ? 'product-list-slide' : 'product-list')
            }
          >
            <CourseItem
              title={'주니어'}
              subTitle={'주니어 영어'}
              type={'junior'}
              subType={'english'}
            />
          </div>
          <div
            className={
              'move8 product-item ' + (deviceCheck ? 'product-list-slide' : 'product-list')
            }
          >
            <CourseItem
              title={'제2외국어'}
              subTitle={'중국어'}
              type={'second'}
              subType={'chinese'}
            />
            <CourseItem subTitle={'일본어'} type={'second'} subType={'japanese'} />
          </div>
        </div>
      </div>
    </>
  )
}
