import { YANADOO_CDN_HOST } from 'common/config'

export const levelTestAiStartValues = {
  HOST_CDN: `${YANADOO_CDN_HOST}/levelTestAi/`,
  buttonList: [
    {
      type: 'quiz',
      name: '퀴즈형',
      time: 2,
      checkList: ['공공장소에 있어서 말하기 어려운 분', '간편하고 빠른 테스트를 선호하는 분'],
      image: 'image-start-quiz.png'
    },
    {
      type: 'conversation',
      name: '대화형',
      time: 3,
      checkList: ['나만의 공간에 있어 대화가 가능한 분', '내 실력을 정확히 테스트 해보고 싶은 분'],
      image: 'image-start-conversation.png'
    }
  ],
  featureList: [
    {
      description: `지루하고 오래 걸리는
영어 레벨테스트는 그만 🖐🏻`,
      title: `단 3분으로
      테스트부터 피드백까지 끝!`
    },
    {
      description: `야나두 인기 강사가 함께할게요 😉`,
      title: `오드리쌤과 영상 통화하듯 진행되는 
      1:1 맞춤형 평가`
    },
    {
      description: `더욱 체계적이고 객관적인 테스트 👩🏻‍💻`,
      title: `CEFR 기준으로 맞춤형 피드백 및 
      학습 방법 제안`
    },
    {
      description: `쉬는 시간에도, 퇴근 후에도! 🕗`,
      title: `24시간 언제든지 
      내가 원하는 시간에 테스트 가능`
    },
    {
      description: `처음부터 끝까지 학습자 중심으로 👍🏻`,
      title: `상황과 학습 수준을 고려한
맞춤형 강의 추천`
    },
    {
      description: `맞힌 문제도 다시 확인해야죠! ✅`,
      title: `틀린 문제 포함
    모든 문제에 대한 정답과 해설 제공`
    }
  ]
}
