import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router'

import AuthService from 'services/authService'

import ModalMyInquiry from 'components/modal/ModalMyInquiry'
import MobileTitleBar from 'components/service/MobileTitleBar'
import useModalLogin from 'store/useModalLogin'

import CustomerServiceCounsel from './components/CustomerServiceCounsel'
import CustomerServiceFaq from './components/CustomerServiceFaq'
import CustomerServiceNotice from './components/CustomerServiceNotice'
import CustomerServiceSearch from './components/CustomerServiceSearch'
import CustomerServiceSurvey from './components/CustomerServiceSurvey'

import s from './CustomerService.module.scss'

export default function CustomerServiceCenter() {
  const navigate = useNavigate()
  const [inquiry, setInquiry] = useState(false)
  const { setIsModalLogin } = useModalLogin()
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  const handleInquiry = () => {
    if (!AuthService.getUserInfo()) {
      setIsModalLogin(true)
      return
    }

    setInquiry(true)
  }

  const handleBackButton = () => {
    navigate(-1)
  }

  return (
    <>
      {isMobile && <MobileTitleBar title="고객센터" onClick={handleBackButton} />}
      <article className={s.csWrap}>
        <CustomerServiceSearch />
        <CustomerServiceFaq />
        <CustomerServiceCounsel handleInquiry={handleInquiry} />
        <CustomerServiceNotice />
        <CustomerServiceSurvey />
      </article>
      {inquiry && <ModalMyInquiry btnClose handleCloseModal={() => setInquiry(false)} />}
    </>
  )
}
