import { Link } from 'react-router-dom'

import styles from './NotFound.module.scss'

export default function NotFound() {
  return (
    <section className={styles.notFound}>
      <div className={styles.inner}>
        <hgroup>
          <h2>페이지를 찾을 수 없습니다.</h2>
          <p>올바른 URL을 입력하였는지 확인하세요.</p>
        </hgroup>
        <div className={styles.notFoundImg}>
          <img
            src="https://english.yanadoocdn.com/upload/yanadoo/new/common/notFound/img_not-found.png"
            alt="404"
          />
        </div>
        <Link to="/">홈으로 이동</Link>
      </div>
    </section>
  )
}
