import { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import useSpeechToText from 'hooks/useSpeechToText'

import ConversationTimer from './ConversationTimer'
import { levelTestAiValues } from '../config'
import RecordingButton from './RecordingButton'
import styles from '../styles/ConversationBottomSheet.module.scss'

export default function ConversationBottomSheet({
  quizIndex,
  status,
  onChangeStatus,
  onCompleteRecording
}) {
  const isCompleteRef = useRef(false) // 추가된 ref
  const { transcript, handleRecognition } = useSpeechToText()
  const [animationClass, setAnimationClass] = useState('slideUp')

  // 시간 초과 됬을 때 > 다음문제로 넘어감
  const onTimeOver = () => {
    if (isCompleteRef.current) return
    isCompleteRef.current = true
    setAnimationClass('slideDown')
    setTimeout(() => {
      onCompleteRecording(transcript)
    }, 1000)
  }

  const onComplete = (answer) => {
    if (isCompleteRef.current) return
    isCompleteRef.current = true
    setAnimationClass('slideDown')
    setTimeout(() => {
      onCompleteRecording(answer)
    }, 1000)
  }

  useEffect(() => {
    isCompleteRef.current = false // quizIndex가 변경될 때 ref 초기화
  }, [quizIndex])

  return (
    <>
      <p
        className={classNames(
          styles.questionIndex,
          status === 'prepare' ? styles.show : styles.hide
        )}
      >
        Question {quizIndex}
      </p>
      {status !== 'prepare' && (
        <ConversationTimer
          disabled={isCompleteRef.current}
          timeLimit={levelTestAiValues.conversationDescription[quizIndex - 1]?.timeLimit}
          quizIndex={quizIndex}
          onTimeOver={onTimeOver}
        />
      )}
      <div className={classNames(styles.container, styles[animationClass])}>
        <RecordingButton
          disabled={isCompleteRef.current || status === 'prepare' || status === 'timeover'}
          handleRecognition={handleRecognition}
          transcript={transcript}
          status={status}
          onChangeStatus={onChangeStatus}
          onCompleteRecording={onComplete}
        />
      </div>
    </>
  )
}
