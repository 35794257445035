import { useLocation } from 'react-router'

import AuthService from 'services/authService'

import api from 'common/api'
import { YANADOO_AI_HOST } from 'common/config'
import useModalLogin from 'store/useModalLogin'

export default function useLevelTestAiCheckLimitation() {
  const location = useLocation()
  const { setIsModalLogin } = useModalLogin()
  const searchParams = new URLSearchParams(location.search)
  const isTest = !!searchParams.get('test')

  const userInfo = AuthService.getUserInfo()

  // 하루에 유형당 횟수 2번으로 제한 체크
  const checkLimitation = async (type, callback) => {
    if (!onCheckIsLogin()) return
    if (isTest) {
      callback && callback()
      return
    }
    try {
      const response = await api.get(
        `${YANADOO_AI_HOST}/level-test/usable?userSeq=${userInfo?.userSeq}&testType=${
          type === 'quiz' ? 1 : 2
        }`
      )
      if (response.data) {
        callback && callback()
      } else {
        alert(
          `오늘은 이미 ${
            type === 'quiz' ? '퀴즈형' : '대화형'
          } 레벨테스트를 완료하셨네요! \nAI 오드리 레벨테스트는 하루 2회 이용할 수 있어요.`
        )
      }
    } catch (e) {
      console.error(e)
      alert('레벨테스트 횟수를 가져올 수 없습니다. 다시 시도해주세요.')
    }
  }

  // 테스트 안할 경우 횟수 차감 리셋
  const addLimitation = async (type, callback) => {
    try {
      await api.post(`${YANADOO_AI_HOST}/level-test/plus`, {
        userSeq: userInfo?.userSeq,
        testType: type === 'quiz' ? 1 : 2
      })
      callback && callback()
    } catch (e) {
      console.error(e)

      alert('레벨테스트 횟수를 증가할 수 없습니다. 다시 시도해주세요.')
    }
  }

  // 로그인 했을 때 체크
  const onCheckIsLogin = () => {
    if (!userInfo) {
      document.body.classList.add('modal-open')
      setIsModalLogin(true)
      return false
    }
    return true
  }

  return { checkLimitation, addLimitation }
}
