import { useCallback, useEffect, useState } from 'react'

import useGetPermissionSpeech from 'hooks/useGetPermissionSpeech'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

export default function useLevelTestAiMicPermission() {
  const [isDisabled, setIsDisabled] = useState(false)
  const [isShowMicConfirmModal, setIsShowMicConfirmModal] = useState(false)
  const examType = useLevelTestAiStore((state) => state.examType)

  const { errorMessage, handleRecognition, clearErrorMessage } = useGetPermissionSpeech()

  // 마이크 허용 권한 요청하기
  const handleStart = useCallback(() => {
    handleRecognition.start()
  }, [handleRecognition])

  // 마이크 허용 거부했을 경우 >  에러메시지 확인 후 모달 띄우기
  const handleErrorMessage = useCallback((errorMessage) => {
    setIsDisabled(true)
    setIsShowMicConfirmModal(true)
  }, [])

  // 마이크 권한 확인 모달 닫기
  const onCloseMicConfirmModal = () => {
    setIsShowMicConfirmModal(false)
  }

  useEffect(() => {
    if (errorMessage.length > 0) {
      handleErrorMessage(errorMessage)
    }
  }, [errorMessage, handleErrorMessage])

  useEffect(() => {
    if (examType === 'conversation') {
      handleStart()
    }
  }, [examType, handleStart])

  useEffect(() => {
    // 페이지 이탈시 횟수 차감 리셋
    return () => {
      if (examType === 'conversation') {
        clearErrorMessage()
      }
    }
  }, [])

  return {
    isDisabled,
    isShowMicConfirmModal,
    onCloseMicConfirmModal
  }
}
