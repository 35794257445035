import React from 'react'

import useGetUser from 'hooks/useGetUser'

import { YANADOO_CDN_HOST } from 'common/config'

import styles from '../styles/FloorBtn.module.scss'

export default function FloorBtn({ onClick, disabled = false }) {
  const { user } = useGetUser()
  return (
    <div className={styles.floorBtn}>
      {user && (
        <button className={styles.bannerButton} onClick={onClick} disabled={disabled}>
          {user.userName}님을 위한 추천 상품 보기 🎁
          <img src={`${YANADOO_CDN_HOST}/levelTestAi/icon-Arrowright.png`} alt="exit" />
        </button>
      )}
    </div>
  )
}
