import { useCallback, useEffect, useState } from 'react'

import { levelTestAiValues } from '../config'

// 테스트 - 퀴즈형
export default function useLevelTestAiConversation({ onCompleteExam }) {
  const KlleonChat = window.KlleonChat

  const [answers, setAnswers] = useState([])
  const [toastMessageClass, setToastMessageClass] = useState(false)
  const [descriptionIndex, setDescriptionIndex] = useState(0)
  const [descriptionFadeClass, setDescriptionFadeClass] = useState(null)
  const [quizIndex, setQuizIndex] = useState(0)
  const [modeType, setModeType] = useState('description') // description, conversation
  const [isResponseEnd, setIsResponseEnd] = useState(false)
  const [isEcho, setIsEcho] = useState(false)
  const [status, setStatus] = useState('prepare') // prepare, recording, prepareEnd, timeover

  // 정답 버튼 클릭시 발화 & 다음 문제로 이동
  const onCompleteRecording = useCallback(
    async (answer) => {
      const newAnswer = [...answers]
      newAnswer[quizIndex - 1] = answer
      setAnswers(newAnswer)
      setStatus(null)

      setTimeout(() => {
        switch (quizIndex) {
          case 6:
            setDescriptionFadeClass('fadeIn')
            setDescriptionIndex(2)
            break
          case 8:
            setDescriptionFadeClass('fadeIn')
            setDescriptionIndex(3)
            break

          case 10:
            onCompleteExam(newAnswer)
            break

          default: // 다음 문제로 이동 & 문제설명 발화
            setStatus('prepare')
            if (quizIndex < 10) setQuizIndex((prev) => prev + 1)
            break
        }
      }, 800)
    },
    [quizIndex, answers]
  )

  // 아바타 이벤트 구독하는 함수 : 발화 끝났을 때 다음 단계로 넘어가는 로직
  const moveToNextDescription = useCallback(() => {
    if (modeType === 'description') {
      // 설명일 경우
      switch (descriptionIndex) {
        case 0:
          setDescriptionFadeClass('fadeOutFirst')
          setTimeout(() => {
            setDescriptionIndex(1) // 인트로 발화 후 > 다음 설명 발화
          }, 700)
          break
        case 1:
          // 인트로 발화 후 > 설명 토스트 띄움
          setToastMessageClass('slideDown')
          setTimeout(() => {
            setToastMessageClass('slideUp')
            setDescriptionFadeClass('fadeOutSecond')
          }, 2000)
          setTimeout(() => {
            setQuizIndex(1)
          }, 3000)
          break
        case 2:
          setDescriptionFadeClass('fadeOut')
          setQuizIndex(7)
          break
        case 3:
          setDescriptionFadeClass('fadeOut')
          setQuizIndex(9)
          break
        default:
          break
      }
    } else {
      //문제일 경우
      setIsEcho(false)
    }
  }, [descriptionIndex, modeType])

  // 퀴즈가 진행될때 퀴즈설명 발화
  const echoDescription = useCallback(
    (message) => {
      if (message?.length) {
        setIsEcho(true)
        KlleonChat?.echo(message)
      }
    },
    [KlleonChat]
  )

  // 클레온 발화 이벤트 구독
  useEffect(() => {
    const handleChatEvent = (result) => {
      setIsResponseEnd(result.chat_type === 'RESPONSE_IS_ENDED')
    }

    KlleonChat?.onChatEvent(handleChatEvent)
    return () => {
      KlleonChat?.destroy()
    }
  }, [])

  // 설명 번호가 바뀌었을 경우 해당 설명 발화
  useEffect(() => {
    if (descriptionIndex > 1) {
      setDescriptionFadeClass('fadeIn')
    }
    echoDescription(levelTestAiValues.conversationStepDescription[descriptionIndex]?.eng)
    setModeType('description')
  }, [descriptionIndex])

  // 퀴즈 번호가 바뀌었을 경우 해당 퀴즈 설명 발화
  useEffect(() => {
    if (quizIndex) {
      echoDescription(levelTestAiValues.conversationDescription[quizIndex - 1]?.text)
      setModeType('conversation')
      setStatus('prepare')
    }
  }, [quizIndex])

  // 발화가 끝났을 경우 다음 대화로 넘어감
  useEffect(() => {
    if (isResponseEnd) {
      moveToNextDescription()
    }
  }, [isResponseEnd])

  useEffect(() => {
    if (!isEcho) {
      setStatus('prepareEnd')
    }
  }, [isEcho])

  return {
    mode: modeType,
    quizIndex,
    status,
    descriptionIndex,
    toastMessageClass,
    descriptionFadeClass,
    onCompleteRecording,
    onChangeStatus: setStatus
  }
}
