import { useEffect } from 'react'

import useGetUser from 'hooks/useGetUser'

import api from 'common/api'
import { YANADOO_AI_HOST } from 'common/config'
import useLevelTestAiStore from 'store/useLevelTestAiStore'

import { levelTestAiResultValues } from '../config'

export default function useGetInteractiveFeedback({ onCompleteAnalyzing }) {
  const { user } = useGetUser()

  const examType = useLevelTestAiStore((state) => state.examType)
  const answers = useLevelTestAiStore((state) => state.answers)
  const setFeedbackData = useLevelTestAiStore((state) => state.setFeedbackData)
  const userSeq = user ? user.userSeq : null

  // 대화형 테스트 결과 가져오기
  const getConversationFeedback = async () => {
    try {
      const data = levelTestAiResultValues.conversationQuestions.map((question, index) => ({
        userSeq: userSeq || null,
        num: index + 1,
        question,
        answer: answers[index] || ''
      }))
      const response = await api.post(`${YANADOO_AI_HOST}/level-test/interactive/feedback`, data)
      setFeedbackData(response.data)
    } catch (e) {
      alert('대화형 테스트 결과를 가져오는 중 오류가 발생했습니다.')
      console.error(e)
    } finally {
      onCompleteAnalyzing()
    }
  }

  useEffect(() => {
    if (userSeq) {
      if (examType === 'conversation') {
        getConversationFeedback()
      } else {
        setTimeout(() => {
          onCompleteAnalyzing()
        }, 6000)
      }
    }
  }, [userSeq, examType])
}
