import React from 'react'

import cx from 'classnames'

import { categoryListValues } from '../config'
import s from './CategoryNav.module.scss'

export default function CategoryNav({ type, selected, handleDataFilter }) {
  const categoryNav = categoryListValues.nav

  return type === 'button' ? (
    <section className={s.categoryBtnNav}>
      <div className={s.innerCol}>
        <h3>더 많은 강의 보기</h3>
        {categoryNav.map((nav, index) => (
          <button
            key={index}
            className={cx({ [s.active]: nav.type === selected })}
            onClick={() => handleDataFilter(nav.type)}
          >
            {nav.title.button}
          </button>
        ))}
      </div>
    </section>
  ) : (
    <section className={s.categoryListNav}>
      <div className={s.innerCol}>
        <h3>
          더 많은 강의 보기
          <span>내게 맞는 강의를 쉽게 찾아보세요</span>
        </h3>
        <ul>
          {categoryNav.map((nav, index) => (
            <li key={index}>
              <button onClick={() => handleDataFilter(nav.type)} className={s[nav.type]}>
                {nav.title.list}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
