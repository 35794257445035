import ModalCounselComplete from 'components/promotion/modal/ModalCounselComplete'
import ModalCounselDuplicated from 'components/promotion/modal/ModalCounselDuplicated'

import ModalQuestionForm from './ModalQuestionForm'
import styles from './SectionForm.module.scss'
import ModalPrivacyAgree from '../../modal/ModalPrivacyAgree'
import ModalWrap from '../../modal/ModalWrap'
import {
  AGE_OPTION,
  COUNSEL_TIME,
  dbCollectionFormValues,
  DEVICE_OPTION,
  DEVICE_OPTION2,
  STUDY_GOAL
} from '../config'
import useDbCollectionForm from '../hooks/useDbCollectionForm'

export default function SectionForm({ promotion, isPerformance, customButton }) {
  const {
    category,
    buttonColor,
    buttonBackground,
    hasDeviceOption,
    noCounselTime,
    hasGoalOption,
    hasAgeOption,
    hasStudyDevice
  } = dbCollectionFormValues[promotion]

  const {
    formRef,
    formData,
    modalControl,
    allChecked,
    formDataInit,
    setFormData,
    setAllChecked,
    handleInputChange,
    handleCheckboxChange,
    handleModalToggle,
    koreanNameCheck,
    handleOpenQuestionModal,
    handleAllCheckboxChange,
    handleSubmit
  } = useDbCollectionForm(promotion)

  const questionProps = {
    formDataInit,
    modalControl,
    handleModalToggle,
    setFormData,
    setAllChecked
  }

  return (
    <>
      <form
        ref={formRef}
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault()
          isPerformance ? handleOpenQuestionModal() : handleSubmit(`${category}2`)
        }}
      >
        <ul className={styles.fields}>
          {hasDeviceOption && (
            <li>
              <label htmlFor="content">
                기기 옵션
                <span>*택 1/선택한 기기 옵션에 대한 상담과 혜택이 제공됩니다</span>
              </label>
              <div className={styles.selectContainer}>
                <div className={styles.counselOptions}>
                  <select id="content" onChange={handleInputChange}>
                    {DEVICE_OPTION.map((item, index) => {
                      return (
                        <option key={`${item + index}`} value={index === 0 ? '' : item}>
                          {item}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </li>
          )}
          <li>
            <label htmlFor="userName">이름</label>
            <input
              id="userName"
              type="text"
              value={formData.userName}
              onChange={handleInputChange}
              onKeyUp={koreanNameCheck}
              placeholder="이름을 입력해주세요"
            />
          </li>
          <li>
            <label htmlFor="phone">휴대폰 번호</label>
            <input
              type="tel"
              id="phone"
              value={formData.phone}
              placeholder="-없이 작성해주세요"
              onChange={handleInputChange}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '')
              }}
            />
          </li>
          {!noCounselTime && (
            <li>
              <label htmlFor="counselTime">전화 상담</label>
              <div className={styles.selectContainer}>
                <p className={styles.counselTitle}>
                  <span>상담 가능 시간</span>
                  <span>평일 : 10:00 ~ 18:00</span>
                </p>
                <div className={styles.counselOptions}>
                  <select id="counselTime" onChange={handleInputChange}>
                    {COUNSEL_TIME.map((item, index) => (
                      <option key={`${item + index}`} value={index === 0 ? '' : item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </li>
          )}
          {hasAgeOption && (
            <li>
              <label htmlFor="userAge">연령대</label>
              <div className={styles.selectContainer}>
                <select id="userAge" onChange={handleInputChange} value={formData.userAge}>
                  {AGE_OPTION.map((item, index) => (
                    <option
                      disabled={index === 0}
                      hidden={index === 0}
                      key={`${item + index}`}
                      value={index === 0 ? '' : item}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </li>
          )}
          {hasStudyDevice && (
            <li>
              <label htmlFor="studyDevice">학습기기</label>
              <div className={styles.selectContainer}>
                <div className={styles.counselOptions}>
                  <select
                    id="studyDevice"
                    onChange={handleInputChange}
                    value={formData.studyDevice || ''}
                  >
                    {DEVICE_OPTION2.map((item, index) => {
                      return (
                        <option
                          disabled={index === 0}
                          hidden={index === 0}
                          key={`${item + index}`}
                          value={index === 0 ? '' : item}
                        >
                          {item}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </li>
          )}
          {hasGoalOption && (
            <li>
              <label htmlFor="goal">학습 목표</label>
              <div className={styles.selectContainer}>
                <select id="goal" onChange={handleInputChange} value={formData.goal || ''}>
                  {STUDY_GOAL.map((item, index) => (
                    <option
                      disabled={index === 0}
                      hidden={index === 0}
                      key={`${item + index}`}
                      value={index === 0 ? '' : item}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </li>
          )}
        </ul>

        <ul className={styles.formAgree}>
          <li>
            <label>
              <input
                type="checkbox"
                id="allAgree"
                checked={allChecked}
                onChange={handleAllCheckboxChange}
              />
              <em></em>
              전체 동의
            </label>
          </li>
          <li>
            <label>
              <input
                type="checkbox"
                id="privacyAgreeYn"
                checked={formData.privacyAgreeYn}
                onChange={handleCheckboxChange}
              />
              <em></em>
              개인정보 수집·이용 동의 [필수]
              <button type="button" onClick={() => handleModalToggle('privacy')}>
                [약관보기]
              </button>
            </label>
          </li>
          <li>
            <label>
              <input
                type="checkbox"
                id="smsAgreeYn"
                checked={formData.smsAgreeYn}
                onChange={handleCheckboxChange}
              />
              <em></em>
              SMS 광고 및 마케팅 이용 동의 [선택]
            </label>
          </li>
        </ul>
        {customButton ? (
          customButton
        ) : (
          <button type="submit" className={styles.submitButton}>
            빠른 상담하기
          </button>
        )}
      </form>

      {modalControl.privacy && (
        <ModalWrap>
          <ModalPrivacyAgree
            toggle={() => {
              handleModalToggle('privacy')
            }}
          />
        </ModalWrap>
      )}

      {modalControl.question && (
        <ModalQuestionForm promotion={promotion} formData={formData} {...questionProps} />
      )}

      {modalControl.success && (
        <ModalCounselComplete
          onClose={() => {
            handleModalToggle('success')
            modalControl.question && handleModalToggle('question')
          }}
          buttonColor={buttonColor}
          buttonBackground={buttonBackground}
        />
      )}

      {modalControl.duplicate && (
        <ModalCounselDuplicated
          onClose={() => {
            handleModalToggle('duplicate')
            modalControl.question && handleModalToggle('question')
          }}
          buttonColor={buttonColor}
          buttonBackground={buttonBackground}
        />
      )}
    </>
  )
}
