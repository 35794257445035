import React from 'react'

import useHandlePagination from 'hooks/useHandlePagination'
import useGetFaqFilter from 'pages/service/hooks/useGetFaqFilter'

import FaqList from 'components/service/faq/FaqList'
import InnerCol from 'components/service/InnerCol'
import Pagination from 'components/service/Pagination'

import s from './FaqSearchList.module.scss'

export default function FaqSearchList({ keyword }) {
  const { faqList } = useGetFaqFilter(keyword)
  const { currentPage, totalPages, currentData, handlePageChange } = useHandlePagination(
    faqList,
    10
  )
  return (
    <section className={s.faqSearchList}>
      <InnerCol innerClass={s.searchListInner}>
        <hgroup className={s.searchTitle}>
          <h4>
            <span>{keyword}</span>에 대한 검색결과
          </h4>
          <span className={s.searchCount}>총 {faqList.length}개</span>
        </hgroup>
        {faqList.length < 1 ? <NotResult /> : <FaqList faqList={currentData} />}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </InnerCol>
    </section>
  )
}

function IcoAlert() {
  return (
    <div className={s.emptyIcon}>
      <img
        src="https://english.yanadoocdn.com/upload/yanadoo/new/search/icon_result-none.png"
        alt="icon none"
      ></img>
    </div>
  )
}

function NotResult() {
  return (
    <div className={s.searchEmpty}>
      <div className={s.notResult}>
        <IcoAlert />
        <p>검색 결과가 없습니다.</p>
      </div>
      <div className={s.searchTip}>
        <strong>검색 TIP</strong>
        <ul>
          <li>단어의 철자가 정확한지 확인해 주세요.</li>
          <li>검색어의 단어 수를 줄이거나 다른 검색어로 검색해 보세요.</li>
          <li>일반적인 단어로 다시 검색해 보세요.</li>
        </ul>
      </div>
    </div>
  )
}
