/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'

import classNames from 'classnames'
import useGetInteractiveFeedback from 'pages/levelTestAi/result/hooks/useGetInteractiveFeedback'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

import LottieAnalyzingConversation from '../assets/lottie-analyzing-conversation.json'
import LottieAnalyzingQuiz from '../assets/lottie-analyzing-quiz.json'
import { levelTestAiValues } from '../config'
import styles from '../styles/StepAnalyzing.module.scss'

export default function StepAnalyzing({ onCompleteAnalyzing }) {
  const examType = useLevelTestAiStore((state) => state.examType)
  const [animation, setAnimation] = useState(true)

  useGetInteractiveFeedback({ onCompleteAnalyzing })

  useEffect(() => {
    const hideTimer = setInterval(() => {
      setAnimation(false)
    }, 8000)
    const showTimer = setInterval(() => {
      setAnimation(true)
    }, 8100)
    return () => {
      clearInterval(hideTimer)
      clearInterval(showTimer)
    }
  }, [])

  return (
    <div className={classNames(styles.container, styles[examType])}>
      <div className={styles.imageWrap}>
        <Lottie
          className={styles.lottie}
          {...levelTestAiValues.lottieDefaultOptions}
          animationData={examType === 'quiz' ? LottieAnalyzingQuiz : LottieAnalyzingConversation}
        />
        <img src={`${levelTestAiValues.HOST_CDN}image-analyzing-${examType}.png`} alt={examType} />
      </div>
      <p className={styles.title}>
        AI 레벨테스트
        <br />
        <b>결과</b>를 분석하고 있어요...
      </p>
      <ul className={classNames(styles.list, animation && styles.active)}>
        {levelTestAiValues.analyzing[examType]?.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  )
}
