import { useReducer, useState } from 'react'
import { useNavigate } from 'react-router'

import useConsultation from 'hooks/useConsultation'
import { useSetGrade } from 'pages/levelTestAi/result/hooks/useSetGrade'
import useSetState from 'pages/levelTestAi/result/hooks/useSetState'

import alertMessage from 'common/alertMessage'
import { getUtm } from 'components/promotion/utile/utile'
import useLevelTestAiStore from 'store/useLevelTestAiStore'

import { levelTestAiResultValues } from 'pages/levelTestAi/result/config'

export default function useLevelTestAiCounsel({ survey }) {
  const navigation = useNavigate()
  const examType = useLevelTestAiStore((state) => state.examType)
  const [allChecked, setAllChecked] = useState(false)
  const [isShowPrivacyModal, handlePrivacyModalToggle] = useReducer((state) => !state, false)
  const [isShowRecheckModal, handleRecheckModalToggle] = useReducer((state) => !state, false)
  const { score } = useSetState() // 점수 계산
  const { level } = useSetGrade(score)
  const [formData, setFormData] = useState({
    category: 'levelTestAi',
    privacyAgreeYn: false,
    smsAgreeYn: false,
    userAge: survey?.answers[1]?.text,
    utm: getUtm() || ''
  })

  const { onPostUserConsultation } = useConsultation()

  // 전체 동의 클릭시
  const handleAllCheckboxChange = (e) => {
    const { checked } = e.target
    setAllChecked(checked)
    setFormData({
      ...formData,
      privacyAgreeYn: checked,
      smsAgreeYn: checked
    })
  }

  // 동의항목 체크박스 클릭시
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target
    setFormData((prev) => {
      const newFormData = { ...prev, [id]: checked }

      const allChecked = newFormData.privacyAgreeYn && newFormData.smsAgreeYn
      setAllChecked(allChecked)

      return newFormData
    })
  }

  // 상담신청 폼 제출시
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!formData.privacyAgreeYn) {
      alert(alertMessage.FORM_PRIVACY_REQUIRED)
      return false
    }
    if (!formData.smsAgreeYn) {
      handleRecheckModalToggle()
      return false
    }
    onClickSmsAgree(true)
  }

  // 상담내용 생성
  const getSurveyContents = () => {
    const contents = {
      '테스트 유형': examType === 'quiz' ? '퀴즈형' : '대화형',
      Level: level,
      성별: survey?.answers[0]?.text,
      연령대: formData.userAge,
      '선호 공부 방법': survey?.answers[2]?.value
    }

    const productContents = {
      '추천 상품': levelTestAiResultValues.recommended[survey?.recommended]
        ? levelTestAiResultValues.recommended[survey?.recommended]?.products
            .map((item) => item.name?.replace('\n', ''))
            .join(', ')
        : '없음',
      '레벨별 추천 상품': levelTestAiResultValues.productRecommended[`lv${level}`]?.productName
    }

    const hasIpadQuestion = survey?.answers?.filter((item) => item?.id === 'M1')
    const hasLaptopQuestion = survey?.answers?.filter((item) => item?.id === 'M11')
    const hasPreferLaptopQuestion = survey?.answers?.filter((item) => item?.id === 'M111')
    const hasStudyLevelQuestion = survey?.answers?.filter((item) => item?.id === 'M4')

    if (hasIpadQuestion.length) {
      contents['아이패드 보유 여부'] = hasIpadQuestion[0]?.value === 'Y' ? '보유' : '미보유'
    }
    if (hasLaptopQuestion.length) {
      contents['노트북 필요여부'] = hasLaptopQuestion[0]?.value === 'Y' ? '필요' : '불필요'
    }
    if (hasPreferLaptopQuestion.length) {
      contents['선호 노트북'] = hasPreferLaptopQuestion[0]?.value
    }
    if (hasStudyLevelQuestion.length) {
      contents['학습 수준'] = hasStudyLevelQuestion[0]?.value
    }
    return JSON.stringify({ ...contents, ...productContents })
      ?.replace(/"/g, '')
      .replace(/{/g, '')
      .replace(/}/g, '')
  }

  // SMS 수신동의 클릭여부 저장해서 내부 상담 전송 & 결과 페이지로 이동 처리
  const onClickSmsAgree = async (isAgree) => {
    try {
      const content = getSurveyContents()
      const reqBody = {
        ...formData,
        privacyAgreeYn: formData.privacyAgreeYn ? 'Y' : 'N',
        smsAgreeYn: isAgree ? 'Y' : 'N',
        content
      }
      onPostUserConsultation(reqBody, (response) => {
        if (response.data.data) {
          alert(alertMessage.CONSULTATION_COMPLETE)
        } else {
          alert(alertMessage.CONSULTATION_DUPLICATED)
        }
        navigation('/levelTestAi/result')
      })
    } catch (error) {
      console.error(error)
    }
  }

  // 레벨테스트 결과만 보기 클릭시 결과 페이지로 이동 처리
  const onClickResult = () => {
    navigation('/levelTestAi/result')
  }

  return {
    allChecked,
    isShowRecheckModal,
    isShowPrivacyModal,
    formData,
    handlePrivacyModalToggle,
    handleAllCheckboxChange,
    handleCheckboxChange,
    handleSubmit,
    onClickSmsAgree,
    onClickResult
  }
}
