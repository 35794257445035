import { useNavigate } from 'react-router'

import { levelTestAiValues } from 'pages/levelTestAi/exam/config'

import styles from '../styles/TopSection.module.scss'

export default function TopSection() {
  const navigation = useNavigate()

  const onClickStart = () => {
    navigation('/levelTestAi/exam')
  }

  return (
    <div className={styles.container}>
      <div className={styles.textWrap}>
        <p className={styles.description}>
          <b>오드리쌤</b>의 <b>1:1</b> 영어 학습 진단
        </p>
        <p className={styles.title}>AI 레벨테스트</p>
      </div>
      <img
        className={styles.bubble}
        src={`${levelTestAiValues.HOST_CDN}icon-start-bubble.png`}
        alt="With Audrey"
      />
      <div className={styles.video}>
        <video
          loop
          muted
          playsInline
          autoPlay
          preload="metadata"
          src={`${levelTestAiValues.HOST_CDN}video-pc-audrey.mov#t=0.5`}
        >
          <source src={`${levelTestAiValues.HOST_CDN}video-pc-audrey.mov`} type="video/mp4" />
        </video>
      </div>
      <div className={styles.bottom}>
        <button onClick={onClickStart} className={styles.startButton}>
          <img
            className={styles.text}
            src={`${levelTestAiValues.HOST_CDN}image-start-top-title.png`}
            alt="레벨테스트 시작하기"
          />
          <img
            className={styles.img}
            src={`${levelTestAiValues.HOST_CDN}icon-start-arrow.png`}
            alt="arrow"
          />
        </button>
        <p>
          <img src={`${levelTestAiValues.HOST_CDN}icon-start-info.png`} alt="info" /> AI
          레벨테스트는 일 이용 횟수가 제한되어 있습니다.
          <br />
          테스트 진행을 위한 준비를 마친 후 시작해 주세요.
        </p>
        <p>
          <img src={`${levelTestAiValues.HOST_CDN}icon-start-info.png`} alt="info" />
          iOS 기종에서의 원활한 테스트를 위하여 <br />
          스피커 또는 유선 이어폰 사용을 권장드립니다.
        </p>
      </div>
    </div>
  )
}
