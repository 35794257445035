import { useEffect, useState } from 'react'

function useScript(src) {
  // 스크립트 로딩 상태를 관리하기 위한 상태를 정의
  const [loading, setLoading] = useState(true) // 스크립트가 로딩 중인지 여부를 관리
  const [error, setError] = useState(null) // 스크립트 로딩 중 에러 발생 시 저장할 상태

  useEffect(() => {
    // 동일한 src를 가진 스크립트가 이미 로드되어 있는지 확인
    let script = document.querySelector(`script[src="${src}"]`)

    // 스크립트가 성공적으로 로드되었을 때 호출되는 함수
    const handleLoad = () => {
      setLoading(false) // 로딩 완료 상태로 설정
      setError(null) // 에러 상태를 초기화
    }

    // 스크립트 로딩에 실패했을 때 호출되는 함수
    const handleError = (error) => {
      setLoading(false) // 로딩 실패로 설정
      setError(error) // 에러 상태에 해당 에러를 설정
    }
    // 스크립트가 이미 로드되지 않았다면 새로 로드
    if (!script) {
      script = document.createElement('script') // 새로운 script 요소 생성
      script.src = src // 스크립트의 src 설정
      script.async = true // 비동기적으로 스크립트 로드 설정
      // 스크립트 로드 성공/실패 이벤트 리스너 등록
      script.addEventListener('load', handleLoad)
      script.addEventListener('error', handleError)
      // 문서의 body에 스크립트를 추가하여 로딩을 시작
      document.body.appendChild(script)
    } else {
      // 스크립트가 이미 존재하는 경우, 로드 상태를 확인
      if (document.readyState === 'complete' || document.readyState === 'loaded') {
        // 스크립트가 이미 로드된 경우
        setLoading(false) // 로딩 완료 상태로 설정
      } else {
        // 스크립트가 아직 로드 중인 경우, 로드 성공/실패 이벤트 리스너 등록
        script.addEventListener('load', handleLoad)
        script.addEventListener('error', handleError)
      }
    }

    // 컴포넌트가 언마운트되거나 src가 변경될 때 이벤트 리스너를 제거하는 정리 함수 반환
    return () => {
      script.removeEventListener('load', handleLoad) // 'load' 이벤트 리스너 제거
      script.removeEventListener('error', handleError) // 'error' 이벤트 리스너 제거
    }
  }, [src]) // src가 변경될 때마다 useEffect 재실행

  // 스크립트 로딩 상태와 에러 상태를 반환
  return [loading, error]
}

export default useScript
