import classNames from 'classnames'

import QuizBottomSheet from './QuizBottomSheet'
import QuizDescription from './QuizDescription'
import { levelTestAiValues } from '../config'
import ExamTitle from './ExamTitle'
import useLevelTestAiQuiz from '../hooks/useLevelTestAiQuiz'
import styles from '../styles/StepExam.module.scss'

export default function Quiz({ onCompleteExam }) {
  const { descriptionIndex, quizIndex, mode, onClickAnswer, stopEcho, descriptionFadeClass } =
    useLevelTestAiQuiz({
      onCompleteExam
    })

  const contents = levelTestAiValues.quizStepDescription?.[descriptionIndex]
  const commonDescriptionClasses = [styles.quizDescription, styles[descriptionFadeClass]]

  return (
    <>
      <div className={styles.examTitleBg}></div>
      {mode === 'quiz' ? (
        <QuizBottomSheet quizIndex={quizIndex} onClickAnswer={onClickAnswer} stopEcho={stopEcho} />
      ) : (
        <ExamTitle title={contents?.title} />
      )}

      {/* 발화 설명글 (애니메이션 구현으로 분기처리함) */}
      {descriptionIndex > 1 ? (
        <QuizDescription contents={contents} classes={commonDescriptionClasses} />
      ) : (
        <div className={classNames(...commonDescriptionClasses)}>
          {descriptionIndex === 0 && (
            <QuizDescription
              contents={levelTestAiValues.quizStepDescription?.[0]}
              classes={[styles.quizDescriptionFirst]}
            />
          )}
          <QuizDescription
            contents={levelTestAiValues.quizStepDescription?.[1]}
            classes={[styles.quizDescriptionSecond]}
          />
        </div>
      )}
    </>
  )
}
