import ModalWrap from 'components/promotion/modal/ModalWrap'

import styles from '../styles/ModalMicConfirm.module.scss'

export default function ModalMicConfirm({ onClickConfirm, onClickMoveToMain }) {
  return (
    <ModalWrap>
      <div className={styles.modal}>
        <p className={styles.title}>
          <b>잠깐!</b> 마이크 권한을 확인해 주세요!
        </p>
        <p className={styles.description}>
          마이크 권한이 없으면 AI 레벨테스트 이용이 어려워요.😥
          <br />
          아래 방법으로 재시도해 주세요.
        </p>
        <ul className={styles.infoBox}>
          <li>
            <span>공통</span>
            <p>
              AI 레벨테스트 {'>'} 대화형 {'>'} 테스트 시작 전 마이크 접근 알림이 뜨면 "허용" 버튼
              선택
            </p>
          </li>
          <li>
            <span>아이폰</span>
            <p>
              설정 {'>'} 개인정보 보호 및 권한 {'>'} 마이크 {'>'} 내가 접속한 App의 마이크 권한 "ON"
              (safari인 경우: 설정 {'>'} safari 검색 {'>'} 마이크 {'>'} “허용”)
            </p>
          </li>
          <li>
            <span>안드로이드폰</span>
            <p>
              설정 {'>'} 애플리케이션 {'>'} 내가 접속한 App {'>'} 권한 {'>'} 마이크 {'>'} “앱 사용
              중에만 허용” or “항상 확인”
            </p>
          </li>
        </ul>
        <p className={styles.infoText}>
          ✅ 모든 설정 후 <b>"새로고침"</b> 또는{' '}
          <b>
            "AI 레벨테스트"를 <br />
            재접속
          </b>
          해야 원활히 진행됩니다.
        </p>
        <div className={styles.buttonWrap}>
          <button onClick={onClickMoveToMain}>메인으로 이동</button>
          <button onClick={onClickConfirm}>네, 이해했어요!</button>
        </div>
      </div>
    </ModalWrap>
  )
}
