import React, { useState, useEffect } from 'react'

import thumbnailImg from '../images/img_realreview_thumbnail.png'
import speech from '../images/img_speech_bubble.png'
import styles from '../styles/VideoSection.module.scss'

export default function VideoSection({ setShowThumbnail, showThumbnail, isPlay, setIsPlay }) {
  // 비디오 재생 상태를 변경하는 함수
  const handlePlayVideo = () => {
    setIsPlay(true) // 비디오를 재생하도록 상태 업데이트
  }

  const handlePauseVideo = () => {
    setIsPlay(false) // 비디오를 정지하도록 상태 업데이트
  }

  useEffect(() => {
    if (!isPlay) {
      setShowThumbnail(true) // 비디오가 멈추면 썸네일을 다시 보여줌
    }
  }, [isPlay, setShowThumbnail])

  return (
    <section className={styles.VideoSection}>
      <div>
        <p className={styles.subTitle}>야나두 수강생 배우 진선규님의</p>
        <p className={styles.title}>리얼 학습 후기를 만나보세요!</p>
      </div>

      <div className={styles.speech}>
        <div className={styles.verticalLine}></div>
        <p>예전의 저에게 말해주고 싶었습니다.</p>
        <img
          src={speech}
          alt="선규야 빨리 영어 공부 시작해 지금 그때 시작해야 돼!"
          className={styles.thumbnail}
        />
      </div>

      <div className={styles.videoWrap}>
        {/* 썸네일을 보여주고 클릭하면 비디오를 재생하도록 */}
        {showThumbnail && !isPlay ? (
          <button
            className={`${styles.btnVideoPlay} ${styles.hideIframe}`}
            onClick={handlePlayVideo} // 썸네일 클릭 시 비디오 재생
          >
            <img
              src={thumbnailImg}
              alt="진선규님의 리얼 후기 썸네일"
              className={styles.thumbnail}
            />
          </button>
        ) : isPlay ? (
          <div className={styles.videoBox}>
            <iframe
              src="https://www.youtube.com/embed/vXhU6orteaY?autoplay=1&mute=1&si=F1YEkRG7wusNPwMd"
              title="진선규님의 리얼 후기"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            <button
              className={styles.btnPause}
              onClick={handlePauseVideo} // 비디오 정지
            >
              정지
            </button>
          </div>
        ) : (
          <button
            className={`${styles.btnVideoPlay} ${styles.hideIframe}`}
            onClick={handlePlayVideo} // 썸네일 클릭 시 비디오 재생
          >
            <img
              src={thumbnailImg}
              alt="진선규님의 리얼 후기 썸네일"
              className={styles.thumbnail}
            />
          </button>
        )}
      </div>
    </section>
  )
}
