import tenMinPackage from './images/img_10min_package.png'
import mainImglv1 from './images/img_ai_audrey_lv1.png'
import mainImglv2 from './images/img_ai_audrey_lv2.png'
import mainImglv3 from './images/img_ai_audrey_lv3.png'
import mainImglv4 from './images/img_ai_audrey_lv4.png'
import mainImglv5 from './images/img_ai_audrey_lv5.png'
import mainImglv6 from './images/img_ai_audrey_lv6.png'
import aiBase from './images/img_ai_base.png'
import aiFullPackage from './images/img_ai_fullpackage.png'
import aiNative from './images/img_ai_native.png'
import aiRealTalk from './images/img_ai_realtalk.png'
import lv1lv2ProductImage from './images/img_ai_recommend-lv1-lv2-product.png'
import lv3lv4ProductImage from './images/img_ai_recommend-lv3-lv4-product.png'
import lv5lv6ProductImage from './images/img_ai_recommend-lv5-lv6-product.png'
import appleFullPackage from './images/img_apple_fullpacakge.png'
import buddyTalk from './images/img_buddy_talk.png'
import frontEnglish from './images/img_front_english.png'
import ipad10 from './images/img_ipad10.png'
import ipadAir from './images/img_ipad_air.png'
import lgGramPackage from './images/img_lg_gram.png'
import macbookAirPackage from './images/img_macbook_air.png'
import newPacage from './images/img_new_package.png'
import srrMaster from './images/img_srr_master.png'
import srrNativ from './images/img_srr_native.png'

export const levelTestAiResultValues = {
  levelTestAiData: {
    quiz: [
      'O',
      'X',
      'by',
      'light',
      'shot',
      'baked',
      'short',
      'sea',
      '나는 우울해.',
      '이제 운동하러 갈거야.'
    ],
    conversation: {
      description: 'AI 레벨테스트 대화형 평가 및 피드백',
      learningMethod: [
        '영어 문장을 만들 때는 주어, 동사, 목적어의 순서를 잘 기억하고 연습해야 해요.',
        '일상 대화를 잘 이해하기 위해 영어 뉴스나 팟캐스트를 듣는 것도 좋은 방법이에요.'
      ],
      level: 2,
      problems: [
        {
          number: 1,
          question: 'Where are you from?',
          answer: 'My name is Wynter.',
          feedback:
            "질문에 대한 답변이 필요해요. 자기소개를 간단히 하며 어디에서 왔는지 설명하면 좋습니다. 예: 'I'm from Seoul, South Korea.'"
        },
        {
          number: 2,
          question: 'What time do you usually wake up?',
          answer: "i don't know",
          feedback:
            "기상 시간을 설명할 때는 시간을 명확히 해야 해요. 예: 'I usually wake up at 7 AM.'"
        },
        {
          number: 3,
          question: 'What’s the weather like today?',
          answer: 'It is cloudy and cold.',
          feedback:
            "현재 날씨를 설명할 때는 구체적으로 표현하는 것이 좋아요. 예: 'It's sunny and warm today.'"
        },
        {
          number: 4,
          question: "What's your favorite food?",
          answer: "what? I don't understand.",
          feedback:
            "좋아하는 음식을 설명할 때 이유를 덧붙이면 좋습니다. 예: 'My favorite food is pizza because I love cheese.'"
        },
        {
          number: 5,
          question: 'What do you do on weekends?',
          answer: 'I usually meet my friends and go shopping.',
          feedback:
            "주말 활동을 설명할 때 다양한 활동을 포함하세요. 예: 'I usually relax and go hiking on weekends.'"
        },
        {
          number: 6,
          question: 'How do you usually spend your evenings?',
          answer: 'I watch TV and relax at home.',
          feedback:
            "저녁 시간을 어떻게 보내는지 설명할 때 다양한 활동을 언급하세요. 예: 'I usually read books or watch TV in the evenings.'"
        },
        {
          number: 7,
          question: "What's your favorite place to travel, and why?",
          answer: 'I like Jeju Island because it is beautiful and peaceful.',
          feedback:
            "좋아하는 여행지를 설명할 때 그 이유를 자세히 설명하세요. 예: 'My favorite place to travel is Paris because of its beautiful architecture.'"
        },
        {
          number: 8,
          question: 'What’s your favorite season, and why?',
          answer: 'I love winter.',
          feedback:
            "좋아하는 계절을 설명할 때 그 이유를 덧붙이세요. 예: 'My favorite season is spring because the flowers bloom.'"
        },
        {
          number: 9,
          question: 'What kind of sports or exercise do you enjoy?',
          answer: 'I enjoy yoga and jogging because they are good for my health.',
          feedback:
            "좋아하는 운동이나 운동 종류를 설명할 때 구체적으로 언급하세요. 예: 'I enjoy swimming because it's refreshing.'"
        },
        {
          number: 10,
          question: 'If you could try a new hobby, what would it be?',
          answer: 'I like to play to football with my friend.',
          feedback:
            "새로 시도해보고 싶은 취미를 구체적으로 설명하세요. 예: 'I would like to try painting as a new hobby.'"
        }
      ],
      totalFeedback:
        "이번 테스트는 A1 수준으로 평가되었습니다. 기본적인 영어 표현 및 문법을 연습하여 일상 대화 능력을 향상시키는 것이 중요합니다. 🌟 문장을 만들 때 주어와 동사의 조합을 잘 연습하고, 꾸준히 대화할 수 있는 기회를 가져보세요. '야나두'의 추천 코스를 수강하면 영어 실력이 향상되고, 더 나은 언어 능력으로 자신을 만나게 될 거예요. 😊"
    }
  },
  levelTestAiValue: {
    lv1: {
      rate: (
        <>
          Absolute <br />
          beginner
        </>
      ), // JSX 형태로 줄바꿈 처리
      mainText: (
        <>
          몇몇 단어나 <br />
          짧은 문장으로만 <br />
          말할 수 있어요.
        </>
      ), // JSX 형태로 줄바꿈 처리
      mainImg: mainImglv1
    },
    lv2: {
      rate: <>Beginner</>,
      mainText: (
        <>
          일상적인 주제에 대해 <br />
          짧고 단순한 문장으로 <br />
          표현할 수 있어요.
        </>
      ),
      mainImg: mainImglv2
    },
    lv3: {
      rate: (
        <>
          Beginner
          <br />
          -high
        </>
      ),
      mainText: (
        <>
          익숙한 주제에 <br />
          대해서는 표현이 가능해요.
        </>
      ),
      mainImg: mainImglv3
    },
    lv4: {
      rate: (
        <>
          Intermediate
          <br />
          -low
        </>
      ),
      mainText: (
        <>
          명확하고 <br />
          논리적으로 자신의 의견을 <br />
          표현할 수 있어요.
        </>
      ),
      mainImg: mainImglv4
    },
    lv5: {
      rate: <>Intermediate</>,
      mainText: (
        <>
          다양한 주제에
          <br />
          대해 유창하게 표현할 수<br />
          있어요.
        </>
      ),
      mainImg: mainImglv5
    },
    lv6: {
      rate: (
        <>
          Intermediate <br />
          -high <br />
        </>
      ),
      mainText: (
        <>
          원어민과 비슷한
          <br />
          수준으로 표현할 수 있어요
        </>
      ),
      mainImg: mainImglv6
    }
  },
  comentValues: {
    lv1: {
      title: (
        <>
          영어로 일반적인 소통을 <br />
          진행하는 것이 어려운 단계예요.
        </>
      ),
      subTitle: (
        <>
          지금은 영어의 아주 기초적인 단계에 있어요.
          <br /> 간단한 인사말, 자기소개, 숫자나 시간처럼 일상에서 자주 쓰는 표현을 사용할 수
          있지만, 대화가 매우 제한적이고 문장을 끝까지 말하기까지의 시간이 느린 편이에요.
          <span></span>
          <br /> 처음에는 단어와 간단한 문장을 익히는 것이 중요합니다.
          <br />
          정확한 발음과 기본 문장 구조를 배우는 데 시간을 투자해 보세요. 천천히 말하더라도, 주어와
          동사를 정확히 연결하는 연습을 하는 것이 중요합니다.
        </>
      )
    },
    lv2: {
      title: (
        <>
          간단한 의사소통이 <br />
          가능하나 복잡한 문법이나 <br />
          표현은 어려운 단계예요.
        </>
      ),
      subTitle: (
        <>
          가족, 친구, 취미, 쇼핑 같이 일상적인 주제에 대해서는 어느 정도 말할 수 있지만, 깊이 있는
          대화를 나누기는 어려워요. <span></span> <br />
          익숙한 표현에 의존하여 비슷한 표현만 반복적으로 사용하는 경우가 많고, 문법이나 시제에 대한
          구분이 명확하지 않아 자주 실수하는 편이에요.
        </>
      )
    },
    lv3: {
      title: (
        <>
          기본적인 문장과 표현에는 <br />
          익숙하지만 추상적이거나 <br />
          복잡한 문법은 어려운 단계예요
        </>
      ),
      subTitle: (
        <>
          여행 이야기, 일상 활동, 취미 등에 대해 간단히 설명하거나 의견을 나눌 수 있어요.
          <span></span> <br />
          하지만 복잡한 주제는 여전히 어렵게 느껴질 수 있고, 의사소통에 큰 문제는 없다고 해도
          문법적인 오류가 종종 발생할 수 있어요.
        </>
      )
    },
    lv4: {
      title: (
        <>
          다양한 주제에 대해 능숙하게 <br />
          대화할 수 있지만, 세부적인 표현과 <br />
          고급 문법은 어려운 단계예요.
        </>
      ),
      subTitle: (
        <>
          익숙한 주제뿐만 아니라 시사나 전문적인 주제에 대해서도 이야기할 수 있어요. <span></span>
          <br />
          사용하는 단어의 폭이 넓어지고, 대화를 자연스럽게 이어나가는 능력이 향상되지만 문장이나
          단어의 미묘한 의미 차이를 구분하거나 세부적인 표현을 하는 것은 어려울 수 있어요.
        </>
      )
    },
    lv5: {
      title: (
        <>
          이미 영어에 능숙하지만, <br />
          조금 더 고급스러운 어휘와 문장으로 <br /> 전문성을 높여야 하는 단계예요.
        </>
      ),
      subTitle: (
        <>
          복잡한 문서나 전문적인 내용도 이해하고 자신의 생각을 명확하게 전달할 수 있어요.
          <span></span>
          <br />
          단어 선택이 세련된 편이고 문장 구조도 다양하지만, 가끔은 일상적인 주제를 벗어나 심도있는
          표현을 할 때 실수가 발생할 수 있어요.
        </>
      )
    },
    lv6: {
      title: (
        <>
          이미 유창한 실력을 갖췄지만, <br /> 외워서 하는 영어보다 원어민의 <br />
          사고방식을 사용하는 것이 좋아요.
        </>
      ),
      subTitle: (
        <>
          복잡하고 미묘한 내용도 완벽하게 이해하고 표현할 수 있으며, 전문적이거나 학문적인 문서도
          처리할 수 있어요. <span></span>
          <br /> 이 단계에서는 일상적인 대화를 나눌 때 거의 언어의 한계가 없는 편이에요.
        </>
      )
    }
  },
  feedBackValues: {
    lv1: {
      description: (
        <>
          기초 단계이기 때문에 너무 어려운 문장을 쓰기보다는 짧고 간단한 문장부터 연습하는 게
          좋아요. <br /> 예를 들어, “I am a teacher.”, “I like coffee.”처럼 주어 + 동사 구조나 "I
          like apples.", "He is a student."처럼 주어 + 동사 + 목적어 형태로 문장을 구성하는 연습을
          꾸준히 해보세요. 기초 단어와 발음을 익히면 자신감도 조금씩 생길 거예요. <br /> 속도가 조금
          느리더라도 정확하게 한 문장을 만들어내는 것이 중요해요. 😊 <br /> 왕초보 전문 야나두와
          함께 하루 10분씩 연습하다보면 완전한 문장으로 일상적이고 익숙한 주제에 대해 자유롭게
          대화할 수 있을거예요! 💪🏻
        </>
      )
    },
    lv2: {
      description: (
        <>
          문장 길이를 조금씩 늘리려는 노력이 필요해요. <br /> 예를 들어, "I like pizza." 대신에 "I
          like pizza because it is delicious."처럼 좋아하는 이유를 추가해 보세요. <br /> 또한,
          일상적인 질문을 연습하며 간단한 대답을 확장하는 습관을 가져 보세요. <br /> 문법 실수를
          두려워하지 말고, 반복 연습을 통해 자연스럽게 익힌다면 어느새 실력이 성장해있는 나를
          발견하실 거예요. <br /> 추천 강의인 "뉴 챌린지 찐 패키지"로 말문이 트이는 1단계부터
          원어민처럼 논리있게 말하는 3단계까지..! 야나두가 그 모든 성장 과정에 함께할게요. 😉
        </>
      )
    },
    lv3: {
      description: (
        <>
          자신감을 가지고 조금 더 자세히 말해 보는 연습이 필요해요. <br /> 예를 들어, “I went to the
          park last weekend.” 대신 “I went to the park last weekend with my family. We had a picnic
          and played games.”처럼 구체적으로 묘사해 보세요. <br /> 또한, 일상적 대화를 통해 표현력을
          키우고 간단한 의견이나 경험을 나눌 수 있도록 연습해 보세요. <br /> 하루 10분 말문이 트이는
          데일리 커리큘럼으로 설계된 "10분 패키지"와 함께라면 보다 장문의 디테일한 표현들이 자연스레
          나올거예요.👍🏻
        </>
      )
    },
    lv4: {
      description: (
        <>
          더 복잡한 문장을 시도하고 다양한 표현을 익히는 것이 중요해요.
          <br /> 예를 들어, 자신의 의견을 말할 때 “I think...” 대신 “In my opinion,”, “I believe
          that...”과 같은 다양한 문장 도입부를 사용해 보세요.
          <br /> 또한, 토론이나 주제에 대한 장단점을 말하는 연습을 통해 논리적인 흐름을 잡을 수
          있도록 연습해 보세요.
          <br />
          매일 10분, 실전 중심의 촘촘한 커리큘럼을 담은 "10분 패키지"와 함께하면 보다 논리적으로
          표현할 수 있을거예요.😊
        </>
      )
    },
    lv5: {
      description: (
        <>
          이제는 더 정교한 문장 구조를 연습하며, 문장 속에서 강조하고 싶은 부분을 자연스럽게 표현해
          보세요. <br /> 예를 들어, "If I had studied harder, I would have passed."처럼 가정법
          과거완료를 연습해 과거의 후회를 표현해 보세요.
          <br /> 또한, 비유적 표현이나 관용구를 학습하면 더 자연스럽고 풍부한 영어를 구사할 수
          있어요.
          <br /> 이미 영어가 어느 정도 능숙하다고 해도 말을 할 때 자신감을 가지고, 중요한 부분을
          강조하는 연습을 하면 더욱 좋겠죠?
          <br /> 야나두의 추천 강의인 "소피반의 비밀과외" 강의를 통해 미국 공인 통역사의 노하우를
          배워보세요!📖
        </>
      )
    },
    lv6: {
      description: (
        <>
          다양한 문체와 어조를 사용하는 연습이 필요해요. 글을 쓸 때는 학문적, 비공식적 표현을
          자유롭게 넘나들고, 말할 때는 상황에 맞는 어휘와 문장을 선택하는 능력을 키워 보세요.
          <br />
          예를 들어, "Never have I seen such a beautiful sunset."처럼 도치구문을 사용해 강조하고
          싶은 부분을 앞에 두는 연습을 하거나, "If I had studied harder, I would be successful
          now."처럼 혼합 가정법을 활용해 과거와 현재를 연결하는 문장을 만드는 연습을 해보는게
          좋아요.
          <br />
          하지만 외워서 잘하는 영어는 한계에 부딪힐 수 밖에 없어요. 한국어를 영어로 번역하기 보다는
          생각을 확장해서 영어로 툭! 나올 수 있는 연습을 해보세요.
          <br />
          야나두의 추천 강의인 "소피반의 비밀과외" 강의를 통해 미국 공인 통역사의 노하우를
          배워보세요! 👩🏻‍💻
        </>
      )
    }
  },
  productRecommended: {
    lv1: {
      productName: '뉴 챌린지 찐패키지',
      productLink: 'promotion-landing/newChallenge',
      productDescription: (
        <>
          아직 기초가 중요한 단계이기에 문장 구조부터 탄탄하게 잡아가는 것이 중요해요! <span></span>
          <br />
          배우 진선규님이 실제 학습한 “뉴 챌린지 찐패키지”로 말문이 트이는 1단계부터 논리있게 말하는
          3단계까지 차근차근 학습해 보는 것은 어떨까요?
        </>
      ),
      jpgSrc: lv1lv2ProductImage
    },
    lv2: {
      productName: '뉴 챌린지 찐패키지',
      productLink: 'promotion-landing/newChallenge',
      productDescription: (
        <>
          아직 기초가 중요한 단계이기에 문장 구조부터 탄탄하게 잡아가는 것이 중요해요! <span></span>
          <br />
          배우 진선규님이 실제 학습한 “뉴 챌린지 찐패키지”로 말문이 트이는 1단계부터 논리있게 말하는
          3단계까지 차근차근 학습해 보는 것은 어떨까요?
        </>
      ),
      jpgSrc: lv1lv2ProductImage
    },
    lv3: {
      productName: '10분 패키지',
      productLink: 'promotion-landing/bestAwards',
      productDescription: (
        <>
          영어가 일상이 될 수 있도록 매일 꾸준히 연습하는게 중요해요! <span></span>
          <br /> 영어 회화 실력이 나날이 달라지도록 실전 중심의 촘촘한 커리큘럼을 담아낸 "10분
          패키지"로 습관부터 실력까지 올케어 받아보세요!
        </>
      ),
      jpgSrc: lv3lv4ProductImage
    },
    lv4: {
      productName: '10분 패키지',
      productLink: 'promotion-landing/bestAwards',
      productDescription: (
        <>
          영어가 일상이 될 수 있도록 매일 꾸준히 연습하는게 중요해요! <span></span> <br />
          영어 회화 실력이 나날이 달라지도록 실전 중심의 촘촘한 커리큘럼을 담아낸 "10분 패키지"로
          습관부터 실력까지 올케어 받아보세요!
        </>
      ),
      jpgSrc: lv3lv4ProductImage
    },
    lv5: {
      productName: '소피반의 비밀과외',
      productLink: 'store/detail/1008782',
      productDescription: (
        <>
          생각을 확장하고 원어민 사고로 바꾸는 것이 중요해요. <span></span> <br /> 한국어로 생각하고
          영어로 번역하는 회화는 <br /> 이제 그만! 미국 공인 통역사이자 30만 유튜버인 소피반
          선생님의 20년 노하우를 모두 <br />
          알려드릴게요!
        </>
      ),
      jpgSrc: lv5lv6ProductImage
    },
    lv6: {
      productName: '소피반의 비밀과외',
      productLink: 'store/detail/1008782',
      productDescription: (
        <>
          생각을 확장하고 원어민 사고로 바꾸는 것이 <br />
          중요해요.
          <span></span>
          <br /> 한국어로 생각하고 영어로 번역하는 회화는 <br /> 이제 그만! 미국 공인 통역사이자
          30만 유튜버인 소피반 선생님의 20년 노하우를 모두 <br />
          알려드릴게요!
        </>
      ),
      jpgSrc: lv5lv6ProductImage
    }
  },
  recommended: {
    applePackage: {
      description: '아이패드와 AI로 영어 공부하길 원하는',
      type: '스마트한',
      products: [
        {
          name: '평생수강 \n+ Apple 풀패키지',
          image: appleFullPackage,
          description: (
            <>
              학습지 52권 분량의 커리큘럼을 담아낸 AI 스르르 학습지를 <br />
              구매하면 아이패드가 0원!
              <br />
              AI 학습의 혁신을 만나보세요.😊
            </>
          ),
          color: '#ffffff'
        },
        {
          name: 'AI 학습지 풀패키지 ',
          image: aiFullPackage,
          description: (
            <>
              학습지 52권 분량의 커리큘럼을 담아낸 AI 스르르 학습지를 <br />
              구매하면 아이패드가 0원!
              <br />
              AI 학습의 혁신을 만나보세요.😊
            </>
          )
        }
      ]
    },
    aiRealTalk: {
      description: '태플이 인정한 PPP 학습법으로 공부하길 원하는 ',
      type: '정통파인',
      products: [
        {
          name: 'AI 리얼톡',
          productName: 'aiRealTalk',
          image: aiRealTalk,
          description: `태플이 인정한 PPP 학습법 기반 AI와 실전처럼 대화하고 뉘앙스까지 확실하게!`,
          color: '#ffffff'
        }
      ]
    },
    srrFullPackage: {
      description: '손으로 필기하며 공부하기 원하는',
      type: '모던한',
      products: [
        {
          name: '스르르 마스터팩',
          image: srrMaster,
          description: (
            <>
              왕초보에 최적화된 영어 학습지 완결판! 입에서 영어가 스르르 <br />
              나오는 경험을 해 보세요.
            </>
          )
        },
        {
          name: '스르르 학습지 원어민편',
          image: srrNativ,
          description: (
            <>
              어색한 직역은 그만! <br />
              원어민처럼 자연스러운 영어를 배워보세요.
            </>
          ),
          color: '#ffffff'
        }
      ]
    },
    newChallenge: {
      description: '아이패드와 AI로 영어 공부하길 원하는',
      type: '스마트한',
      products: [
        {
          name: '뉴 챌린지 찐 패키지',
          image: newPacage,
          description: `배우 진선규의 진심이 담긴 찐패키지! 진짜 영어가 될 때까지 레벨업을 도와드려요. 하루 10분으로 미뤄덨던 꿈을 이뤄보세요!`,
          color: '#ffffff'
        },
        {
          name: 'AI 학습지 기초편',
          image: aiBase,
          description: (
            <>
              학습지 50권 분량의 커리큘럼을 담아낸 AI 스르르 학습지!
              <br />
              AI 학습의 혁신을 만나보세요.😊
            </>
          )
        }
      ]
    },
    laptopPackage: {
      description: 'LG gram과 AI로 영어 공부하길 원하는',
      type: '스마트한',
      products: [
        {
          name: ' 평생수강 \n+ LG gram',
          image: lgGramPackage,
          description: (
            <>
              최신형 고사양 CPU 장착! <br />
              노트북도 영어도 야나두가 <br />
              책임질게요! 기초부터 실전까지 <br />
              무제한으로 평생 함께해요 😊
            </>
          ),
          color: '#ffffff'
        },
        {
          name: 'AI 학습지 \n원어민편',
          image: aiNative,
          description: (
            <>
              학습지 16권 분량의 커리큘럼을 담아낸 AI 스르르 학습지! <br />
              AI 학습의 혁신을 만나보세요.😊
            </>
          )
        }
      ]
    },
    macbookPackage: {
      description: 'MacBook Air와 AI로 영어 공부하길 원하는',
      type: '스마트한',
      products: [
        {
          name: '평생수강\n+ MacBook Air',
          image: macbookAirPackage,
          description: (
            <>
              13인치 M3 성능 장착! 맥북도 <br />
              영어도 야나두가 책임질게요!
              <br /> 기초부터 실전까지 무제한으로 평생 함께해요 😊
            </>
          ),
          color: '#ffffff'
        },
        {
          name: 'AI 학습지 \n원어민편',
          image: aiNative,
          description: (
            <>
              학습지 16권 분량의 커리큘럼을 담아낸 AI 스르르 학습지! <br />
              AI 학습의 혁신을 만나보세요.😊
            </>
          )
        }
      ]
    },
    frontEnglish: {
      description: '뼈대부터 튼튼히! 새로운 마음으로 영어 공부 시작하는',
      type: '왕초보',
      products: [
        {
          name: '평생수강\n+ iPad 10세대',
          image: ipad10,
          description: `왕초보부터 중고급까지! 야나두의 노하우로 평생 영어 책임질게요. 아이패드는 덤!`
        },
        {
          name: '원예나 앞자리 영어',
          image: frontEnglish,
          description: `앞자리부터 시작하면 모든 영어가 쉬워져요! 3초면 영어 문장이 톡 나오도록 뼈대를 잡아드립니다. 함께 영알못을 탈출해봐요!`
        }
      ]
    },
    bestAwards: {
      description: '강의를 보며 영어 습관을 만들기 원하는',
      type: '초보',
      products: [
        {
          name: `평생수강\n+ iPad Air 6`,
          image: ipadAir,
          description: `기초부터 원어민 대화까지 케어! 최신형 아이패드 무료 제공, 지금 시작하세요.`,
          color: '#ffffff'
        },
        {
          name: `10분 영어 \n패키지`,
          image: tenMinPackage,
          description: `누적 수강생 170만이 검증한 Best of Best 강의! 하루 10분으로 영어 습관 만들어봐요.`
        }
      ]
    },
    buddytalk: {
      description: '강의를 보며 실사용 표현을 재밌게 배우길 원하는',
      type: '',
      products: [
        {
          name: '평생수강\n+ iPad Air 6',
          image: ipadAir,
          description: `기초부터 원어민 대화까지 케어! 최신형 아이패드 무료 제공, 지금 시작하세요.`,
          color: '#ffffff'
        },
        {
          name: '오드리 버디톡',
          image: buddyTalk,
          description: `누적 수강생 170만이 검증한 Best of Best 강의! 하루 10분으로 영어 습관 만들어봐요.`,
          color: '#ffffff'
        }
      ]
    }
  },
  conversationQuestions: [
    'Where are you from?',
    'What time do you usually wake up?',
    'What’s the weather like today?',
    "What's your favorite food?",
    'What do you do on weekends?',
    'How do you usually spend your evenings?',
    "What's your favorite place to travel, and why?",
    'What’s your favorite season, and why?',
    'What kind of sports or exercise do you enjoy?',
    ' If you could try a new hobby, what would it be?'
  ]
}
