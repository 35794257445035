import { useState, useCallback, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { NavLink, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import classNames from 'classnames'
import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'
import { postGAEvent } from 'utils/postGAEvent'

import ModalJoin from 'components/modal/ModalJoin'
import useHeaderStore from 'store/useHeaderStore'
import useModalLogin from 'store/useModalLogin'

import styles from './NavLeft.module.scss'

export default function NavLeft({ isHamburger }) {
  const currentPath = useLocation().pathname
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const [currentIdx, setCurrentIdx] = useState(null)
  const [currentIdx2, setCurrentIdx2] = useState(null)
  const [modalopenJoin, setModalopenJoin] = useState(false)
  const { setIsModalLogin } = useModalLogin()

  const gnbItems = useHeaderStore((state) => state.gnbItems)
  const gnbActiveDepthIndex = useHeaderStore((state) => state.gnbActiveDepthIndex)

  const onClickGnbMenu = (idx) => {
    setCurrentIdx(idx)
  }
  const onClickGnbMenu2 = (idx) => {
    setCurrentIdx2(idx)
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })
    return isMobile ? children : null
  }

  const handleOpenModalLogin = useCallback(
    (e) => {
      if (currentPath.includes('login')) {
        window.location.href = '/login'

        return false
      }

      document.body.classList.add('modal-open')
      setIsModalLogin(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPath]
  )

  const handleCloseModalJoin = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenJoin(false)
  }, [])

  useEffect(() => {
    const mobileNav = document.querySelector('header').querySelectorAll('.nav-main')
    for (let i = 0; i < mobileNav.length; i++) {
      mobileNav[i].onclick = function () {
        if (mobileNav[i].parentElement.classList.value.indexOf('active') !== -1) {
          mobileNav[i].parentElement.classList.remove('active')
        } else {
          mobileNav[i].parentElement.classList.add('active')
        }
      }
    }
  }, [])

  useEffect(() => {
    if (gnbActiveDepthIndex !== null) {
      setCurrentIdx(gnbActiveDepthIndex?.depth1)
      setCurrentIdx2(gnbActiveDepthIndex?.depth2)
    } else {
      setCurrentIdx(0)
    }
  }, [gnbActiveDepthIndex])

  return (
    <>
      <nav className={`${styles.nav} ${isHamburger ? styles.active : ''}`}>
        <Mobile>
          <Link to="/" className={`${styles.logo} logo`}>
            <i className="blind">야나두</i>
          </Link>
          <div className={styles.lnbMBtnGroup}>
            <Link to="/event/list/1" className={styles.btnMEvents}>
              이벤트
            </Link>
            <Link to="/promotion-landing/b2bLanding">기업교육</Link>
          </div>
          <div className={styles.leftCompanyCol}>
            <ul className={styles.tabMenu}>
              {gnbItems &&
                gnbItems.map((item, idx) => (
                  <li
                    key={idx}
                    className={currentIdx === idx ? styles.active : ''}
                    onClick={() => onClickGnbMenu(idx)}
                  >
                    <button type="button" style={{ borderBottomColor: item.backgroundColor }}>
                      {item.menuName}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
          {gnbItems &&
            gnbItems.map((gnb, index) => (
              <ul
                key={index}
                className={`${styles.menu2dList} ${currentIdx === index ? styles.active : ''}`}
              >
                {gnb.childGnbMenus.map((item, idx) => (
                  <li
                    key={idx}
                    className={classNames(
                      item.iconType && item.iconType !== 'NONE' && item.iconType.toLowerCase(),
                      currentIdx2 === idx && 'active',
                      item.childGnbMenus.length <= 0 && styles.nochild
                    )}
                    onClick={() => onClickGnbMenu2(idx)}
                  >
                    {item.link.includes('http') ? (
                      <a
                        href={isMobile && item.childGnbMenus.length > 0 ? '#;' : item.link}
                        target={`_${item.linkTarget.toLowerCase()}`}
                        className={styles.menuName}
                      >
                        {item.menuName}
                        {/* 2A */}
                      </a>
                    ) : (
                      <NavLink
                        className={styles.menuName}
                        to={isMobile && item.childGnbMenus.length > 0 ? '#;' : item.link}
                      >
                        {item.menuName}
                        {/* 2L */}
                      </NavLink>
                    )}
                    {item.childGnbMenus?.length > 0 && (
                      <ul>
                        {item.childGnbMenus.map((sub, index, e) => (
                          <li
                            key={`${sub.depth}-${sub.gnbMenuSeq}`}
                            className={classNames(
                              sub.iconType && sub.iconType !== 'NONE' && sub.iconType.toLowerCase()
                            )}
                          >
                            <a
                              href={sub.link}
                              target={`_${sub.linkTarget.toLowerCase()}`}
                              className={classNames(
                                styles.depthMenuName,
                                currentPath === sub.link && 'active'
                              )}
                              onClick={() => postGAEvent(sub.gaEventName, sub.gaEventName)}
                            >
                              {sub.menuName}
                              {/* 3A */}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            ))}

          <div className={styles.btnGroupBottom}>
            <Link to="/service/center">
              <span>고객센터</span>
            </Link>
            {!AuthService.isLoggedIn() && (
              <button onClick={handleOpenModalLogin}>
                <span>로그인</span>
              </button>
            )}
          </div>
        </Mobile>
      </nav>

      {modalopenJoin ? (
        <ModalPortal>
          <ModalJoin
            title={'회원가입'}
            btnClose
            type={'DEFAULT'} // DEFAULT: 기존 회원가입 , SOCIAL: 소셜 회원가입, PAYMENT: 수기결제
            handleCloseModal={handleCloseModalJoin}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
