import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import SectionTitle from 'pages/service/center/components/common/SectionTitle'
import useGetFaqCategory from 'pages/service/hooks/useGetFaqCategory'
import useGetFaqList from 'pages/service/hooks/useGetFaqList'

import FaqCategory from 'components/service/faq/FaqCategory'
import FaqList from 'components/service/faq/FaqList'
import Pagination from 'components/service/Pagination'
import ServiceTabNav from 'components/service/ServiceTabNav'

import s from './FaqContents.module.scss'

export default function FaqContents() {
  const [selected, setSelected] = useState('BEST')
  const [page, setPage] = useState(1)
  const [tab, setTab] = useState({ yanadoo: true, yafit: false })
  const groupCode = tab.yanadoo ? 'YANADOO_FAQ_GROUP' : 'YAFIT_FAQ_GROUP'
  const { category } = useGetFaqCategory(groupCode)
  const { faqList, totalPage } = useGetFaqList({ cate: selected, group: groupCode, page, size: 10 })
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  const handleTab = (key) => {
    setTab((prevTab) => ({
      yanadoo: key === 'yanadoo' ? !prevTab.yanadoo : false,
      yafit: key === 'yafit' ? !prevTab.yafit : false
    }))
    setSelected('BEST')
  }

  const handleCategory = (code) => {
    setSelected(code)
    setPage(1)
  }

  const onPageChange = (current) => {
    setPage(current)
  }
  return (
    <>
      {!isMobile && (
        <SectionTitle>
          <h3>자주 묻는 질문 FAQ</h3>
        </SectionTitle>
      )}
      <ServiceTabNav handleTab={handleTab} tab={tab} />
      <FaqCategory
        category={category}
        classes={s.faqContentsCategory}
        selected={selected}
        handleCategory={handleCategory}
      />
      <div className={s.csTabContent}>
        <FaqList faqList={faqList} />
        <Pagination currentPage={page} totalPages={totalPage} onPageChange={onPageChange} />
      </div>
    </>
  )
}
