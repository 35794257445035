import React from 'react'

import RecommendCoreProduct from './RecommendCoreProduct'
import RecommendLevelProduct from './RecommendLevelProduct'
import Top from './Top'
import VideoSection from './VideoSection'
import styles from '../styles/LevelTestAiResult.module.scss'

export default function ConversationRecommendPage({
  level,
  recommendData,
  showThumbnail,
  setShowThumbnail,
  isPlay,
  setIsPlay,
  handleNavigate,
  conversationLevel
}) {
  return (
    <div className={styles.inner}>
      <div className={styles.container}>
        <div className={styles.newPage}>
          <Top onClick={() => handleNavigate('main')} />
          <RecommendLevelProduct level={level} />
          {recommendData && <RecommendCoreProduct recommendData={recommendData} />}
          <VideoSection
            showThumbnail={showThumbnail}
            setShowThumbnail={setShowThumbnail}
            isPlay={isPlay}
            setIsPlay={setIsPlay}
          />
        </div>
      </div>
    </div>
  )
}
