import { useEffect, useState } from 'react'

import AuthService from 'services/authService'

import styles from '../styles/RecommendCoreProduct.module.scss'
export default function RecommendCoreProduct({ recommendData }) {
  const [userName, setUserName] = useState('')

  const getUserName = async () => {
    const res = await AuthService.getUserInfo()
    setUserName(res.userName)
  }

  useEffect(() => {
    getUserName()
  }, [])

  return (
    <section className={styles.recommendCore}>
      <p className={styles.subTitle}>{recommendData.description}</p>
      <p className={styles.title}>
        {userName}님에게 <br />딱 맞는 추천 상품이에요!
      </p>
      <ul className={styles.productList}>
        {recommendData &&
          recommendData.products.map((item, index) => {
            return (
              <li key={index} className={styles.product}>
                <img src={item.image} alt="" />
                <div className={styles.productText}>
                  <p className={styles.name} style={{ color: item.color }}>
                    {item.name}
                  </p>
                  <p className={styles.description} style={{ color: item.color }}>
                    {item.description}
                  </p>
                </div>
              </li>
            )
          })}
      </ul>
    </section>
  )
}
