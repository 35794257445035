export const STORE_DETAIL_CDN_HOST =
  'https://english.yanadoocdn.com/upload/yanadoo/new/store/detail'

export const storeDetailValues = {
  tabs: [
    {
      name: '상품소개',
      value: 'product'
    },
    {
      name: '수강생 후기',
      value: 'review-list'
    },
    {
      name: '커리큘럼',
      value: 'curriculum'
    },
    {
      name: '상품문의',
      value: 'question'
    }
  ],
  subGnbNames: {
    외국어: 2,
    커리어: 3,
    머니: 4,
    취미: 5
  }
}
