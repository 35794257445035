import { useState, useEffect } from 'react'

export function useSetGrade(score) {
  const [level, setLevel] = useState(null)

  useEffect(() => {
    const determineLevel = (score) => {
      if (score >= 0 && score <= 20) return '1'
      if (score >= 21 && score <= 40) return '2'
      if (score >= 41 && score <= 70) return '3'
      if (score >= 71 && score <= 80) return '4'
      if (score >= 81 && score <= 90) return '5'
      if (score >= 91 && score <= 100) return '6'
      return null // score가 범위를 벗어난 경우
    }

    setLevel(determineLevel(score))
  }, [score])

  return { level }
}
