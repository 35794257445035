import React, { useEffect } from 'react'

import classNames from 'classnames'

import { YANADOO_CDN_HOST } from 'common/config'
import OAuth from 'common/OAuth'

import KakaoImg from '../images/ico_kakao.png'
import KakaoImg2 from '../images/ico_kakao_v2.png'
import styles from '../styles/ShareSection.module.scss'
export default function ShareSection({ examType }) {
  const shareKakao = () => {
    if (!window.Kakao || !window.Kakao.Link) {
      alert('카카오톡 공유 기능이 로드되지 않았습니다. 잠시 후 다시 시도해주세요.')
      return
    }

    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: '야나두 AI 레벨테스트 (with 오드리)',
        description: '야나두 대표 강사인 오드리쌤과 1:1로 영어 실력을 측정해 보세요!',
        imageUrl: `${YANADOO_CDN_HOST}/levelTestAi/img-OG.png`,
        imageWidth: 600,
        imageHeight: 300,
        link: {
          mobileWebUrl: 'https://www.yanadoo.co.kr/levelTestAi/start',
          webUrl: 'https://www.yanadoo.co.kr/levelTestAi/start'
        }
      },
      buttons: [
        {
          title: '웹으로 이동',
          link: {
            mobileWebUrl: 'https://www.yanadoo.co.kr/levelTestAi/start',
            webUrl: 'https://www.yanadoo.co.kr/levelTestAi/start'
          }
        }
      ]
    })
  }
  useEffect(() => {
    OAuth.injectScript('https://developers.kakao.com/sdk/js/kakao.js', () => {
      if (!window.Kakao.isInitialized()) {
        window.Kakao.init(OAuth.KAKAO_JS_KEY)
      }
    })
  }, [])
  return (
    <section
      className={classNames(styles.ShareSection, {
        [styles.quiz]: examType === 'quiz', // quiz 타입일 때
        [styles.conversation]: examType !== 'quiz' // conversation 타입일 때
      })}
    >
      <h4>
        친구들의 영어 실력이 <br /> 궁금하다면?
      </h4>
      <div className={styles.download}>
        <button onClick={shareKakao}>
          <span>
            {examType === 'quiz' ? (
              <img src={KakaoImg} alt="카카오 아이콘" />
            ) : (
              <img src={KakaoImg2} alt="카카오 아이콘" />
            )}
            친구에게 테스트 공유하기
          </span>
        </button>
      </div>
    </section>
  )
}
