import { lazy } from 'react'

import { PromotionLanding } from 'router/OutletStore'

import RedirectTo from 'components/RedirectTo'

const AiRealtalk = lazy(() => import('pages/promotion/aiRealtalk'))
const AITravelEnglish = lazy(() => import('pages/promotion/AITravelEnglish'))
const AISrr = lazy(() => import('pages/promotion/aiSrr'))
const BestAwardsDBOnlyPerformance = lazy(() =>
  import('pages/promotion/bestAwardsDBOnly_performance')
)
const DBMotivation = lazy(() => import('pages/promotion/DB_Motivation'))
const DBPerformance = lazy(() => import('pages/promotion/DB_Performance'))
const MacbookPackage = lazy(() => import('pages/promotion/macbookPackage'))
const LaptopPackage = lazy(() => import('pages/promotion/laptopPackage'))
const ApplePackage = lazy(() => import('pages/promotion/applePackage'))
const B2BLanding = lazy(() => import('pages/promotion/B2BLanding'))
const BestAwards = lazy(() => import('pages/promotion/bestAwards'))
const BestAwardsDBOnly = lazy(() => import('pages/promotion/bestAwardsDBOnly'))
const FrontEnglish = lazy(() => import('pages/promotion/frontEnglish'))
const IpadDouble = lazy(() => import('pages/promotion/ipadDouble'))
const IpadDoubleAptner = lazy(() => import('pages/promotion/ipadDouble_aptner'))
const NewBenefit = lazy(() => import('pages/promotion/newBenefit'))
const QuizEvent = lazy(() => import('pages/promotion/quizEvent'))
const SrrBooks = lazy(() => import('pages/promotion/SRR_books'))
const SrrCareer = lazy(() => import('pages/promotion/srrCareer'))
const StandByMe = lazy(() => import('pages/promotion/standByMe'))
const Buddytalk = lazy(() => import('pages/promotion/buddyTalk'))
const Curriculum = lazy(() => import('pages/promotion/curriculum'))
const TabPackageAir = lazy(() => import('pages/promotion/tabPackageAir'))
const TabPackage10th = lazy(() => import('pages/promotion/tabPackage10th'))
const Brain = lazy(() => import('pages/promotion/brain'))
const LaptopPackagePerformance = lazy(() => import('pages/promotion/laptopPackage_performance'))
const MacbookPackagePerformance = lazy(() => import('pages/promotion/macbookPackage_performance'))
const SRRfullpackagePerformance = lazy(() => import('pages/promotion/SRRfullpackage_performance'))
const Nipowoopo2024 = lazy(() => import('pages/promotion/n_nipowoopo2024'))
const SRRfullpackage = lazy(() => import('pages/promotion/SRRfullpackage'))
const SRRworkbook = lazy(() => import('pages/promotion/SRRworkbook'))
const SRRworkbookbrand = lazy(() => import('pages/promotion/SRRworkbookbrand'))
const ScholarShip = lazy(() => import('pages/promotion/scholarShip'))
const NewChallenge = lazy(() => import('pages/promotion/newChallenge_renew'))
const MyNewChallenge = lazy(() => import('pages/promotion/myNewChallenge'))
const YndGuide = lazy(() => import('pages/promotion/yndGuide'))
const IpadDoubleNaverCpt = lazy(() => import('pages/promotion/ipadDouble_navercpt'))
const MacbookPackageNaverCpt = lazy(() => import('pages/promotion/macbookPackage_navercpt'))
const NewChallengeMl = lazy(() => import('pages/promotion/newChallenge_ml'))
const MacbookPackageFreepass = lazy(() => import('pages/promotion/macbookPackage_freepass'))
const ClassSecret = lazy(() => import('pages/promotion/class_secret'))
const MacbookPackageTogether = lazy(() => import('pages/promotion/macbookPackage_together'))

const PromotionRoutes = [
  // react promotion
  {
    path: 'promotion-landing',
    element: <PromotionLanding />,
    children: [
      { path: 'DB_Motivation', element: <DBMotivation /> },
      { path: 'DB_performance', element: <DBPerformance /> },
      { path: 'b2bLanding', element: <B2BLanding /> },
      { path: 'bestAwards', element: <BestAwards /> },
      { path: 'quizevent', element: <QuizEvent /> },
      { path: 'buddytalk', element: <Buddytalk /> },
      { path: 'applePackage', element: <ApplePackage /> },
      { path: 'applePackageUI', element: <ApplePackage isAdType /> },
      { path: 'macbookPackage', element: <MacbookPackage /> },
      { path: 'macbookPackage_performance', element: <MacbookPackagePerformance /> },
      { path: 'SRRbasic', element: <SrrBooks pageType="basic" /> },
      { path: 'SRRnative', element: <SrrBooks pageType="native" /> },
      { path: 'SRRspeech', element: <SrrBooks pageType="speech" /> },
      { path: 'newBenefit', element: <NewBenefit /> },
      { path: 'srrCareer', element: <SrrCareer /> },
      { path: 'frontEnglish', element: <FrontEnglish /> },
      { path: 'ipadDouble', element: <IpadDouble /> },
      { path: 'ipadDouble_aptner', element: <IpadDoubleAptner /> },
      { path: 'tabPackageAir', element: <TabPackageAir /> },
      { path: 'tabPackage10th', element: <TabPackage10th /> },
      { path: 'standByMe', element: <StandByMe /> },
      { path: 'SRRfullpackage', element: <SRRfullpackage /> },
      { path: 'SRRfullpackage_performance', element: <SRRfullpackagePerformance /> },
      { path: 'laptopPackage', element: <LaptopPackage /> },
      { path: 'laptopPackage_performance', element: <LaptopPackagePerformance /> },
      { path: 'bestAwardsDBOnly', element: <BestAwardsDBOnly /> },
      { path: 'curriculum', element: <Curriculum /> },
      { path: 'bestAwardsDBOnly_performance', element: <BestAwardsDBOnlyPerformance /> },
      { path: 'brain', element: <Brain /> },
      { path: 'AITravelEnglish', element: <AITravelEnglish /> },
      { path: 'aiRealtalk', element: <AiRealtalk /> },
      { path: 'aiSrr', element: <AISrr /> },
      { path: 'aiSrrSecret', element: <AISrr isSecretPage /> },
      { path: 'n_nipowoopo2024', element: <Nipowoopo2024 /> },
      { path: 'SRRworkbookbrand', element: <SRRworkbookbrand /> },
      { path: 'SRRworkbook', element: <SRRworkbook /> },
      { path: 'scholarShip', element: <ScholarShip /> },
      { path: 'newChallenge', element: <NewChallenge /> },
      { path: 'MyNewChallenge', element: <MyNewChallenge /> },
      { path: 'yndGuide', element: <YndGuide /> },
      { path: 'ipadDouble_navercpt', element: <IpadDoubleNaverCpt /> },
      { path: 'macbookPackage_navercpt', element: <MacbookPackageNaverCpt /> },
      {
        path: 'macbookPackage_navercpt2',
        element: <MacbookPackageNaverCpt cptName={'navercpt2'} />
      },
      { path: 'newChallenge_ml', element: <NewChallengeMl /> },
      { path: 'macbookPackage_freepass', element: <MacbookPackageFreepass /> },
      { path: 'class_secret', element: <ClassSecret /> },
      { path: 'macbookPackage_together', element: <MacbookPackageTogether /> }
    ]
  },
  // public html promotion
  {
    path: '/promotion/',
    children: [
      { path: 'n_nipowoopo2023', element: <Nipowoopo2024 /> },
      { path: 'tabPackage_air', element: <TabPackageAir /> },
      { path: 'bestAwards_db_only', element: <BestAwardsDBOnly /> },
      { path: 'tabPackage_air5th', element: <TabPackageAir /> },
      { path: 'tabPackage', element: <RedirectTo htmlFileName="tabPackage" /> },
      { path: 'previewYanadoo', element: <RedirectTo htmlFileName="previewYanadoo" /> },
      { path: 'tripEnglish', element: <RedirectTo htmlFileName="tripEnglish" /> },
      { path: 'trainingEnglish', element: <RedirectTo htmlFileName="trainingEnglish" /> },
      { path: 'audioEnglish', element: <RedirectTo htmlFileName="audioEnglish" /> },
      { path: 'realEnglish', element: <RedirectTo htmlFileName="realEnglish" /> },
      { path: 'cyclePackage', element: <RedirectTo htmlFileName="cyclePackage" /> }
    ]
  }
]

export default PromotionRoutes
