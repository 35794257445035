import React from 'react'

import cx from 'classnames'

import s from './FaqCategory.module.scss'

export default function FaqCategory({ category, classes, selected, handleCategory }) {
  return (
    <div className={cx(s.csCategory, classes)}>
      {category.map((item, index) => (
        <button
          key={index}
          type="button"
          className={cx(s.csCateBtn, { [s.csCateActive]: item.code === selected })}
          onClick={() => handleCategory(item.code)}
        >
          <span>{item.value}</span>
        </button>
      ))}
    </div>
  )
}
