import React from 'react'

import s from './styles/ReviewPrecaution.module.scss'

export default function ReviewPrecaution() {
  return (
    <section className={s.reviewPrecaution}>
      <dl>
        <dt>※ 수강 후기 작성 안내</dt>
        <dd>
          <span>
            약관 및 법률에 위배(비속어, 허위사실 등), 해석이 불가능하거나 상품 후기 외 다른 목적의
            글을 작성한 경우 관리자에 의해 사전 동의 없이 미게시 될 수 있습니다.
          </span>
        </dd>
        <dd>
          <span>작성하신 후기는 홍보, 이벤트 등 다양한 방법으로 활용될 수 있습니다.</span>
        </dd>
      </dl>
    </section>
  )
}
