import { useCallback, useEffect, useState } from 'react'

import api from 'common/api'

export default function usePostProvide(provide) {
  const [isProvide, setIsProvide] = useState(false)

  const postProvide = useCallback(async () => {
    try {
      const response = await api.post('/v2/review/mall-in-mall-class/provide')
      const meta = response?.data?.meta
      if (meta?.code === 200) {
        setIsProvide(true)
      } else {
        setIsProvide(false)
        // alert(meta?.message || '무료 지급된 강의를 확인해 보세요!')
      }
    } catch (error) {
      // 요청 실패 시 에러 처리
      console.error('🚀 Post Error:', error.message, {
        apiUrl: '/v2/review/mall-in-mall-class/provide'
      })
      setIsProvide(false)
    }
  }, [])

  useEffect(() => {
    if (provide) {
      postProvide()
    }
  }, [provide, postProvide])

  return { isProvide }
}
