import ModalPrivacyAgree from 'components/promotion/modal/ModalPrivacyAgree'
import ModalWrap from 'components/promotion/modal/ModalWrap'

import ModalReCheck from './ModalReCheck'
import useLevelTestAiCounsel from '../hooks/useLevelTestAiCounsel'
import styles from '../styles/StepConsultation.module.scss'

export default function RecommendCounsel({ survey }) {
  const {
    allChecked,
    isShowRecheckModal,
    isShowPrivacyModal,
    formData,
    handlePrivacyModalToggle,
    handleAllCheckboxChange,
    handleCheckboxChange,
    handleSubmit,
    onClickSmsAgree,
    onClickResult
  } = useLevelTestAiCounsel({ survey })

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ul className={styles.agree}>
          <li>
            <label>
              <input
                type="checkbox"
                id="allAgree"
                checked={allChecked}
                onChange={handleAllCheckboxChange}
              />
              <em></em>
              전체 동의
            </label>
          </li>
          <li>
            <label>
              <input
                type="checkbox"
                id="privacyAgreeYn"
                checked={formData.privacyAgreeYn}
                onChange={handleCheckboxChange}
              />
              <em></em>
              개인정보 수집·이용 동의 [필수]
              <button type="button" onClick={handlePrivacyModalToggle}>
                약관보기
              </button>
            </label>
          </li>
          <li>
            <label>
              <input
                type="checkbox"
                id="smsAgreeYn"
                checked={formData.smsAgreeYn}
                onChange={handleCheckboxChange}
              />
              <em></em>
              SMS 광고 및 마케팅 이용 동의 [선택]
            </label>
          </li>
        </ul>
        <button button="submit" className={styles.submitButton}>
          <p>레벨테스트 결과 확인</p>
          <em>+ 전문가와 무료 상담</em>
        </button>
      </form>
      <button className={styles.textButton} onClick={onClickResult}>
        레벨테스트 결과만 보기
        <Arrow />
      </button>
      {/* 개인정보 수집팝업 */}
      {isShowPrivacyModal && (
        <ModalWrap>
          <ModalPrivacyAgree toggle={handlePrivacyModalToggle} />
        </ModalWrap>
      )}

      {/* 선택항목 재확인 모달 */}
      {isShowRecheckModal && (
        <ModalReCheck
          onClickAgree={() => onClickSmsAgree(true)}
          onClickCancel={() => onClickSmsAgree(false)}
        />
      )}
    </>
  )
}
const Arrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.5 3L13.5 10L6.5 17"
        stroke="black"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
