import React from 'react'

import classNames from 'classnames'
import useAccordion from 'hooks/useAccordion'
import { v4 as uuid } from 'uuid'

import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'

import RadarChart from './charts/RadarChart'
import useSetAccordionList from '../hooks/useSetAccordionList'
import useSetCategoryGrade from '../hooks/useSetCategoryGrade'
import useSetCategoryStats from '../hooks/useSetCategoryStats'
import downloadImage from '../images/ico_download.svg'
import styles from '../LevelTestAdvancedResult.module.scss'

export default function Assessment({ onImageDownload }) {
  const { test, level, name } = useLevelTestAdvancedResultStore()
  const { currentIndex, handleAccordionClick } = useAccordion(0)
  const { categoryStats } = useSetCategoryStats(test)
  const { assessmentText } = useSetCategoryGrade(categoryStats)
  const { assessmentList } = useSetAccordionList(categoryStats)

  return (
    <section className={styles.assessment}>
      <hgroup className={styles.hgroup}>
        <h3>영역별로 진단을 해드릴게요</h3>
        <h6>
          {name}님은 {assessmentText}
        </h6>
      </hgroup>

      <RadarChart level={level} categoryStats={categoryStats} />

      <ul className={classNames(styles.accordion, 'assessment-accordion')}>
        {assessmentList &&
          Object.values(assessmentList).map((value, idx) => (
            <li
              key={uuid()}
              className={idx === currentIndex ? styles.active : ''}
              onClick={handleAccordionClick}
            >
              <h4>{value.title}</h4>
              <p>{value.text}</p>
            </li>
          ))}
      </ul>

      <div className={styles.download}>
        <p>
          * 레벨테스트 결과는 저장되지 않으니
          <br />
          이미지를 다운로드 받아 저장하세요!
        </p>

        <button onClick={onImageDownload}>
          <img src={downloadImage} alt="레벨테스트 결과 다운로드" />
          레벨테스트 결과 저장
        </button>
      </div>
    </section>
  )
}
