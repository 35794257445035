import React, { useRef, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import useAlertBeforeUnload from 'hooks/useAlertBeforeUnload'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

import ConversationRecommendPage from './components/ConversationRecommend'
import Feedback from './components/Feedback'
import FloorBtn from './components/FloorBtn'
import MyLevel from './components/MyLevel'
import RecommendCoreProduct from './components/RecommendCoreProduct'
import RecommendLevelProduct from './components/RecommendLevelProduct'
import RecommendSurvey from './components/RecommendSurvey'
import ShareSection from './components/ShareSection'
import VideoSection from './components/VideoSection'

import { levelTestAiResultValues } from './config'
import useSetDownload from './hooks/useSetDownload'
import { useSetGrade } from './hooks/useSetGrade'
import useSetState from './hooks/useSetState'
import styles from './styles/LevelTestAiResult.module.scss'

export default function LevelTestAiResult() {
  const navigate = useNavigate()
  const location = useLocation()
  const [currentPage, setCurrentPage] = useState('main')

  const handleNavigate = (page) => {
    setCurrentPage(page)
  }

  // 페이지 떠날 때 경고
  useAlertBeforeUnload('페이지를 떠나시면 결과를 확인할 수 없습니다.\n 페이지를 떠나시겠습니까?')

  // 상태 관리
  const {
    examType,
    setExamType,
    answers,
    setAnswers,
    setRecommend,
    recommend,
    feedbackData,
    setFeedbackData
  } = useLevelTestAiStore((state) => ({
    examType: state.examType,
    setExamType: state.setExamType,
    answers: state.answers,
    setAnswers: state.setAnswers,
    setRecommend: state.setRecommend,
    recommend: state.recommend,
    feedbackData: state.feedbackData,
    setFeedbackData: state.setFeedbackData
  }))

  const recommendData = levelTestAiResultValues.recommended[recommend]
  const captureRef = useRef(null)
  const { handleDownloadResult } = useSetDownload(captureRef)
  const { score } = useSetState()
  const { level: calculatedLevel } = useSetGrade(score) // Level 계산
  const level = score ? calculatedLevel : feedbackData?.level || null

  // 동영상 캡쳐 상태 관리
  const [showThumbnail, setShowThumbnail] = useState(true)
  const [isPlay, setIsPlay] = useState(false)

  const handleCaptureWithThumbnail = async () => {
    setIsPlay(false)
    setShowThumbnail(true)
    await new Promise((resolve) => setTimeout(resolve, 100))
    await handleDownloadResult()
  }

  // 테스트용 데이터 로드
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const isTestMode = searchParams.get('test') === 'true'
    const testExamType = searchParams.get('examType')

    if (isTestMode && testExamType && (!answers || answers.length === 0)) {
      if (testExamType === 'quiz') {
        setAnswers(levelTestAiResultValues.levelTestAiData['quiz'])
      } else if (testExamType === 'conversation') {
        setFeedbackData(levelTestAiResultValues.levelTestAiData['conversation'])
      }
      setRecommend('aiRealTalk')
      setExamType(testExamType)
    }
  }, [answers, location.search, setAnswers, setRecommend, setExamType, setFeedbackData])

  // 잘못된 접근 처리
  useEffect(() => {
    if (!location.search.includes('test') && (!answers || answers.length === 0)) {
      alert('잘못된 접근입니다.')
      navigate('/leveltestAi/start')
    }
  }, [answers, navigate, location.search])

  // 배너 표시
  useEffect(() => {
    window['wingBannerDisplay']()
  }, [])

  useEffect(() => {
    if (currentPage === 'conversationRecommend') {
      window.scrollTo(0, 0)
    }
  }, [currentPage])
  return (
    <main className={styles.main} ref={captureRef}>
      {currentPage === 'main' && (
        <div className={styles.inner}>
          <div className={styles.container}>
            <MyLevel level={`lv${level}`} examType={examType} score={score} />

            {/* 'quiz'일 경우에만 표시 */}
            {examType === 'quiz' && <RecommendSurvey level={`lv${level}`} />}

            <Feedback
              level={`lv${level}`}
              onImageDownload={handleCaptureWithThumbnail}
              examType={examType}
              feedbackData={feedbackData}
            />

            <ShareSection examType={examType} />

            {/* 'quiz'일 경우에만 표시 */}
            {examType === 'quiz' && (
              <>
                <RecommendLevelProduct level={`lv${level}`} />
                {recommendData && <RecommendCoreProduct recommendData={recommendData} />}
                <VideoSection
                  showThumbnail={showThumbnail}
                  setShowThumbnail={setShowThumbnail}
                  isPlay={isPlay}
                  setIsPlay={setIsPlay}
                />
              </>
            )}
            {examType === 'conversation' && (
              <FloorBtn onClick={() => handleNavigate('conversationRecommend')} />
            )}
          </div>
        </div>
      )}
      {currentPage === 'conversationRecommend' && (
        <ConversationRecommendPage
          level={`lv${level}`}
          recommendData={recommendData}
          showThumbnail={showThumbnail}
          setShowThumbnail={setShowThumbnail}
          isPlay={isPlay}
          setIsPlay={setIsPlay}
          handleNavigate={handleNavigate}
        />
      )}
    </main>
  )
}
