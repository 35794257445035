import { useState } from 'react'

import api from 'common/api'

export default function usePostLearningReview() {
  const [isSuccess, setIsSuccess] = useState(false)

  const postLearningReview = async (payload) => {
    try {
      const response = await api.post('/v2/review/learning-review', payload)

      // 방어적으로 response 데이터 처리
      const meta = response?.data?.meta
      if (meta?.code === 200) {
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
        alert(meta?.message || '이미 등록 된 후기가 존재합니다.')
        window.location.href = 'https://www.yanadoo.co.kr'
      }
    } catch (error) {
      // 요청 실패 시 에러 처리
      console.error('🚀 Post Error:', error.message, {
        payload: payload,
        apiUrl: '/v2/review/learning-review'
      })
      setIsSuccess(false)
    }
  }

  return { isSuccess, postLearningReview }
}
