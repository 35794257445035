// 공통 그룹화 유틸리티 함수
export const groupByCondition = (data, getKey, formatTitle) => {
  const groupedData = data.reduce((acc, item) => {
    const key = getKey(item) // 조건에 따라 그룹 키 계산
    if (key !== undefined && key !== null) {
      if (!acc[key]) {
        acc[key] = [] // 그룹이 없으면 초기화
      }
      acc[key].push(item) // 그룹에 데이터 추가
    }
    return acc
  }, {})

  // 객체를 배열로 변환
  return Object.keys(groupedData).map((key) => ({
    title: formatTitle(key), // 그룹 이름 포맷
    items: groupedData[key] // 그룹에 속한 데이터
  }))
}

export const filterByLevel = (data) =>
  groupByCondition(
    data,
    (item) => item.classLevelInfo, // key 계산
    (key) =>
      Number(key) === 0
        ? '종합 코스'
        : Number(key) === 1
        ? '초급 코스'
        : Number(key) === 2
        ? '초중급 코스'
        : '중고급 코스' // 타이틀 포맷
  )

export const filterByPeriod = (data) =>
  groupByCondition(
    data,
    (item) => item.periodInfo, // key 계산
    (key) => `${key}개월` // 타이틀 포맷
  )

export const filterByClassType = (data) =>
  groupByCondition(
    data,
    (item) => item.classType, // key 계산
    (key) => (Number(key) === 1 ? '케어 받으며' : '테마별 선택') // 타이틀 포맷
  )

export const filterByPrice = (data) =>
  groupByCondition(
    data,
    (item) => {
      const price = item.exceptOptionMembershipSalePrice
      if (price === undefined || price === null) return undefined
      const monthlyPrice = price / 12
      if (monthlyPrice <= 9990) return 0
      if (monthlyPrice >= 10000 && monthlyPrice <= 19999) return 1
      if (monthlyPrice >= 20000 && monthlyPrice <= 59999) return 2
      if (monthlyPrice >= 60000) return 3
    },
    (key) =>
      Number(key) === 0
        ? '~월 9,990원'
        : Number(key) === 1
        ? '월 10,000원~'
        : Number(key) === 2
        ? '월 20,000원~'
        : '월 60,000원~' // 타이틀 포맷
  )
