import { levelTestAiStartValues } from '../config'
import styles from '../styles/InfoSection.module.scss'

export default function InfoSection() {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.title}>
          <img src={`${levelTestAiStartValues.HOST_CDN}icon-start-warning.png`} alt="feature" />

          <p>
            <b>AI 레벨테스트 시</b>
            <span>주의해 주세요!</span>
          </p>
        </div>
        <ul className={styles.list}>
          <li>
            <span>1. 불법, 비윤리적인 답변은 안 돼요.</span>
            <p>
              테스트와 무관한 답변이나 불법, 비윤리적인 언급을 회피하도록 프로그래밍되어 있습니다.
            </p>
            <p>
              욕설, 비방, 성적인 표현 등을 사용할 경우 서비스 이용이 제한될 수 있으니 유의해 주세요.
            </p>
          </li>
          <li>
            <span>2. 개인 정보는 AI에게도 공유하지 마세요.</span>
            <p>필요 시, 야나두 개발팀에 의해 대화가 열람될 수 있습니다.</p>
            <p>여러분의 소중한 개인정보는 AI에게도 비밀로 지켜주세요.</p>
          </li>
          <li>
            <span>3. AI는 아직 발전 중이에요.</span>
            <p>
              때때로 문맥이나 뉘앙스를 이해하지 못하여 흐름에 맞지 않거나 엉뚱한 피드백을
              {'\n'} 줄 수도 있으니 참고해 주세요.
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}
