import { useEffect, useState } from 'react'

import classNames from 'classnames'

import { levelTestAiValues } from '../config'
import QuizTimer from './QuizTimer'
import styles from '../styles/QuizBottomSheet.module.scss'

export default function QuizBottomSheet({ quizIndex, onClickAnswer, stopEcho }) {
  const [answer, setAnswer] = useState(null)
  const [animationClass, setAnimationClass] = useState('slideUp')
  const [isTimeOver, setIsTimeOver] = useState(false)
  const [contents, setContents] = useState(null)

  const ANIMATION_TIME = 700
  const TIMEOUT_TIME = 1000
  const COMPLETE_TIME = 3000

  // 정답 클릭시
  const onSelectAnswer = (answer) => {
    if (isTimeOver) return
    setAnswer(answer)
    stopEcho()
    setTimeout(() => {
      onCompleteQuiz(answer) // 정답 클릭시 3초뒤 다음 문제로 넘어감
    }, COMPLETE_TIME)
  }

  // 시간 초과시
  const onTimeOver = () => {
    setIsTimeOver(true)
    if (!answer) {
      stopEcho() // 시간 초과시 1초 뒤 다음 문제로 넘어감
      setTimeout(() => {
        onCompleteQuiz(null)
      }, TIMEOUT_TIME)
    }
  }

  // 문제 풀이 완료시
  const onCompleteQuiz = (answer) => {
    setAnimationClass('slideDown')
    setTimeout(() => {
      onClickAnswer(answer)
    }, ANIMATION_TIME)
  }

  // 문제 변경시 >  reset State &  바텀시트 올라가는 애니메이션
  useEffect(() => {
    setAnswer(null)
    setIsTimeOver(false)
    setTimeout(() => {
      setAnimationClass('slideUp')
      setContents(levelTestAiValues.quiz[quizIndex - 1])
    }, ANIMATION_TIME)
  }, [quizIndex])

  if (!contents) return null

  return (
    <>
      <QuizTimer
        disabled={isTimeOver || !!answer}
        timeLimit={contents?.timeLimit}
        quizIndex={quizIndex}
        onTimeOver={onTimeOver}
      />
      <div className={classNames(styles.container, styles[animationClass])}>
        <span className={styles.quizIndex}>Q. {quizIndex}/10</span>
        <h1 className={styles.question}>{contents.question}</h1>
        <p className={styles.engText}>{contents.eng}</p>
        {contents.kor && <p className={styles.korText}>{contents.kor}</p>}
        {
          {
            OX: (
              <div className={styles.buttonWrap}>
                {['O', 'X'].map((item) => {
                  const isDisabled = (answer && answer !== item) || isTimeOver
                  return (
                    <button
                      disabled={isTimeOver || !!answer}
                      className={classNames(
                        styles.oxButton,
                        isDisabled && styles.disabled,
                        answer === item && styles.selected
                      )}
                      onClick={() => onSelectAnswer(item)}
                    >
                      {item === 'O' ? <OIcon /> : <XIcon />}
                    </button>
                  )
                })}
              </div>
            ),
            BLANK: (
              <ul className={styles.blankButtons}>
                {contents.answers?.map((item, index) => (
                  <TextButton
                    answer={answer}
                    isTimeOver={isTimeOver}
                    key={index}
                    item={item}
                    onSelectAnswer={onSelectAnswer}
                  />
                ))}
              </ul>
            ),
            TRANSLATE: (
              <ul className={styles.translateButtons}>
                {contents.answers?.map((item, index) => (
                  <TextButton
                    answer={answer}
                    isTimeOver={isTimeOver}
                    key={index}
                    item={item}
                    onSelectAnswer={onSelectAnswer}
                  />
                ))}
              </ul>
            )
          }[contents.type]
        }
      </div>
    </>
  )
}

const TextButton = ({ answer, isTimeOver, item, onSelectAnswer }) => {
  return (
    <li>
      <button
        disabled={isTimeOver || !!answer}
        onClick={() => onSelectAnswer(item)}
        className={classNames(
          styles.textButton,
          isTimeOver && styles.disabled,
          answer === item && styles.selected
        )}
      >
        {item}
      </button>
    </li>
  )
}

const OIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="109"
      height="109"
      viewBox="0 0 109 109"
      fill="none"
    >
      <circle cx="54" cy="54" r="32" stroke="white" strokeWidth="12" />
    </svg>
  )
}
const XIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="109"
      height="109"
      viewBox="0 0 109 109"
      fill="none"
    >
      <path
        d="M81.75 27.25L27.25 81.75M27.25 27.25L81.75 81.75"
        stroke="white"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
