import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router'

import cx from 'classnames'
import AuthService from 'services/authService'

import ModalMyInquiry from 'components/modal/ModalMyInquiry'
import InnerCol from 'components/service/InnerCol'
import MobileTitleBar from 'components/service/MobileTitleBar'
import useModalLogin from 'store/useModalLogin'

import FaqContents from './components/FaqContents'
import FaqSearch from './components/FaqSearch'

import s from './FaqMain.module.scss'
import CustomerServiceCounsel from '../center/components/CustomerServiceCounsel'

export default function FaqMain({ isPlatform }) {
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  const [inquiry, setInquiry] = useState(false)

  const { setIsModalLogin } = useModalLogin()

  const handleInquiry = () => {
    if (!isPlatform) {
      if (!AuthService.getUserInfo()) {
        setIsModalLogin(true)
        return
      }
    }

    setInquiry(true)
  }

  const handleBackButton = () => {
    if (!isPlatform) {
      navigate(-1)
    } else {
      if (document.referrer === '') {
        console.log('닫기')
      } else {
        navigate(-1)
      }
    }
  }

  return (
    <>
      {isMobile && !isPlatform && (
        <MobileTitleBar title="자주 묻는 질문" onClick={handleBackButton} />
      )}
      <article className={s.faqMain}>
        <FaqSearch isMobile={isMobile} />
        <section className={s.faqListWrap}>
          <InnerCol innerClass={s.csInner}>
            <FaqContents />
          </InnerCol>
        </section>
        <CustomerServiceCounsel handleInquiry={handleInquiry} />
      </article>
      {inquiry && <ModalMyInquiry btnClose handleCloseModal={() => setInquiry(false)} />}
    </>
  )
}
