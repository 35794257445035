import { useNavigate } from 'react-router'

import classNames from 'classnames'
import useGetUser from 'hooks/useGetUser'

import { levelTestAiResultValues } from '../config'
import downloadImage from '../images/ico_download.svg'
import styles from '../styles/Feedback.module.scss'
import Button from './common/Button'

export default function Feedback({ level, onImageDownload, examType, feedbackData }) {
  const navigate = useNavigate()
  const { user } = useGetUser()

  const feedBackValue = levelTestAiResultValues.feedBackValues[level]
  const formattedLevel = level ? level.match(/\d+/)?.[0] : 'Unknown Level'

  return (
    <section className={styles.feedbackSection}>
      {/* 피드백 영역 */}
      <div className={styles.feedback}>
        <div className={classNames(styles.badge, styles[`lv${formattedLevel}`])}>Feedback.</div>

        {user && (
          <h4>
            {user.userName}님을 위한 <br /> 맞춤 학습 피드백이에요.
          </h4>
        )}

        {/* 'examType' 타입에 따라 피드백 내용 렌더링 */}
        {examType === 'quiz'
          ? feedBackValue && <p className={styles.quizDescription}>{feedBackValue.description}</p>
          : feedbackData && (
              <div className={styles.conDescription}>
                <ul>
                  {feedbackData.problems.map((q, idx) => (
                    <li key={idx}>
                      <p className={styles.question}>
                        {q.question.startsWith(`${q.number}.`)
                          ? q.question
                          : `${q.number}. ${q.question}`}
                      </p>
                      <p className={styles.answer}>답변: {q.answer}</p>
                      <p className={styles.questionFeedback}>피드백: {q.feedback}</p>
                    </li>
                  ))}
                </ul>
                <h5>종합 평가 및 학습 가이드</h5>
                <p className={styles.totalFeedbackTitle}>연습 방법</p>
                <ul className={styles.learningGuideBox}>
                  {feedbackData?.learningMethod.map((q, idx) => (
                    <li key={idx} className={styles.learningGuide}>
                      {q}
                    </li>
                  ))}
                </ul>

                <p className={styles.totalFeedbackTitle}>종합평가</p>
                <p className={styles.totalFeedback}>{feedbackData.totalFeedback}</p>
              </div>
            )}
      </div>

      {/* 'quiz'일 경우, 정답 및 해설 버튼 표시 */}
      {examType === 'quiz' && (
        <Button
          text="정답 및 해설 확인하기"
          level={formattedLevel}
          onClick={() => navigate('/levelTestAi/answer')}
        />
      )}

      {/* 다운로드 영역 */}
      <div className={styles.download}>
        <button
          onClick={onImageDownload}
          className={classNames({
            [styles.quiz]: examType === 'quiz',
            [styles[`lv${formattedLevel}`]]: examType === 'conversation'
          })}
        >
          <span>
            <img src={downloadImage} alt="레벨테스트 결과 다운로드" />
            레벨테스트 결과 저장
          </span>
        </button>
        <p>
          * 레벨테스트 결과는 저장되지 않으니 이미지를 <br />
          다운로드 받아 저장하세요!
        </p>
      </div>
    </section>
  )
}
