import { lazy } from 'react'

import PrivateRoute from 'common/PrivateRoute'
import LevelTestAiStart from 'pages/levelTestAi/start'

const LevelTestExam = lazy(() => import('pages/levelTest/LevelTestExam'))
const LevelTestResult = lazy(() => import('pages/levelTest/LevelTestResult'))
const LevelTestStart = lazy(() => import('pages/levelTest/LevelTestStart'))
const LevelTestAdvancedLanding = lazy(() => import('pages/levelTestAdvanced/landing/index'))
const LevelTestAdvancedStart = lazy(() => import('pages/levelTestAdvanced/start'))
const LevelTestMovieExam = lazy(() => import('pages/levelTestMovie/page/LevelTestExam'))
const LevelTestMovieResult = lazy(() => import('pages/levelTestMovie/page/LevelTestResult'))
const LevelTestMovieStart = lazy(() => import('pages/levelTestMovie/page/LevelTestStart'))

const LevelTestRoutes = [
  {
    path: 'levelTest/*',
    children: [
      { path: 'levelTestStart', element: <LevelTestStart /> },
      { path: 'start', element: <LevelTestStart /> },
      { path: 'exam', element: <LevelTestExam /> },
      {
        path: 'result',
        element: (
          <PrivateRoute>
            <LevelTestResult />
          </PrivateRoute>
        )
      }
    ]
  },
  {
    path: 'levelTest2/*',
    children: [
      { path: 'start', element: <LevelTestMovieStart /> },
      { path: 'exam', element: <LevelTestMovieExam /> },
      {
        path: 'result',
        element: (
          <PrivateRoute>
            <LevelTestMovieResult />
          </PrivateRoute>
        )
      }
    ]
  },
  {
    path: 'levelTestAdvanced/*',
    children: [
      { path: 'landing', element: <LevelTestAdvancedLanding /> },
      {
        path: 'start',
        element: (
          <PrivateRoute>
            <LevelTestAdvancedStart />
          </PrivateRoute>
        )
      }
    ]
  },
  {
    path: 'levelTestAi/*',
    children: [
      {
        path: 'start',
        element: <LevelTestAiStart />
      }
    ]
  }
]

export default LevelTestRoutes
