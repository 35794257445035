import React from 'react'

import cx from 'classnames'

import { reviewWriteValues } from '../config'
import s from './styles/ReviewRating.module.scss'

export default function ReviewRating({ rate, handleRatePoint }) {
  const { rating } = reviewWriteValues

  return (
    <section className={s.reviewRating}>
      <h3>만족도는 어떠셨나요?</h3>
      <ul className={cx(s.ratingBox, { [s[`point${rate}`]]: rate > 0 })}>
        {rating.map((rate, index) => (
          <li key={index} onClick={() => handleRatePoint(rate)}>
            <img
              src="https://english.yanadoocdn.com/upload/yanadoo/assets/images/bg_star_215x215.png"
              alt="별점"
            />
          </li>
        ))}
      </ul>
      <p>별점을 선택해주세요.</p>
    </section>
  )
}
