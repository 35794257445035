export const categoryListValues = {
  nav: [
    {
      title: { button: '학습 레벨', list: '학습 레벨별 강의' },
      type: 'level'
    },
    {
      title: { button: '판매 가격', list: '판매 가격별 강의' },
      type: 'price'
    },
    {
      title: { button: '수강 기간', list: '수강 기간별 강의' },
      type: 'period'
    },
    {
      title: { button: '학습 방법', list: '학습 방법별 강의' },
      type: 'classType'
    }
  ],
  data: [
    {
      imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_04.png',
      imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_04.png',
      linkType: 'LINK',
      linkPath: '/store/detail/1000837',
      title: '원어민이 매일 쓰는 IDIOM',
      subTitle: '미드에서 가장 많이\n쓰는 표현 TOP60',
      desc: '원어민이 가장 많이 사용하는\n이디엄 베스트 표현 배우기',
      exceptOptionMembershipSalePrice: 49000,
      classLevelInfo: 1,
      periodInfo: 6,
      target: '_self',
      sortOrder: 1
    },
    {
      imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_05.png',
      imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_05.png',
      linkType: 'LINK',
      linkPath: '/store/detail/1000839',
      title: '비즈니스 응급키트',
      subTitle: '현업에서 바로 써먹는\n단기 실전영어',
      desc: '현대인에게 부담 없는 하루 10분으로\n배우는 단기 비즈니스 영어',
      exceptOptionMembershipSalePrice: 84000,
      classLevelInfo: 1,
      periodInfo: 6,
      target: '_self',
      sortOrder: 2
    },
    {
      imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_01.png',
      imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_01.png',
      linkType: 'LINK',
      linkPath: '/store/detail/1001883',
      title: '써먹는 마법 VOCA',
      subTitle: '지금 바로 써먹는\n왕초보 VOCA 정복',
      desc: '웹툰과 스토리텔링 기법을\n활용한 VOCA 완전 정복',
      exceptOptionMembershipSalePrice: 108500,
      classLevelInfo: 1,
      periodInfo: 6,
      target: '_self',
      sortOrder: 3
    },
    {
      imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_02.png',
      imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_02.png',
      linkType: 'LINK',
      linkPath: '/store/detail/1002320',
      title: '오디오 영어 패키지',
      subTitle: '눈 감고도 배우는\n신개념 영어공부',
      desc: '완전히 새로운 고 몰입 영어 학습법\n듣기, 읽기, 말하기까지 완전 정복',
      exceptOptionMembershipSalePrice: 199000,
      classLevelInfo: 2,
      periodInfo: 6,
      classType: 2,
      target: '_self',
      sortOrder: 4
    },
    {
      imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_03.png',
      imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_03.png',
      linkType: 'LINK',
      linkPath: '/store/detail/1000851',
      title: '원어민 상황영어 패키지',
      subTitle: '200가지 상황으로\n배우는 리얼 현지영어',
      desc: '마치 해외 현지에서 원어민과\n대화하듯 상황에 따른 영어 배우기',
      exceptOptionMembershipSalePrice: 239000,
      classLevelInfo: 2,
      periodInfo: 6,
      target: '_self',
      sortOrder: 5
    },
    {
      imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_06.png',
      imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_06.png',
      linkType: 'LINK',
      linkPath: '/store/detail/1001882',
      title: '해외여행 응급키트 패키지',
      subTitle: '해외 올로케!\n세계 12개국 여행영어',
      desc: '해외 현지에서 발생 할 수 있는\n모든 상황을 담은 완벽한 여행 준비',
      exceptOptionMembershipSalePrice: 239000,
      classLevelInfo: 1,
      periodInfo: 12,
      classType: 2,
      target: '_self',
      sortOrder: 6
    },
    {
      imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/thumb_10minute.png',
      imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/thumb_10minute.png',
      linkType: 'LINK',
      linkPath: '/promotion-landing/bestAwards',
      title: '10분 패키지',
      subTitle: '이제 영어는 딱 10분으로\n짧고 쉽게!',
      desc: '집요한 10분 연구소에서 탄생한\n164만 성공노하우 10분 패키지',
      exceptOptionMembershipSalePrice: 477000,
      classLevelInfo: 0,
      periodInfo: 12,
      classType: 1,
      target: '_self',
      sortOrder: 7
    },
    {
      imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_08.png',
      imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_08.png',
      linkType: 'LINK',
      linkPath: '/store/detail/1000848',
      title: '랜선 미국연수 패키지',
      subTitle: '미국 현지에서 사용하는\n진짜 생활 영어',
      desc: '미국 가족들이이 일상에서 일어나는\n에피소드로 실전 감각 익히기',
      exceptOptionMembershipSalePrice: 299000,
      classLevelInfo: 3,
      periodInfo: 12,
      classType: 2,
      target: '_self',
      sortOrder: 8
    },
    {
      imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_11.png',
      imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_11.png',
      linkType: 'LINK',
      linkPath: '/store/detail/1001870',
      title: '홈스쿨링 주니어 패키지',
      subTitle: '집에서 배우는\n미국 초등교과 과정',
      desc: '미국 정규 과정을 그대로 가져와\n체계적 학습플랜의 주니어 강의',
      exceptOptionMembershipSalePrice: 299000,
      classLevelInfo: 2,
      periodInfo: 12,
      target: '_self',
      sortOrder: 9
    },
    {
      imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_09.png',
      imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_09.png',
      linkType: 'LINK',
      linkPath: '/store/detail/1000853',
      title: '현직자 필수! 비스니스 영어 패키지',
      subTitle: '현업에 필수 표현만\n선정한 실전 영어',
      desc: '미국 대기업 현직자 강력추천!\n활용도 200% 비즈니스 영어',
      exceptOptionMembershipSalePrice: 299000,
      classLevelInfo: 2,
      periodInfo: 12,
      classType: 2,
      target: '_self',
      sortOrder: 10
    },
    {
      imagePath1: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_12.png',
      imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/product/img_prd_thumb_12.png',
      linkType: 'LINK',
      linkPath: '/store/detail/1002009',
      title: '초급맞춤 패턴영어 패키지',
      subTitle: '더 이상 암기 없이\n문장의 패턴을 배운다',
      desc: '내가 아는 단어 하나면 수 십가지\n문장을 만들어 내는 패턴 노하우 배우기',
      exceptOptionMembershipSalePrice: 299000,
      classLevelInfo: 1,
      periodInfo: 6,
      target: '_self',
      sortOrder: 11
    }
  ]
}
