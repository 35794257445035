import html2canvas from 'html2canvas'

/**
 * AI 레벨 테스트 결과 화면 다운로드를 처리
 * - 결과 화면을 캡쳐하여 이미지로 다운로드하는 함수
 * @param {React.RefObject} captureRef - 결과를 캡쳐할 Ref 객체
 * @returns {handleDownloadResult} - 결과 화면을 캡쳐하여 이미지로 다운로드하는 함수
 */
export default function useSetDownload(captureRef) {
  const handleDownloadResult = async () => {
    if (!captureRef || !captureRef.current) {
      console.error('Invalid captureRef: Ensure it is properly attached to a DOM element.')
      return
    }

    try {
      // 화면 캡쳐
      const canvas = await html2canvas(captureRef.current, { useCORS: true })

      // Base64 방식으로 데이터 생성
      const dataUrl = canvas.toDataURL('image/jpeg') // JPEG로 변환 (용량 줄이기)
      const fileName = `AilevelTest_${Date.now()}.jpg`

      // 다운로드 처리
      const link = document.createElement('a')
      link.href = dataUrl
      link.download = fileName
      document.body.appendChild(link)

      // 크롬, 사파리: 링크 클릭
      link.click()

      // 다운로드가 지원되지 않을 경우 백업 동작
      setTimeout(() => {
        if (!link.href) {
          window.open(dataUrl, '_blank') // 새 창에서 이미지 열기
        }
      }, 100)

      // 링크 정리
      document.body.removeChild(link)
    } catch (error) {
      console.error('Error while capturing and downloading the result:', error)
    }
  }

  return { handleDownloadResult }
}
