import { useState } from 'react'

import api from 'common/api'
import * as config from 'common/config'

export default function usePostImagesUpload() {
  const [isUploading, setIsUploading] = useState(false)

  const postImagesUpload = async (fileImages) => {
    try {
      if (!fileImages || fileImages.length === 0) return

      setIsUploading(true)

      const formData = new FormData()
      fileImages
        .filter((file) => file.type.startsWith('image/'))
        .forEach((file) => formData.append('files', file))

      formData.append('resizeType', 'REVIEW')

      const response = await api.post('/v2/file/image-upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      const { meta, data } = response.data

      if (meta.code !== 200) {
        alert(meta.message || config.MESSAGE['common-error'])
        return
      }

      return data
    } catch (error) {
      console.error('Image upload failed:', error)
    } finally {
      setIsUploading(false)
    }
  }

  return { isUploading, postImagesUpload }
}
