import React from 'react'
import { Link } from 'react-router-dom'

import s from './ProductItem.module.scss'

export default function ProductItem({ item }) {
  return (
    <Link to={item.linkPath} className={s.productItem}>
      <div
        className={s.thumb}
        style={{
          backgroundImage: `url(${item.imagePath1})`
        }}
      />
      <div className={s.contents}>
        <strong>{item.subTitle}</strong>
        <p>{item.desc}</p>
        <button>바로가기</button>
        <div className={s.testInfo}>
          <div>판매가격(12개월) : {item.exceptOptionMembershipSalePrice / 12}</div>
          <div>학습레벨 : {item.classLevelInfo} </div>
          <div>수강기간(개월) : {item.periodInfo / 30}</div>
          <div>학습방법 : {item.classType}</div>
        </div>
      </div>
    </Link>
  )
}
