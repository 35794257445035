import classNames from 'classnames'

import ExamTitle from './ExamTitle'
import QuizDescription from './QuizDescription'
import { levelTestAiValues } from '../config'
import ConversationBottomSheet from './ConversationBottomSheet'
import useLevelTestAiConversation from '../hooks/useLevelTestAiConversation'
import styles from '../styles/StepExam.module.scss'

export default function Conversation({ onCompleteExam }) {
  const {
    descriptionIndex,
    toastMessageClass,
    descriptionFadeClass,
    quizIndex,
    mode,
    status,
    onChangeStatus,
    onCompleteRecording
  } = useLevelTestAiConversation({
    onCompleteExam
  })

  const contents = levelTestAiValues.conversationStepDescription[descriptionIndex]
  const commonDescriptionClasses = [
    styles.quizDescription,
    styles[descriptionFadeClass],
    styles.conversation
  ]

  return (
    <>
      <div className={styles.examTitleBg}></div>
      <p className={classNames(styles.toastMessage, styles[toastMessageClass])}>
        이제부터 <b>자막없이</b> 대화를 시작합니다.
        <br />
        제한 시간 내에 영어로 답변해 주세요.
      </p>
      {status &&
        (mode === 'conversation' ? (
          <ConversationBottomSheet
            quizIndex={quizIndex}
            status={status}
            onChangeStatus={onChangeStatus}
            onCompleteRecording={onCompleteRecording}
          />
        ) : (
          <ExamTitle fadeClass={descriptionFadeClass} title={contents?.title} />
        ))}

      {descriptionIndex > 1 ? (
        <QuizDescription contents={contents} classes={commonDescriptionClasses} />
      ) : (
        <div className={classNames(...commonDescriptionClasses)}>
          {descriptionIndex === 0 && (
            <QuizDescription
              contents={levelTestAiValues.conversationStepDescription?.[0]}
              classes={[styles.quizDescriptionFirst]}
            />
          )}
          <QuizDescription
            contents={levelTestAiValues.conversationStepDescription?.[1]}
            classes={[styles.quizDescriptionSecond]}
          />
        </div>
      )}
    </>
  )
}
