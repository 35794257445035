import React from 'react'

import classNames from 'classnames'

import styles from '../../styles/LevelTestAiResult.module.scss'

export default function Button({ text, onClick, level }) {
  return (
    <div className={styles.btnWrap}>
      <button
        className={classNames(styles.button, styles[`lv${level}`])} // level에 따른 클래스 동적 적용
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  )
}
