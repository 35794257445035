import React from 'react'
import { useMediaQuery } from 'react-responsive'

import cx from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'

import ProductItem from './ProductItem'
import s from './ProductList.module.scss'

export default function ProductList({ title, listData }) {
  const isMobile = useMediaQuery({ maxWidth: 750 })
  if (!listData || listData.length < 1) return <></>
  return (
    <section className={cx(s.productListWrap, isMobile ? s.productListSwiper : s.productList)}>
      <div className={s.innerCol}>
        <h3>{title}</h3>
        {isMobile ? (
          <Swiper slidesPerView={1.2} spaceBetween={20} className={s.swiperWrap}>
            {listData.map((item, index) => (
              <SwiperSlide key={index}>
                <ProductItem item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <ul>
            {listData.map((item, index) => (
              <li key={index}>
                <ProductItem item={item} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  )
}
