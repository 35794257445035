import ShareKakao from 'pages/aiTalk/components/share/ShareKakao'

import { levelTestAiValues } from '../../exam/config'
import styles from '../styles/PCTopSection.module.scss'

export default function PCTopSection() {
  const url = `${process.env.REACT_APP_HOST_URL}/levelTestAi/start`

  // URL 복사하기
  const onClickCopyUrl = (e) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert('URL 복사가 완료되었습니다!')
      })
      .catch((err) => {
        alert('URL 복사가 실패했습니다')
      })
  }

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <p className={styles.subTitle}>
            <b>오드리쌤</b>에게 <b>1:1</b> 영어 학습 진단을 받고 싶다면?
          </p>
          <p className={styles.title}>
            모바일로 AI 레벨테스트를
            <br />
            진행해보세요!
          </p>
          <div className={styles.description}>
            <p>
              단 3분! AI 오드리와 함께
              <br />
              나의 영어 실력을 확인하고, 개인별 맞춤 피드백을 받아보세요.
            </p>
          </div>
          <img
            className={styles.cardImage}
            src={`${levelTestAiValues.HOST_CDN}image-pc-card.png`}
            alt="퀴즈형 대화형 결과"
          />
        </div>
      </div>
      <div className={styles.bg}></div>
      <div className={styles.videoInner}>
        <div className={styles.video}>
          <video loop muted playsInline autoPlay>
            <source src={`${levelTestAiValues.HOST_CDN}video-pc-audrey.mov`} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className={styles.sidebarContainer}>
        <div className={styles.sidebar}>
          <img
            className={styles.sidebarBubble}
            src={`${levelTestAiValues.HOST_CDN}image-pc-bubble.png`}
            alt="테스트는 모바일로만 가능하니,
아래 QR을 이용해주세요!"
          />
          <p className={styles.sidebarTitle}>
            AI 레벨테스트
            <br />
            (with 오드리)
          </p>
          <ul>
            <li>
              <img src={`${levelTestAiValues.HOST_CDN}image-pc-qr.png`} alt="AI 레벨테스트 QR" />
              <p className={styles.name}>QR찍고 이동하기</p>
            </li>
            <li>
              <ShareKakao
                pathname="/levelTestAi/start"
                className={styles.kakaoShareButton}
                meta={{
                  ...levelTestAiValues.metaInfo,
                  og: levelTestAiValues.metaInfo.imgsrc
                }}
              >
                <img src={`${levelTestAiValues.HOST_CDN}image-pc-kakaotalk.png`} alt="kakao" />
                <p className={styles.name}>카톡으로 링크 보내기</p>
              </ShareKakao>
            </li>
            <li>
              <button onClick={onClickCopyUrl}>
                <img src={`${levelTestAiValues.HOST_CDN}image-pc-link.png`} alt="link" />
                <p className={styles.name}>URL 복사하기</p>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
