/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo } from 'react'

import classNames from 'classnames'

import { levelTestAiValues } from '../config'
import styles from '../styles/ConversationBottomSheet.module.scss'

export default function RecordingButton({
  status,
  disabled,
  handleRecognition,
  transcript,
  onChangeStatus,
  onCompleteRecording
}) {
  const onClickRecording = () => {
    if (status === 'prepareEnd') {
      onClickRecordingStart()
    }
    if (status === 'recording') {
      onClickCompleteRecording()
    }
  }

  //   녹음 버튼 클릭시 녹음시작
  const onClickRecordingStart = useCallback(() => {
    onChangeStatus('recording')
    handleRecognition?.start()
  }, [])

  //  녹음 완료 버튼 클릭시 녹음 종료
  const onClickCompleteRecording = useCallback(() => {
    const trimMessage = transcript.trim()
    if (!!trimMessage) {
      onCompleteRecording(trimMessage)
      setTimeout(() => {
        handleAbort()
      }, 1200)
    } else {
      console.error('죄송합니다. 이해하지 못했어요. 다시 한번 말씀해 주시겠어요?')
    }
  }, [handleRecognition])

  // 녹음 취소 버튼 클릭시 녹음 취소
  const onClickCancel = useCallback(() => {
    onChangeStatus('prepareEnd')
    handleRecognition.abort()
  }, [handleRecognition, onChangeStatus])

  // 녹음 취소
  const handleAbort = useCallback(() => {
    handleRecognition.abort()
  }, [handleRecognition])

  // 녹음 재시작 버튼 클릭시 녹음 재시작
  const onClickReStart = useCallback(() => {
    handleRecognition.restart()
  }, [handleRecognition])

  const description = useMemo(() => {
    return {
      prepare: '문제를 \n준비하고 있습니다.',
      prepareEnd: '버튼을 눌러\n빠르게 답변해주세요.',
      timeover: '답변 시간이\n종료되었습니다.',
      recording: transcript
    }[status]
  })

  useEffect(() => {
    if (!status) {
      handleAbort()
    }
  }, [status])

  return (
    <>
      {description && <h1 className={styles.description}>{description}</h1>}
      <div className={styles.buttonContainer}>
        {status === 'recording' && (
          <button onClick={onClickCancel} className={styles.cancelButton}>
            취소
          </button>
        )}
        <button
          disabled={disabled}
          className={classNames(styles.button, styles[status])}
          onClick={onClickRecording}
        >
          <img
            src={`${levelTestAiValues.HOST_CDN}icon-recording-${
              status === 'recording' ? 'send' : 'microphone'
            }.svg`}
            alt="녹음"
          />
        </button>
        {status === 'recording' && (
          <button onClick={onClickReStart} className={styles.reButton}>
            재녹음
          </button>
        )}
      </div>
    </>
  )
}
