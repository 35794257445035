import { useLocation } from 'react-router'

import ResponsiveImage from 'components/ResponsiveImage'

import { keyVisualBannerValues } from './config'

export default function KeyVisualBanner() {
  const location = useLocation()

  // 현재 경로에 해당하는 배너 키 찾기
  const bannerKey = Object.keys(keyVisualBannerValues.paths).find((key) =>
    keyVisualBannerValues.paths[key]
      .map((path) => path.toLowerCase())
      .includes(location.pathname.toLowerCase())
  )

  // 해당 키값으로 배너 데이터 가져오기
  const banner = bannerKey ? keyVisualBannerValues.banners[bannerKey] : null

  // 경로와 일치하지 않는 경우 배너를 렌더링하지 않음
  if (!banner) return null

  return (
    <div
      style={{ textAlign: 'center', background: banner.background ? banner.background : '#fff' }}
    >
      <ResponsiveImage
        style={{ maxWidth: '1920px', display: 'inline-block' }}
        maxWidth={750}
        pc={banner.pc}
        mo={banner.mobile}
      />
    </div>
  )
}
