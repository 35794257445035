import React from 'react'

import s from './styles/ReviewTextarea.module.scss'

export default function ReviewTextarea({ content, onChangeText }) {
  return (
    <section className={s.reviewTextarea}>
      <textarea
        value={content}
        onChange={onChangeText}
        placeholder="어떤 점이 만족스러웠나요? 최소 10자 이상 입력해주세요!"
      />
      <div className={s.textLength}>
        <em>{content.length}</em>/1000
      </div>
    </section>
  )
}
