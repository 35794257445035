import { levelTestAiStartValues } from '../config'
import styles from '../styles/FeatureSection.module.scss'

export default function FeatureSection() {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <p className={styles.title}>야나두 AI 레벨테스트</p>
        <p className={styles.description}>무엇이 다른가요?</p>
        <ul className={styles.list}>
          {levelTestAiStartValues.featureList.map((feature, index) => (
            <li key={index}>
              <img
                src={`${levelTestAiStartValues.HOST_CDN}icon-start-feature-0${index + 1}.png`}
                alt="feature"
              />
              <div>
                <p className={styles.featureTitle}>{feature.description}</p>
                <p className={styles.featureDescription}>{feature.title}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
