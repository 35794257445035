import { YANADOO_CDN_HOST } from 'common/config'
export const LTA_HOST_CDN = `${YANADOO_CDN_HOST}/levelTestAdvanced/2024/`
export const levelTestAiAnswerValues = {
  1: {
    type: 'ox',
    answerType: 'type1',
    question: '바르게 번역이 되었으면 O, 틀리면 X를 선택해 주세요.',
    text1: 'I’m feeling so fired up.',
    text3: '나는 정말 지쳤어.',
    buttonGroup: {
      answer: 'X',
      list: ['O', 'X'],
      score: 5
    },
    comment:
      '"Fired up"은 주로 열정적이거나 흥분된 상태를 의미하므로, "정말 지쳤다"는 표현과 맞지 않습니다. "정말 지쳤어."는 "I’m feeling so drained" 또는 "I’m so exhausted"라고 말할 수 있습니다.'
  },
  2: {
    type: 'ox',
    answerType: 'type1',
    question: '바르게 번역이 되었으면 O, 틀리면 X를 선택해 주세요.',
    text1: 'I’m over the moon.',
    text3: '나는 정말 기뻐.',
    buttonGroup: {
      answer: 'O',
      list: ['O', 'X'],
      score: 5
    },
    comment: '"Over the moon"은 매우 행복하거나 기쁜 상태를 표현하는 관용구입니다. '
  },
  3: {
    type: 'blank',
    answerType: 'type2',
    question: '빈칸에 들어갈 단어는 무엇일까요?',
    text1: `I go to work _________ subway.`,
    text3: <>출근할 때 지하철을 타고 가.</>,
    buttonGroup: {
      answer: 'by',
      list: ['on', 'by', 'with', 'in'],
      score: 10
    },
    listBlock: true,
    comment:
      '일반적인 교통수단으로 지하철을 이용한다는 의미를 나타내기 위해 "by subway"를 사용합니다. 다른 전치사인 "on"은 표면 위에 있거나 대중교통 내부를 강조할 때, "in"은 소형 교통수단 내부를 나타낼 때 사용합니다.'
  },
  4: {
    type: 'blank',
    answerType: 'type2',
    question: '빈칸에 들어갈 단어는 무엇일까요?',
    text1: `It dosen’t ring a _________. `,
    text3: <>기억이 나지 않아요.</>,
    buttonGroup: {
      answer: 'bell',
      list: ['phone', 'call', 'light', 'bell'],
      score: 10
    },
    listBlock: true,
    comment:
      '"Ring a bell"은 무언가를 듣고 익숙하다고 느끼거나 기억이 날 때 사용하는 관용 표현입니다. "bell"이 아닌 다른 단어들은 이 관용 표현에 적합하지 않습니다. 따라서 정답은 "bell"입니다.'
  },
  5: {
    type: 'blank',
    answerType: 'type2',
    question: '빈칸에 들어갈 단어는 무엇일까요?',
    text1: `This coffee is my _________!`,
    text3: <>이 커피는 내가 쏠게!</>,
    buttonGroup: {
      answer: 'treat',
      list: ['reward', 'shot', 'treat', 'thing'],
      score: 15
    },
    listBlock: true,
    comment:
      '"My treat"은 내가 계산을 하겠다는 의미의 관용 표현입니다. "shot," "reward," "thing"은 문맥상 어울리지 않으며, 대접이나 한턱을 의미할 때 "treat"가 적합합니다.'
  },
  6: {
    type: 'blank',
    answerType: 'type2',
    question: '빈칸에 들어갈 단어는 무엇일까요?',
    text1: `My make up is _________. `,
    text3: <>내 화장이 떴어.</>,
    buttonGroup: {
      answer: 'caked',
      list: ['baked', 'cracked', 'cooked', 'caked'],
      score: 15
    },
    listBlock: true,
    comment:
      '"Caked"는 화장이 두껍게 발리거나 뜨는 상태를 묘사할 때 사용됩니다. "baked, cooked, cracked"는 모두 화장의 상태를 나타내는 표현으로 사용되지 않으므로 정답은 "caked"입니다.'
  },
  7: {
    type: 'blank',
    answerType: 'type2',
    question: '빈칸에 들어갈 단어는 무엇일까요?',
    text1: `I'm a dollar _________. `,
    text3: <>1 달러가 부족해.</>,
    buttonGroup: {
      answer: 'short',
      list: ['short', 'wide', 'deep', 'long'],
      score: 15
    },
    listBlock: true,
    comment:
      '"Be short"는 부족하다는 의미로, 금액이 모자랄 때 사용됩니다. "wide, deep, long"은 문맥상 맞지 않으므로 정답은 "short"입니다.'
  },
  8: {
    type: 'blank',
    answerType: 'type2',
    question: '빈칸에 들어갈 단어는 무엇일까요?',
    text1: `I'm feeling a bit under the _________. `,
    text3: <>몸이 조금 안 좋아요.</>,
    buttonGroup: {
      answer: 'weather',
      list: ['sea', 'sun', 'weather', 'earth'],
      score: 15
    },
    listBlock: true,
    comment:
      '"Under the weather"는 몸 상태가 좋지 않을 때 사용하는 관용 표현입니다. "sun, sea, earth"는 이런 의미를 전달하지 않으므로 정답은 "weather"입니다.'
  },
  9: {
    type: 'translation',
    answerType: 'type3',
    question: '올바르게 해석한 문장을 골라주세요',
    text1: `I'm feeling blue.`,
    buttonGroup: {
      answer: '나는 우울해.',
      list: ['나는 기분이 좋아졌어.', '나는 우울해.'],
      score: 5
    },
    comment:
      '"Feeling blue"는 우울하거나 슬픈 기분을 표현하는 관용구입니다. "기분이 좋아졌다"는 반대의 의미이므로 문맥에 맞지 않습니다.'
  },
  10: {
    type: 'translation',
    answerType: 'type3',
    question: '올바르게 해석한 문장을 골라주세요',
    text1: `I'm going to hit the sack.`,
    buttonGroup: {
      answer: '이제 자러 갈거야.',
      list: ['이제 자러 갈거야.', '이제 운동하러 갈거야.'],
      score: 5
    },
    comment:
      '"Hit the sack"은 잠자리에 든다는 의미의 관용 표현입니다. 운동하러 간다는 의미로 사용되지 않으므로, 정답은 "이제 자러 갈거야."입니다.'
  }
}
