import React from 'react'

import s from './styles/ReviewTitleBar.module.scss'

export default function ReviewTitleBar() {
  return (
    <section className={s.reviewTitleBar}>
      <h2>수강 후기 작성</h2>
    </section>
  )
}
