import { YANADOO_CDN_HOST } from 'common/config'

import ArrowSvg from './components/ArrowSvg'
import { APPLE_PACKAGE_HOST_CDN } from 'pages/promotion/applePackage/config'
import { BEST_AWARDS_HOST_CDN } from 'pages/promotion/bestAwards/config'
import { BEST_PERFORM_CDN } from 'pages/promotion/bestAwardsDBOnly_performance/config'
import { IPAD_DOUBLE_HOST_CDN } from 'pages/promotion/ipadDouble/config'
import { MACBOOK_PACKAGE_HOST_CDN } from 'pages/promotion/macbookPackage/config'
import { STANDBYME_HOST_CDN } from 'pages/promotion/standByMe/config'

export const COUNSEL_TIME = [
  '상담가능시간', // 띄어쓰기 X 퍼포먼스 팀 요청
  '10:00 ~ 11:00',
  '11:00 ~ 12:00',
  '12:00 ~ 13:00',
  '13:00 ~ 14:00',
  '14:00 ~ 15:00',
  '15:00 ~ 16:00',
  '16:00 ~ 17:00',
  '17:00 ~ 18:00',
  '언제든 연락주세요'
]
export const AGE_OPTION = ['연령대를 선택해 주세요', '20대', '30대', '40대', '50대 이상']
export const STUDY_GOAL = [
  '학습목표를 선택해 주세요',
  '자기개발',
  '해외여행',
  '외국인과의 대화',
  '커리어 향상',
  '기타'
]
export const DEVICE_OPTION = ['Apple 풀패키지', 'iPad 10세대', 'MacBook Air', 'LG gram 15']
export const DEVICE_OPTION2 = [
  '원하는 기기를 선택해 주세요',
  '1대면 충분해요 [아이패드 10세대]',
  '가족, 친구와 함께 [아이패드 더블득템]',
  '가벼워서 휴대하기 딱 [아이패드 Air 6]',
  '펜, 키보드까지 [Apple 풀패키지]'
]
/**
 [UI 관련 설정]
 - 공통 설정
 * category : 내부상담 카테고리 접수루트 (필수)
 * timer : 타이머 표시 여부
 * buttonBackground: 버튼 배경색
 * buttonColor : 버튼 글자색
- 하단 고정배너 설정
 * bottomBannerBackground: 배경색
 * bottomBannerTitle: 타이틀
 * bottomLeftText: 왼쪽 텍스트
 * bottomButtonText: 버튼 텍스트
 * speechBubbleBackground : 말풍선 배경색
 * bottomExpandButtonText: 확장시 버튼 텍스트
 * bottomBannerExpandType: 확장형 배너 타입 ('C1'|'C3'|'C4' > 피그마 기준 / default: 'C1')
 - 모달 관련 설정
 * modalTitle: 타이틀 텍스트
 * modalButtonText: 모달 버튼 텍스트
 * modalBanner: 모달 상단 이미지
 *
 [FORM 관련 설정]
 * content : 상담 요청 내용
 * alertModalBanner : 추가 정보 입력 모달 배너 이미지(퍼포먼스 페이지에서만 사용)
 * noCounselTime : "상담 가능 시간"을 보여주지 않을지 여부
 * hasGoalOption : "학습 목표" 선택 옵션을 보여줄지 여부
 * hasAgeOption : "연령대" 선택 옵션을 보여줄지 여부
 * hasDeviceOption : "상담 선택 기기" 선택 옵션을 보여줄지 여부
 * hasStudyDevice : 아이패드 선택 옵션
 */

export const dbCollectionFormValues = {
  macbookPackagePerformance: {
    category: 'New_macbookPackage',
    bottomBannerBackground: '#28284B',
    buttonBackground: 'linear-gradient(90deg, #15BAFF 0%, #621CF5 100%)',
    buttonColor: '#fff',
    modalButtonText: '빠른 상담하기',
    timer: true,
    speechBubbleBackground: '#0094FF',
    alertModalBanner: {
      pc: `${BEST_PERFORM_CDN}img_db-question-banner.png`,
      alt: '상담 신청 감사합니다! 전문가의 맞춤 상담을 위해 추가 정보를 기입해 주세요.'
    }
  },
  laptopPackagePerformance: {
    category: 'New_laptopPackage',
    bottomBannerBackground: '#28284B',
    buttonBackground: 'linear-gradient(90deg, #91E9FF 0%, #F3A5FE 100%)',
    buttonColor: '#000',
    modalButtonText: '빠른 상담하기',
    timer: false,
    alertModalBanner: {
      pc: `${BEST_PERFORM_CDN}img_db-question-banner.png`,
      alt: '상담 신청 감사합니다! 전문가의 맞춤 상담을 위해 추가 정보를 기입해 주세요.'
    }
  },
  bestAwardsDBOnlyPerformance: {
    category: '10min_DB',
    bottomBannerBackground: '#000000',
    buttonBackground: '#336bee',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${BEST_PERFORM_CDN}img_db-banner.png`,
      alt: '지금 상담 신청하면 영어 평생지원 왕초보 탈출!'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg />
      </>
    ),
    timer: true,
    modalTitle: (
      <>
        지금 결제하지 마세요 <br />
        상담 먼저 받고 결정하세요!
      </>
    ),
    speechBubbleBackground: '#F53B00',
    alertModalBanner: {
      pc: `${BEST_PERFORM_CDN}img_db-question-banner.png`,
      alt: '상담 신청 감사합니다! 전문가의 맞춤 상담을 위해 추가 정보를 기입해 주세요.'
    }
  },
  applePackage: {
    category: 'applePackage',
    buttonBackground: 'linear-gradient(90deg, #FF0059 0%, #9316F7 100%)',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_db-modal-applePackage.png`,
      alt: '애플 풀 패키지 선착순 한정 추가 증정 혜택'
    },
    modalButtonText: (
      <>
        Apple 풀패키지 신청하기
        <ArrowSvg />
      </>
    ),
    bottomBannerTitle: '선착순 Apple 풀패키지 혜택 신청',
    timer: false
  },
  applePackageUI: {
    category: 'applePackageUI',
    buttonBackground: 'linear-gradient(90deg, #FF0059 0%, #9316F7 100%)',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2024/bnr_pc_popup_applepackage.png`,
      alt: '애플 풀 패키지 선착순 한정 추가 증정 혜택'
    },
    modalButtonText: (
      <>
        Apple 풀패키지 신청하기
        <ArrowSvg />
      </>
    ),
    bottomBannerTitle: '선착순 Apple 풀패키지 혜택 신청',
    timer: false
  },
  macbookPackageForm: {
    category: 'macbookPackage',
    buttonBackground: 'linear-gradient(to right, #4200ff, #7200ff)',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_db-modal-macbookPackage.png`,
      alt: '애플 풀 패키지 선착순 한정 추가 증정 혜택'
    },
    modalButtonText: 'MacBook 신청하기',
    timer: true,
    noCounselTime: true,
    hasGoalOption: true,
    hasAgeOption: true
  },
  macbookPackageFloating: {
    category: 'macbookPackage',
    bottomBannerExpandType: 'C4',
    buttonBackground: '#FFB900',
    buttonColor: '#222',
    modalButtonText: 'MacBook 신청하기',
    timer: true,
    bottomButtonText: '바로 신청하기',
    bottomExpandButtonText: `상담 \n예약하기`,
    noCounselTime: true,
    hasGoalOption: true,
    timerTitle: '설 혜택 마감 🎁',
    targetDate: '20250207'
  },
  macbookPackageImac: {
    category: 'macbookPackage',
    content: '상담 선택 기기 : iMac',
    buttonBackground: '#6511ff',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${MACBOOK_PACKAGE_HOST_CDN}2023/nov/img_pc_imac_popup.png`,
      alt: '선착순 한정 수량 iMac 드려요'
    },
    modalButtonText: 'iMac 상담 신청하기',
    timer: true
  },
  ipadDouble: {
    timer: true,
    hasStudyDevice: true,
    category: 'ipadDouble',
    buttonBackground: `url(${IPAD_DOUBLE_HOST_CDN}bg-modal-button.png)`,
    buttonColor: '#000',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_db-modal-ipadDouble.png`,
      alt: '아이패드10 더블득템 평생 수강 시작만 하면 조건없이 증정 재고 소진 시 종료'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg isBlack />
      </>
    ),
    noCounselTime: true,
    hasGoalOption: true,
    hasAgeOption: true
  },
  bestAwards: {
    category: 'mainLandingB',
    buttonBackground: '#F5420B',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${BEST_AWARDS_HOST_CDN}img-db-banner.png`,
      alt: '오늘 한정 수량이 소진되었어요. 추가 수량 확보 시 실시간으로 알려드릴게요!'
    },
    modalButtonText: (
      <>
        10분 패키지 오픈 알림 신청하기
        <ArrowSvg />
      </>
    ),
    timer: false
  },
  standByMe: {
    category: 'standByMe',
    buttonBackground: '#19b644',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${STANDBYME_HOST_CDN}img-db-modal.png`,
      alt: '오늘 한정 수량이 소진되었어요. 추가 수량 확보 시 실시간으로 알려드릴게요!'
    },
    modalButtonText: '스탠바이미Go 무료 신청하기',
    timer: false
  },
  tabPackageAir: {
    category: 'tabPackageAir5',
    buttonBackground: 'linear-gradient(90deg, #6555ff, #8543ff)',
    buttonColor: '#fff',
    modalTitle: (
      <>
        가장 합리적으로 공부하는 방법
        <br />
        지금 상당 받으세요
      </>
    ),
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_db-modal-tabPackageAir.png`,
      alt: '오늘 한정 수량이 소진되었어요. 추가 수량 확보 시 실시간으로 알려드릴게요!'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg />
      </>
    ),
    timer: false
  },
  tabPackage10th: {
    category: 'tabPackage10th',
    buttonBackground: '#ff0cc3',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_db-modal-tabPackage10th.png`,
      alt: '오늘 한정 수량이 소진되었어요. 추가 수량 확보 시 실시간으로 알려드릴게요!'
    },

    modalButtonText: (
      <>
        iPad 10세대 신청하기
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 31" fill="none">
          <path
            d="M22.033 17.113H0V12.9564H22.033L12.0336 2.96515L15 0.046875L30 15.0347L15 30.0225L12.0336 27.1043L22.033 17.113Z"
            fill="white"
          />
        </svg>
      </>
    ),
    timer: true
  },
  laptopPackage: {
    category: 'laptopPackage',
    buttonBackground: '#fff005',
    buttonColor: '#000',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_db-modal-laptopPackage.png`,
      alt: 'LG 그램 패키지 선착순 한정 추가 증정 혜택'
    },
    modalButtonText: (
      <>
        노트북 무료 신청하기
        <ArrowSvg isBlack />
      </>
    ),
    timer: true,
    timerTitle: '설 한정 혜택 증정 🎁',
    targetDate: '20250207'
  },
  DB_Performance: {
    category: 'DB_Performance',
    hasDeviceOption: true,
    buttonBackground: '#ff36e2',
    buttonColor: '#fff',
    modalBanner: {
      pc: `https://english.yanadoocdn.com/upload/yanadoo/new/promotion/db_performance/img_popup_banner.png`,
      alt: '영어 공부 필수템 증정 사라지기 전에 신청하세요'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg />
      </>
    )
  },
  DB_Motivation: {
    category: 'DB_Motivation',
    hasDeviceOption: true,
    buttonBackground: '#ff4912',
    buttonColor: '#fff',
    modalBanner: {
      pc: `https://english.yanadoocdn.com/upload/yanadoo/new/promotion/DBmotivation/2023/nov/img_pc_motivation_popup_banner.png`,
      alt: '영어 공부 필수템 증정 사라지기 전에 신청하세요'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg />
      </>
    )
  },
  n_nipowoopo2023: {
    category: 'n_nipowoopo2023',
    buttonBackground: 'linear-gradient(90deg, #ff4800 0%, #ff004e 100%)',
    buttonColor: '#fff',
    modalTitle: (
      <>
        <div style={{ marginBottom: '.5em' }}>
          <img
            src="https://english.yanadoocdn.com/upload/yanadoo/new/promotion/n_nipowoopo2023/2022/dec/ico_pc_nipowoopo_check_58x58.png"
            alt="체크이미지"
          />
        </div>
        선착순 영어회화 성공 공식
        <br />
        지금 신청하세요!
        <span style={{ fontSize: '0.6em', fontWeight: 400, display: 'block', marginTop: '0.5em' }}>
          받으실 연락처와 성함을 알려주세요
        </span>
      </>
    ),
    modalButtonText: '왕초보 탈출 노하우 신청하기'
  },
  bestAwardsDBonly: {
    category: 'bestAwardsDBonly',
    buttonBackground: '#762bff'
  },
  aiSrr: {
    category: 'aiSrr',
    bottomBannerExpandType: 'C1',
    buttonBackground: '#117DFC',
    bottomBannerSubTitle: (
      <>
        론칭 혜택 받고 <b>AI 스르르 시작하기</b>
      </>
    ),
    bottomLeftText: (
      <>
        전문가 추천받고 <br /> <b>맞춤 패키지 알아보기</b>
      </>
    ),
    buttonColor: '#fff',
    bottomBannerBackground: '#0A1521',
    bottomBannerButtonText: '상담 신청하기',
    bottomButtonText: '상담 신청하기',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/aiSrr/image-modal.png`,
      alt: '오픈 기념 선착순 혜택'
    },
    modalButtonText: <>AI 스르르 학습지 신청하기</>,
    noCounselTime: true
  },
  blackfriday: {
    category: 'black_event',
    buttonBackground: '#D644E3',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/banner/img_black-friday-241105.png`,
      alt: '이벤트 한정 혜택 블프핫딜'
    },
    modalButtonText: (
      <>
        상담 받고 블프핫딜 혜택 받기
        <ArrowSvg />
      </>
    )
  },
  Aptner: {
    timer: true,
    category: 'Aptner',
    buttonBackground: `url(${IPAD_DOUBLE_HOST_CDN}bg-modal-button.png)`,
    buttonColor: '#000',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_db-modal-ipadDouble.png`,
      alt: '아이패드10 더블득템 평생 수강 시작만 하면 조건없이 증정 재고 소진 시 종료'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg isBlack />
      </>
    ),
    noCounselTime: true,
    hasGoalOption: true,
    hasAgeOption: true
  },
  ipadDoubleNaverCpt: {
    timer: true,
    category: 'ipadDouble_naverCPT',
    buttonBackground: `url(${IPAD_DOUBLE_HOST_CDN}bg-modal-button.png)`,
    buttonColor: '#000',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_db-modal-ipadDouble.png`,
      alt: '아이패드10 더블득템 평생 수강 시작만 하면 조건없이 증정 재고 소진 시 종료'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg isBlack />
      </>
    ),
    noCounselTime: true,
    hasGoalOption: true,
    hasAgeOption: true
  },
  macbookPackageNaverCpt: {
    category: 'macbook_naverCPT',
    buttonBackground: 'linear-gradient(to right, #4200ff, #7200ff)',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_db-modal-macbookPackage.png`,
      alt: '애플 풀 패키지 선착순 한정 추가 증정 혜택'
    },
    modalButtonText: 'MacBook 신청하기',
    timer: true,
    noCounselTime: true,
    hasGoalOption: true,
    hasAgeOption: true
  },
  macbookPackageNaverCpt2: {
    category: 'macbook_naverCPT2',
    buttonBackground: 'linear-gradient(to right, #4200ff, #7200ff)',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_db-modal-macbookPackage.png`,
      alt: '애플 풀 패키지 선착순 한정 추가 증정 혜택'
    },
    modalButtonText: 'MacBook 신청하기',
    timer: true,
    noCounselTime: true,
    hasGoalOption: true,
    hasAgeOption: true
  },
  macbookPackageFreepass: {
    category: 'macbook_freepass',
    buttonBackground: '#f17bd8',
    buttonColor: '#fff',
    hasAgeOption: true,
    hasGoalOption: true,
    noCounselTime: true,
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/macbookPackage/freePass/img_modal_banner.png`,
      alt: '맥북 패키지 선착순 한정 추가 할인 혜택'
    },
    modalButtonText: 'MacBook 신청하기'
  },
  macbookPackageTogether: {
    category: 'macbook_together',
    buttonBackground: '#5246FF',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_db-modal-macbookPackage.png`,
      alt: '애플 풀 패키지 선착순 한정 추가 증정 혜택'
    },
    modalButtonText: 'MacBook 신청하기',
    timer: true,
    noCounselTime: true,
    hasGoalOption: true,
    hasAgeOption: true
  }
}
