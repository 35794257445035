import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'

import useGetClass from 'hooks/useGetClass'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import useHeaderStore from 'store/useHeaderStore'

export default function useStoreDetail() {
  const { packageSeq } = useParams()
  const [isSticky, setIsSticky] = useState(false)
  const [isEntrancedUser, setIsEntrancedUser] = useState(false) // 입학 여부
  const [isEntrancedCheck, setIsEntrancedCheck] = useState(false) // 입학 체크여부
  const onChangeGnbActiveDepthIndex = useHeaderStore((state) => state.onChangeGnbActiveDepthIndex) // 클래스 종류에 따라 active할 메뉴 index 변경
  const gnbActiveDepthIndex = useHeaderStore((state) => state.gnbActiveDepthIndex) // 클래스 종류에 따라 active된 메뉴 depth index
  const gnbItems = useHeaderStore((state) => state.gnbItems) // GNB 메뉴

  const detailWrapRef = useRef(null)
  const userInfo = AuthService.getUserInfo()

  const { isLoading, classData, getClass } = useGetClass()

  const getUserInfo = () => {
    if (AuthService.getUserInfo()) {
      api
        .get(config.AUTH_API_HOST + '/auth/v2/user')
        .then((response) => {
          setIsEntrancedUser(response.data.data?.entrance?.entranceStatus === 'ENTRANCE')
          setIsEntrancedCheck(true)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const onScrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
  }

  const onChangeIsSticky = (is) => {
    setIsSticky(is)
  }

  useEffect(() => {
    if (detailWrapRef.current !== null && isLoading && classData) {
      let pArr = detailWrapRef.current.children
      Object.entries(pArr).forEach(([key, value]) => {
        if (value && value.innerHTML === '&nbsp;') {
          value.classList.add('empty_block')
        }
      })
    }
  }, [isLoading, detailWrapRef, classData])

  useEffect(() => {
    // GNB에 속해있지 않은 클래스 상세일 경우 클래스 카테고리네임에 따라서 active할 메뉴 index 변경
    if (
      classData &&
      (gnbActiveDepthIndex?.depth1 === 0 ||
        (gnbActiveDepthIndex?.depth1 === 1 &&
          !gnbActiveDepthIndex?.depth2 &&
          gnbActiveDepthIndex?.depth3 === 0))
    ) {
      // GNB 메뉴 중 일치하는 메뉴의 인덱스를 depth2로 설정
      let selected = 0
      gnbItems[1]?.childGnbMenus?.forEach(({ menuName }, index) => {
        if (menuName === classData?.packageCategoryGroupName) {
          selected = index
        }
      })
      onChangeGnbActiveDepthIndex({
        depth1: 1,
        depth2: selected,
        depth3: 0
      })
    }
  }, [
    classData,
    gnbActiveDepthIndex?.depth1,
    gnbActiveDepthIndex?.depth2,
    gnbActiveDepthIndex?.depth3,
    gnbItems,
    onChangeGnbActiveDepthIndex
  ])

  useEffect(() => {
    if (packageSeq) {
      getClass(packageSeq)
    }
  }, [packageSeq])

  useEffect(() => {
    getUserInfo()
  }, [])

  return {
    isEntrancedUser,
    isEntrancedCheck,
    userInfo,
    classData,
    isLoading,
    isSticky,
    onScrollToTop,
    onChangeIsSticky
  }
}
