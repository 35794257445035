import { useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

import { levelTestAiValues } from '../config'
import styles from '../styles/StepSurvey.module.scss'

export default function StepSurvey({ onCompleteSurvey }) {
  const examType = useLevelTestAiStore((state) => state.examType)
  const [questionId, setQuestionId] = useState(1)
  const [answers, setAnswers] = useState([])
  const [selected, setSelected] = useState(null)
  const [currentQuestion, setCurrentQuestion] = useState(0)

  // 질문 가져오기
  const getQuestion = useCallback((questionId) => {
    if (questionId <= 2) return levelTestAiValues.survey.commons.find(({ id }) => id === questionId)
    if (questionId === 3) return levelTestAiValues.survey.method
    return levelTestAiValues.survey.additionals.find(({ id }) => id === questionId)
  }, [])

  // 선택한 답변을 answers에 저장 & 선택한 답변 표시
  const onSelectItem = (item, questions) => {
    const { index, id } = questions
    const newAnswer = [...answers]
    newAnswer[index - 1] = { ...item, id }
    setAnswers(newAnswer)
  }

  // 이전 버튼 클릭시 이전 질문으로 이동
  const onClickPrev = () => {
    setQuestionId(answers[currentQuestion - 1]?.id)
    setCurrentQuestion(currentQuestion - 1)
  }

  // 다음 버튼 클릭시 다음 질문으로 이동
  const onClickNext = () => {
    setCurrentQuestion(currentQuestion + 1)
    if (selected?.nextQuestionId) {
      setQuestionId(selected.nextQuestionId)
    }
    if (selected?.recommended) {
      onCompleteSurvey({ recommended: selected.recommended, answers })
    }
  }

  useEffect(() => {
    setSelected(answers[currentQuestion])
  }, [answers, currentQuestion])

  const questions = useMemo(() => getQuestion(questionId), [getQuestion, questionId])

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.index}>Q.{questions?.index}</span>
        <p className={styles.question}>{questions?.question}</p>
        <div className={classNames(styles.buttons, styles[questions?.textType])}>
          {questions?.options.map((item, index) => (
            <button
              key={index}
              onClick={() => onSelectItem(item, questions)}
              className={classNames(
                styles.button,
                selected?.value === item.value && styles.selected,
                styles[examType]
              )}
            >
              {item.image && <img src={item.image} alt={item.title} />}
              <p className={styles.buttonText}>{item.text}</p>
            </button>
          ))}
        </div>
      </div>
      <div className={styles.bottomButtons}>
        <button disabled={questionId === 1} onClick={onClickPrev}>
          이전
        </button>
        <button disabled={!selected} onClick={onClickNext}>
          다음
        </button>
      </div>
    </div>
  )
}
