import React from 'react'

import useGetKeywords from 'pages/service/hooks/useGetKeywords'

import InnerCol from 'components/service/InnerCol'
import SearchForm from 'components/service/search/SearchForm'
import SearchPopular from 'components/service/search/SearchPopular'

import s from './styles/CustomerServiceSearch.module.scss'

export default function CustomerServiceSearch() {
  const { keywords } = useGetKeywords()

  return (
    <section className={s.csSearch}>
      <InnerCol innerClass={s.searchInner}>
        <h3>무엇을 도와드릴까요?</h3>
        <SearchForm classes={s.csSearchForm} />
        <SearchPopular keys={keywords} />
      </InnerCol>
    </section>
  )
}
