import React from 'react'

import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'

import LineChart from './charts/LineChart'
import styles from '../LevelTestAdvancedResult.module.scss'

export default function LevelChart() {
  const { name, location } = useLevelTestAdvancedResultStore()

  return (
    <section className={styles.lineChart}>
      <hgroup className={styles.hgroup}>
        <h6>{name}님의 영어 실력은</h6>
        <h3>상위 {location}%</h3>
      </hgroup>
      <LineChart />
    </section>
  )
}
