import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import usePostImagesUpload from 'hooks/usePostImagesUpload'
import usePostLearningReview from 'hooks/usePostLearningReview'
import AuthService from 'services/authService'
import { decodeHTMLEntities } from 'utils/decodeHTMLEntities'

import useModalLogin from 'store/useModalLogin'

import ReviewAttachment from './components/ReviewAttachment'
import ReviewPrecaution from './components/ReviewPrecaution'
import ReviewRating from './components/ReviewRating'
import ReviewTextarea from './components/ReviewTextarea'
import ReviewTitleBar from './components/ReviewTitleBar'

import usePostProvide from './hooks/usePostProvide'
import s from './ReviewWrite.module.scss'

export default function ReviewWrite() {
  const [searchParams] = useSearchParams()
  const classSeq = parseInt(searchParams.get('classSeq'), 10) || null
  const studyGroupUserSeq = parseInt(searchParams.get('studyGroupUserSeq'), 10) || null
  const productTitle = decodeURIComponent(searchParams.get('title')) || null
  const userInfo = AuthService.getUserInfo()

  const { isSuccess, postLearningReview } = usePostLearningReview()
  const { isProvide } = usePostProvide(isSuccess)
  const { postImagesUpload } = usePostImagesUpload()
  const { isModalLogin, setIsModalLogin } = useModalLogin()

  const [contents, setContents] = useState('')
  const [satisfaction, setSatisfaction] = useState(0)
  const [fileImages, setFileImages] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  // 필수값 검증 함수
  const validateRequiredFields = () => {
    if (!classSeq || !studyGroupUserSeq) {
      alert('잘못된 접근입니다.')
      return false
    }
    if (satisfaction < 1) {
      alert('만족도를 선택해주세요.')
      return false
    }
    if (contents.length < 10) {
      alert('리뷰 내용을 최소 10자 이상 입력해주세요.')
      return false
    }
    if (!userInfo) {
      setIsModalLogin(true)
      return false
    }
    return true
  }

  // 리뷰 등록 핸들러
  const onClickSubmit = async () => {
    // 필수값 검증
    if (!validateRequiredFields()) return

    // 중복 제출 방지
    if (isSubmitting) return

    setIsSubmitting(true)

    try {
      let attachments = []

      if (fileImages.length > 0) {
        const attachedImages = await postImagesUpload(fileImages)
        attachments = attachedImages.map((list) => ({
          attachmentName: list.saveFileName,
          attachmentPath: list.filePath,
          thumbnailPath: list.thumbnailFile.filePath
        }))
      }

      const payload = {
        attachments,
        classSeq,
        contents,
        satisfaction,
        studyGroupUserSeq
      }

      await postLearningReview(payload)
    } catch (error) {
      console.error('Error during review submission:', error)
      alert('리뷰 등록 중 오류가 발생했습니다.')
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (isSuccess && isProvide) {
      alert('리뷰를 작성해 주셔서 감사합니다.\n무료 지급된 강의를 확인해 보세요!')
      window.location.href = 'https://www.yanadoo.co.kr'
    }

    if (isSuccess && !isProvide) {
      alert('리뷰를 작성해 주셔서 감사합니다.\n무료 지급된 강의를 확인해 보세요!')
      window.location.href = 'https://www.yanadoo.co.kr'
    }
  }, [isProvide, isSuccess])

  // 텍스트 입력 핸들러
  const onChangeText = (e) => {
    const text = e.target.value
    if (text.length > 1000) {
      alert('최대 1000자까지 입력이 가능합니다.')
      return
    }
    setContents(text)
  }

  useEffect(() => {
    if (!userInfo && !isModalLogin) {
      setIsModalLogin(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isModalLogin])

  useEffect(() => {
    window['wingBannerDisplay']()
  }, [])

  return (
    <article className={s.reviewWrite}>
      <div className={s.contentBox}>
        <ReviewTitleBar />
        <h3 className={s.productTitle}>{decodeHTMLEntities(productTitle)}</h3>
        <ReviewRating rate={satisfaction} handleRatePoint={setSatisfaction} />
        <ReviewTextarea content={contents} onChangeText={onChangeText} />
        <ReviewAttachment fileImages={fileImages} setFileImages={setFileImages} />
        <ReviewPrecaution />
        <section className={s.reviewButtons}>
          <button
            onClick={onClickSubmit}
            disabled={satisfaction < 1 || contents.length < 10 || !userInfo || isSubmitting}
          >
            {isSubmitting ? '리뷰 등록 중' : '확인'}
          </button>
        </section>
      </div>
    </article>
  )
}
