import { lazy } from 'react'

import Redirect from 'pages/redirect'

const AiBrand = lazy(() => import('pages/aiBrand'))
const AppDownload = lazy(() => import('pages/appDownload'))
const AppDownloadVoca = lazy(() => import('pages/appDownloadVoca'))
const Company = lazy(() => import('pages/company'))
const Custom = lazy(() => import('pages/custom'))
const CustomPreview = lazy(() => import('pages/customPreview'))
const MainPreview = lazy(() => import('pages/mainPreview'))
const YanadooInfo = lazy(() => import('pages/yanadooInfo'))

const EtcRoutes = [
  { path: 'main-preview', element: <MainPreview /> }, // 메인 미리보기
  { path: 'company', element: <Company /> }, // 회사소개
  { path: 'yanadooInfo', element: <YanadooInfo /> }, // 야나두 서비스 소개
  { path: 'common/appDownload', element: <AppDownload /> }, // 앱 다운로드
  { path: 'common/appDownloadVoca', element: <AppDownloadVoca /> }, // 앱 다운로드(틈새단어)
  { path: 'custom/:pageUrl', element: <Custom /> },
  { path: 'custom-preview/:pageUrl', element: <CustomPreview /> },
  { path: 'AIYanadoo', element: <AiBrand /> },
  { path: '*', element: <Redirect /> }
]

export default EtcRoutes
