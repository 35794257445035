import { useEffect, useState } from 'react'

import classNames from 'classnames'
import AuthService from 'services/authService'

import { addComma } from 'common/common'

import { STORE_DETAIL_CDN_HOST } from '../config'
import styles from '../StoreDetail.module.scss'

const MINIMUM_PRICE = 50_000
const MINIMUM_MONTH = 1

function Price({ salePrice, percentage, installment = true }) {
  return (
    <>
      <b>
        {installment && '월'} <em>{addComma(Number(salePrice))}원</em>으로 시작하기
      </b>
      <span className={styles.mobileN}>{percentage && `(${percentage}% 할인)`}</span>
    </>
  )
}

export default function PriceButton({
  isEntrancedUser,
  classData,
  onOpenModalLogin,
  onOpenModalCourse,
  onOrderStart
}) {
  const salePercentage = Math.floor(
    ((Number(classData.exceptOptionOriginalPrice / classData.installmentMonth) -
      Number(classData.exceptOptionSalePrice / classData.installmentMonth)) /
      Number(classData.exceptOptionOriginalPrice / classData.installmentMonth)) *
      100
  )

  const handleClickStart = () => {
    if (!AuthService.getUserInfo()) {
      onOpenModalLogin() // 로그인 모달
    } else if (classData.requireEntranceYn === 'Y' && !isEntrancedUser) {
      onOpenModalCourse() // 입학 안내 모달
    } else {
      onOrderStart() // 구매
    }
  }

  return (
    <button className={classNames(styles.priceButton, 'btn-cart')} onClick={handleClickStart}>
      {isEntrancedUser ? (
        //입학회원
        <>
          {classData.exceptOptionSalePrice < MINIMUM_PRICE ||
          classData.installmentMonth <= MINIMUM_MONTH ? (
            <Price
              salePrice={classData.exceptOptionMembershipSalePrice}
              percentage={classData.exceptOptionDiscountPercent}
              installment={false}
            />
          ) : (
            <>
              {classData.exceptOptionSalePrice !== classData.exceptOptionMembershipSalePrice ? (
                <Price
                  salePrice={classData.monthlyMembershipSalePrice}
                  percentage={classData.exceptOptionDiscountPercent}
                />
              ) : (
                <Price salePrice={classData.monthlyMembershipSalePrice} />
              )}
            </>
          )}

          <TimeSale text={`입학\n특가`} />
        </>
      ) : (
        //일반회원
        <>
          {classData.exceptOptionSalePrice < MINIMUM_PRICE ||
          classData.installmentMonth <= MINIMUM_MONTH ? (
            <Price
              salePrice={classData.exceptOptionSalePrice}
              percentage={salePercentage}
              installment={false}
            />
          ) : (
            <>
              {classData.exceptOptionSalePrice !== classData.exceptOptionOriginalPrice ? (
                <Price
                  salePrice={Math.floor(
                    Number(classData.exceptOptionSalePrice / classData.installmentMonth)
                  )}
                  percentage={salePercentage}
                />
              ) : (
                <Price salePrice={classData.monthlySalePrice} />
              )}
            </>
          )}
          <TimeSale />
        </>
      )}
    </button>
  )
}

function TimeSale({ text }) {
  const [index, setIndex] = useState(1)

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex(index === 0 ? 1 : 0)
    }, 10000)

    return () => {
      clearInterval(timer)
    }
  }, [index])

  return (
    <i className={styles.iconTimeSale}>
      {index ? (
        text ? (
          <span className={classNames(styles.contents, styles.text)}>{text}</span>
        ) : (
          <img
            className={classNames(styles.contents, styles.text)}
            src={`${STORE_DETAIL_CDN_HOST}/img-time-sale-text.png`}
            alt={text}
          />
        )
      ) : (
        <img
          className={classNames(styles.contents, styles.image)}
          src={`${STORE_DETAIL_CDN_HOST}/gif-time-sale.gif`}
          alt="clock"
        />
      )}
    </i>
  )
}
