import { YANADOO_CDN_HOST } from 'common/config'

export const keyVisualBannerValues = {
  banners: {
    1: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_kv_laptop_250115.png`,
      mobile: `${YANADOO_CDN_HOST}/promotion/etc/img_m_kv_laptop_250115.png`,
      background: `#FFD98E`
    },
    2: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_kv_macbook_250115.png`,
      mobile: `${YANADOO_CDN_HOST}/promotion/etc/img_m_kv_macbook_250115.png`,
      background: `#C1E7B5`
    },
    3: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_kv_ipad_250115.png`,
      mobile: `${YANADOO_CDN_HOST}/promotion/etc/img_m_kv_ipad_250115.png`,
      background: `#69E2E1`
    },
    4: {
      pc: `${YANADOO_CDN_HOST}/promotion/etc/img_kv_dbonly_250115.png`,
      mobile: `${YANADOO_CDN_HOST}/promotion/etc/img_m_kv_dbonly_250115.png`,
      background: `#F1C7DB`
    }
  },
  paths: {
    1: ['/promotion-landing/laptopPackage'],
    2: ['/promotion-landing/macbookPackage'],
    3: ['/promotion-landing/ipadDouble'],
    4: ['/promotion-landing/bestAwardsDBOnly']
  }
}
