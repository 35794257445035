/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { levelTestAiValues } from '../config'

// 테스트 - 퀴즈형
export default function useLevelTestAiQuiz({ onCompleteExam }) {
  const KlleonChat = useMemo(() => window.KlleonChat || {}, [])
  const chatTypeQueue = useRef([])

  const [answers, setAnswers] = useState([])
  const [descriptionFadeClass, setDescriptionFadeClass] = useState('')
  const [descriptionIndex, setDescriptionIndex] = useState(0)
  const [quizIndex, setQuizIndex] = useState(0)
  const [mode, setMode] = useState('description') // description, quiz
  const [isResponseEnd, setIsResponseEnd] = useState(false)

  // 정답 버튼 클릭시 발화 & 다음 문제로 이동
  const onClickAnswer = useCallback(
    (answer) => {
      const updatedAnswers = [...answers]
      updatedAnswers[quizIndex - 1] = answer
      setAnswers(updatedAnswers)

      switch (quizIndex) {
        case 2: // 퀴즈 타입 1 문제풀이 끝난후 > 퀴즈 타입 2 설명 발화 시작
          setTimeout(() => {
            setDescriptionIndex(2)
          }, 1400)
          break
        case 8: // 퀴즈 타입 2 문제풀이 끝난후 > 퀴즈 타입 3 설명 발화 시작
          setTimeout(() => {
            setDescriptionIndex(3)
          }, 700)
          break
        case 10: // 문제풀이 끝남
          if (onCompleteExam) {
            onCompleteExam(updatedAnswers)
          }
          if (quizIndex < levelTestAiValues.quizDescription.length) {
            setQuizIndex(quizIndex + 1)
          }
          break
        default: // 다음 문제로 이동 & 문제설명 발화
          setQuizIndex(quizIndex + 1)
          break
      }
    },
    [quizIndex, answers]
  )

  // 아바타 이벤트 구독하는 함수 : 발화 끝났을 때 다음 단계로 넘어가는 로직
  const moveToNextDescription = useCallback(() => {
    if (mode === 'quiz') return false // 퀴즈 발화 끝나고 난후에는
    if (chatTypeQueue.current[chatTypeQueue.current.length - 2] !== 'TEXT') return false // 이벤트 순서 체크 > 발화후가 아닐경우 다음 단계로 넘어가지 않음 다음 단계로 넘어가지 않음
    switch (descriptionIndex) {
      case 0:
        setDescriptionFadeClass('fadeOutFirst')
        setTimeout(() => {
          setDescriptionIndex(1) // 인트로 발화 후 > 퀴즈 타입 1 설명 발화
        }, 700)

        break
      case 1:
        setDescriptionFadeClass('fadeOutSecond')
        setTimeout(() => {
          setQuizIndex(1) // 퀴즈 타입 1 설명 발화 끝난후 > 퀴즈 타입 1 시작 (O,X 문제)
        }, 700)
        break
      case 2:
        setDescriptionFadeClass('fadeOut')
        setQuizIndex(3) // // 퀴즈 타입 2 설명 발화 끝난후 > 퀴즈 타입 2 시작 (빈칸 채우기)
        break
      case 3:
        setDescriptionFadeClass('fadeOut')
        setQuizIndex(9) // // 퀴즈 타입 3 설명 발화 끝난후 > 퀴즈 타입 3 시작 (번역 문제)
        break

      default:
        break
    }
  }, [mode, chatTypeQueue, descriptionIndex])

  const stopEcho = () => {
    KlleonChat.stopSpeech()
    KlleonChat.endAudioEcho()
  }

  // 퀴즈가 진행될때 퀴즈설명 발화
  const echoDescription = useCallback(
    (message) => {
      if (message?.length) {
        KlleonChat?.echo(message)
      }
    },
    [KlleonChat]
  )

  // 클레온 발화 이벤트 구독
  useEffect(() => {
    const handleChatEvent = (result) => {
      chatTypeQueue.current.push(result.chat_type)
      setIsResponseEnd(result.chat_type === 'RESPONSE_IS_ENDED')
    }

    KlleonChat?.onChatEvent(handleChatEvent)
    return () => {
      chatTypeQueue.current = []
      KlleonChat?.destroy()
    }
  }, [])

  // 설명 번호가 바뀌었을 경우 해당 설명 발화
  useEffect(() => {
    echoDescription(levelTestAiValues.quizStepDescription[descriptionIndex].eng)
    setMode('description')
    if (descriptionIndex > 1) {
      setDescriptionFadeClass('fadeIn')
    }
  }, [descriptionIndex])

  // 퀴즈 번호가 바뀌었을 경우 해당 퀴즈 설명 발화
  useEffect(() => {
    if (quizIndex) {
      echoDescription(levelTestAiValues.quizDescription[quizIndex - 1])
      setMode('quiz')
    }
  }, [quizIndex])

  // 발화가 끝났을 경우 다음 대화로 넘어감
  useEffect(() => {
    if (isResponseEnd) {
      moveToNextDescription()
    }
  }, [isResponseEnd])

  return {
    mode,
    quizIndex,
    descriptionIndex,
    descriptionFadeClass,
    stopEcho,
    onClickAnswer
  }
}
