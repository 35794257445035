import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

import AuthService from 'services/authService'

export default function LoginSuccess() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const isKeepLogin = state ? state : false
  const userInfo = AuthService.getUserInfo()
  const accessToken = AuthService.getLocalAccessToken()
  const refreshToken = AuthService.getLocalRefreshToken()

  const sendMessageToReactNativeWebView = (data) => {
    const message = JSON.stringify({
      type: 'LoginSuccess',
      userInfo: data,
      isKeepLogin,
      accessToken,
      refreshToken
    })

    try {
      window.ReactNativeWebView.postMessage(message)
    } catch (e) {
      console.error('Error sending message to React Native WebView:', e)
    }
  }

  useEffect(() => {
    if (!userInfo) {
      navigate('/webview/login')
    }
    sendMessageToReactNativeWebView(userInfo)
  }, [userInfo])

  return <div style={{ display: 'none' }}>Login Success</div>
}
