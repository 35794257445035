import React from 'react'

import BtnMore from 'components/service/BtnMore'
import InnerCol from 'components/service/InnerCol'
import NoticeList from 'components/service/notice/NoticeList'

import SectionTitle from './common/SectionTitle'
import s from './styles/CustomerServiceNotice.module.scss'

export default function CustomerServiceNotice() {
  return (
    <section className={s.csNotice}>
      <InnerCol>
        <SectionTitle>
          <h3>공지사항</h3>
        </SectionTitle>
        <NoticeList />
        <BtnMore title="전체보기" link="/service/notice/1" btnClass={s.btnMore} />
      </InnerCol>
    </section>
  )
}
