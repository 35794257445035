import Lottie from 'react-lottie-player'

import classNames from 'classnames'
import useGetUser from 'hooks/useGetUser'

import LottieLoadingCircle from '../assets/lottie-loading-circle.json'
import LottieLoadingSpinner from '../assets/lottie-loading-spinner.json'
import { levelTestAiValues } from '../config'
import styles from '../styles/ExamLoading.module.scss'

export default function ExamLoading({ examType, onClickCancel }) {
  const { user } = useGetUser() || { userName: '' }

  return (
    <div className={classNames(styles.container, styles[examType])}>
      <div className={styles.inner}>
        <p className={styles.title}>
          오드리쌤이&nbsp;
          <b>
            {user?.userName?.slice(0, 4)}
            {user?.userName?.length > 4 && '...'}
          </b>
          님을 위한 <br />
          레벨 테스트를 준비중입니다.
        </p>
        <p className={styles.description}>답변 시간 초과 시, 다음 문제로 넘어가요!</p>
        <div className={styles.loading}>
          <Lottie
            className={styles.loadingBg}
            {...levelTestAiValues.lottieDefaultOptions}
            animationData={LottieLoadingCircle}
          />

          <img
            src={`${levelTestAiValues.HOST_CDN}image-exam-loading-${examType}.png`}
            alt=""
            className={styles.loadingAudrey}
          />
          <div className={styles.loadingSpinner}>
            <Lottie
              {...levelTestAiValues.lottieDefaultOptions}
              animationData={LottieLoadingSpinner}
            />
            <span className={styles.loadingText}>Loading...</span>
          </div>
        </div>
      </div>
      <button className={styles.cancelButton} onClick={onClickCancel}>
        <CloseIcon />
        취소
      </button>
    </div>
  )
}

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M4 4L28 28M28 4L4 28"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
