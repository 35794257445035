import React, { useState, useEffect } from 'react'

import api from 'common/api'
import * as config from 'common/config'
import 'assets/styles/modal.scss'
import 'assets/styles/coupon.scss'
import 'assets/styles/_modalReviewWrite.scss'

export default function ModalReviewWrite({
  title,
  btnClose,
  handleCloseModalReviewWrite,
  studySeq,
  getMyreview, // 내가 작성한 수강 후기가 있다면 데이터 업데이트
  postMyreview, // 수강 후기 등록
  editMyReview, // 수강 후기 수정
  postProductReview, // 상품 후기 등록
  inputValue, // 상품 후기 데이터
  getProductReview, // 내가 작성한 상품 후기가 있다면 데이터 업데이트
  editProductReview, // 상품 후기 수정
  myClassLearningReviewSeq
}) {
  let filesLimit = 5

  const [btn, setBtn] = useState(false)
  const [classData, setClassData] = useState(null)
  const [textareaLength, setTextareaLength] = useState(0)
  const [learningReviewSeq, setLearningReviewSeq] = useState(null)
  const [textareaValue, setTextareaValue] = useState('')
  const [satisfaction, setSatisfaction] = useState(null)
  // 상품 후기
  const [productLearningReviewSeq, setProductLearningReviewSeq] = useState(null)

  const [selectImgUrl, setSelectImgUrl] = useState()
  const [getImgData, setGetImgData] = useState()
  const [registerImage, setRegisterImage] = useState([])

  const handleGetPoint = (point) => {
    setSatisfaction(point)
  }

  const handleTextLengthCheck = (e) => {
    let textValue = e.target.value
    let textLength = e.target.value.length

    setTextareaLength(textLength)
    setTextareaValue(textValue)
  }

  // 수강후기 작성할 클래스 데이터
  const getClassData = () => {
    api
      .get('/v2/review/class/' + studySeq)
      .then((response) => {
        setClassData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 마이클래스에서 상품후기 데이터 조회
  const getClassProductData = () => {
    api
      .get('/v2/review/' + myClassLearningReviewSeq)
      .then((response) => {
        setClassData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleRegistMyReview = () => {
    if (!satisfaction) {
      alert('만족도를 선택해주세요.')
      return false
    }

    if (textareaLength < 10) {
      alert('최소 10자 이상 입력해주세요.')
      return false
    }

    if (textareaLength >= 1000) {
      alert('최대 1000자까지 입력이 가능합니다.')
      return false
    }

    // modalProductSelect에서 가져온 inputValue가 있을 때 (상품 후기)
    if (inputValue) {
      if (productLearningReviewSeq) {
        editProductReview(productLearningReviewSeq, textareaValue, satisfaction)
      } else {
        postProductReview(inputValue.packageSeq, inputValue.productSeq, textareaValue, satisfaction)
      }
    }

    // 나의 문의 후기에서 상품 후기 수정
    if (myClassLearningReviewSeq && classData.reviewType === 'PRODUCT') {
      editProductReview(myClassLearningReviewSeq, textareaValue, satisfaction)
    }

    // 수강 후기 작성한 적이 있다면 수정 API, 없다면 등록 API
    if (learningReviewSeq) {
      editMyReview(learningReviewSeq, textareaValue, satisfaction, registerImage)
    } else if (classData) {
      postMyreview(classData, textareaValue, satisfaction, registerImage)
    }
  }

  const handleImageUpload = (e) => {
    let file
    const fileArr = e.target.files // 첨부한 여러개의 이미지 파일을 불러옴.

    if (fileArr.length > filesLimit || fileArr.length + registerImage.length > 5) {
      alert('이미지는 5장까지만 등록 가능합니다.')
    } else {
      for (let i = 0; i < fileArr.length; i++) {
        file = fileArr[i]

        let reader = new FileReader() // FileReader API를 사용하기 위해 선언.
        reader.readAsDataURL(file) // 파일을 Base64 Encode 문자열로 반환
      }

      setSelectImgUrl([...fileArr]) // aws에 등록할 이미지 리스트
    }
  }

  // aws에 이미지 등록
  const postImage = () => {
    const formData = new FormData()

    selectImgUrl &&
      selectImgUrl.forEach((file) => {
        if (file.type.startsWith('image/')) {
          formData.append('files', file)
        }
      })
    formData.append('resizeType', 'REVIEW')

    api
      .post('/v2/file/image-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response.data.meta.code !== 200) {
          if (response.data.meta.message) {
            alert(response.data.meta.message)
          } else {
            alert(config.MESSAGE['common-error'])
          }
          return
        }

        setGetImgData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleDeleteImgae = (url) => {
    let deleteRegisterItem = registerImage.filter((el) => el.thumbnailPath !== url)

    setRegisterImage([...deleteRegisterItem])
  }

  const handleUpdateImage = (imageList) => {
    if (imageList) {
      setRegisterImage([...registerImage, ...imageList])
    }
  }

  useEffect(() => {
    if (selectImgUrl) {
      postImage()
    }
  }, [selectImgUrl])

  useEffect(() => {
    textareaLength <= 0 ? setBtn(false) : setBtn(true)
  }, [textareaValue, textareaLength])

  useEffect(() => {
    if (studySeq) {
      getClassData()
    }
    if (myClassLearningReviewSeq) {
      getClassProductData()
    }
  }, [])

  useEffect(() => {
    if (getMyreview && title === '수강 후기 작성') {
      getMyreview(
        setTextareaValue,
        setTextareaLength,
        setLearningReviewSeq,
        handleGetPoint,
        handleUpdateImage
      )
    }
  }, [getMyreview])

  useEffect(() => {
    if (getProductReview) {
      getProductReview(
        setTextareaValue,
        setTextareaLength,
        setProductLearningReviewSeq,
        handleGetPoint
      )
    }
  }, [getProductReview])

  useEffect(() => {
    if (getImgData) {
      let uploadImgData = getImgData?.map((list) => {
        return {
          attachmentName: list.saveFileName,
          attachmentPath: list.filePath,
          thumbnailPath: list.thumbnailFile.filePath
        }
      })

      setRegisterImage((prevImages) => [...prevImages, ...uploadImgData])
    }
  }, [getImgData])

  return (
    <>
      <div className="modal-wrap class-review">
        <div className="dimmed" onClick={handleCloseModalReviewWrite}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModalReviewWrite}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>{title}</strong>
          </div>
          <div className="modal-content">
            {classData && (
              <div className="class-info-box">
                <span
                  className="class-img"
                  style={{
                    backgroundImage: 'url(' + classData.thumbnailPath + ')'
                  }}
                ></span>
                <p>
                  <span className="class-name">{classData.className || classData.title}</span>
                </p>
              </div>
            )}
            {inputValue ? (
              <div className="class-info-box">
                <span
                  className="class-img"
                  style={{
                    backgroundImage: 'url(' + inputValue.thumbnailPath + ')'
                  }}
                ></span>
                <p>
                  <span className="class-name">{inputValue.productName}</span>
                </p>
              </div>
            ) : null}
            <div className="review-box">
              <form name="reviewWriteFrm" action="#">
                <dl>
                  <dt>만족도는 어떠셨나요?</dt>
                  <dd>
                    <div className="point-star-box">
                      <span
                        id="starPoint"
                        style={{
                          width: `${satisfaction * 20 + '%'}`
                        }}
                      ></span>
                      <div className="btn-group">
                        <button
                          type="button"
                          onClick={() => {
                            handleGetPoint(1)
                          }}
                        >
                          1
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            handleGetPoint(2)
                          }}
                        >
                          2
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            handleGetPoint(3)
                          }}
                        >
                          3
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            handleGetPoint(4)
                          }}
                        >
                          4
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            handleGetPoint(5)
                          }}
                        >
                          5
                        </button>
                      </div>
                    </div>
                    별점을 선택해주세요.
                  </dd>
                </dl>
                <textarea
                  placeholder="어떤 점이 만족스러웠나요?&#13;&#10;나만의 공부 노하우는 무엇인가요?&#13;&#10;최소 10자 이상 입력해주세요!"
                  id="reviewText"
                  maxLength="1000"
                  value={textareaValue}
                  onChange={handleTextLengthCheck}
                />
                <strong>
                  <span id="textLength">{textareaLength}</span>
                  /1000
                </strong>

                <div className="img-thumb-area">
                  <ul className={registerImage.length > 0 ? ' active' : null}>
                    {registerImage.length > 0 &&
                      registerImage.map((item, idx) => (
                        <li
                          key={idx}
                          style={{
                            backgroundImage: `url(${item.thumbnailPath})`
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => handleDeleteImgae(item.thumbnailPath)}
                          ></button>
                        </li>
                      ))}
                  </ul>
                  <label className="select-btn" htmlFor="select-image-file">
                    <em>사진은 최대 5장 까지 첨부됩니다.</em>
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    name="select-image-file"
                    id="select-image-file"
                    multiple
                    onChange={handleImageUpload}
                  />
                </div>
              </form>
            </div>
            <div className="notice-box">
              <strong>수강 후기 작성 안내</strong>
              <ul>
                <li>
                  약관 및 법률에 위배(비속어, 허위사실 등), 해석이 불가능하거나 상품 후기 외 다른
                  목적의 글을 작성한 경우 관리자에 의해 사전 동의 없이 미게시 될 수 있습니다.
                </li>
                <li>작성하신 후기는 홍보, 이벤트 등 다양한 방법으로 활용될 수 있습니다.</li>
              </ul>
              <div className="btn-flex-form">
                <button className="button" onClick={handleCloseModalReviewWrite}>
                  취소
                </button>
                <button
                  className={'button disabled' + (btn ? ' active' : '')}
                  disabled={btn ? false : true}
                  onClick={handleRegistMyReview}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
