import cx from 'classnames'

import FaqAnswer from './FaqAnswer'
import s from './FaqItem.module.scss'

export default function FaqItem({ faq, handleFaqSeq, open, answer }) {
  return (
    <dl className={s.csFaqItem}>
      <dt
        className={cx(s.faqTitle, { [s.faqAnswerOpen]: open })}
        onClick={() => {
          handleFaqSeq(faq.faqSeq)
        }}
        dangerouslySetInnerHTML={{ __html: faq.title }}
      />
      <dd className={cx(s.faqAnswer, { [s.faqAnswerOpen]: open })}>
        {open && <FaqAnswer answer={answer} />}
      </dd>
    </dl>
  )
}
