import { useState, useEffect } from 'react'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

import { levelTestAiAnswerValues } from '../../answer/config'

//점수 계산
export default function useSetState() {
  const answers = useLevelTestAiStore((state) => state.answers)
  const [score, setScore] = useState(0)

  useEffect(() => {
    if (!answers.length) return

    const totalScore = answers.reduce((acc, answer, index) => {
      const questionData = levelTestAiAnswerValues[index + 1]
      const correctAnswer = questionData.buttonGroup.answer
      return answer === correctAnswer ? acc + questionData.buttonGroup.score : acc
    }, 0)

    setScore(totalScore)
  }, [answers])

  return { score }
}
