import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useGetUser from 'hooks/useGetUser'

import { YANADOO_CDN_HOST } from 'common/config'
import useLevelTestAiStore from 'store/useLevelTestAiStore'

import { levelTestAiResultValues } from '../config'
import scoreImg from '../images/ico_score.png'
import timerImg from '../images/ico_timer.png'
import styles from '../styles/MyLevel.module.scss'

export default function MyLevel({ score, level, examType }) {
  const navigate = useNavigate()
  const totalTime = useLevelTestAiStore((state) => state.totalTime)

  const { user } = useGetUser()

  const [levelContent, setLevelContent] = useState(null)
  const formattedLevel = level ? level.match(/\d+/)?.[0] : 'Unknown Level'

  useEffect(() => {
    if (level) {
      setLevelContent(levelTestAiResultValues.levelTestAiValue[level])
    }
  }, [level])

  const handleExit = () => {
    navigate('/leveltestAi/start')
  }

  return (
    <section className={styles.myLevel}>
      <div className={styles.levelContent}>
        <div className={styles.imageWrapper}>
          <button className={styles.exitButton} onClick={handleExit}>
            <img src={`${YANADOO_CDN_HOST}/levelTestAi/icon-exam-exit.png`} alt="exit" />
          </button>
          {levelContent && (
            <>
              <img
                src={levelContent.mainImg}
                alt={`${levelContent.rate} 이미지`}
                className={styles.levelImage}
              />
              <div className={styles.overlay}>
                <p className={`${styles.badege} ${styles[`badege--${level}`]}`}>
                  Lv.{formattedLevel}
                </p>
                <h2 className={styles.rate}>{levelContent.rate}</h2>
                {user && (
                  <p className={styles.mainText}>
                    {user.userName.length > 3 ? `${user.userName.slice(0, 3)}..` : user.userName}
                    님은 {levelContent.mainText}
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {examType !== 'conversation' && (
        <div className={styles.scoreBox}>
          <div className={styles.score}>
            <p className={styles.scoretitle}>
              <img src={scoreImg} alt="점수 이미지" />
              점수 (100점 만점)
            </p>
            <p className={styles.scoreResult}>{score}점</p>
          </div>
          <div className={styles.verticalLine}></div>
          <div className={styles.time}>
            <p className={styles.scoretitle}>
              <img src={timerImg} alt="시계 이미지" />총 소요시간
            </p>
            <p className={styles.scoreResult}>{totalTime}</p>
          </div>
        </div>
      )}
    </section>
  )
}
