import AiFaq from 'pages/aiFaq'
import CustomerServiceCenter from 'pages/service/center/index'
import DetailNotice from 'pages/service/DetailNotice'
import DetailOfficial from 'pages/service/DetailOfficial'
import DetailPolicy from 'pages/service/DetailPolicy'
import DetailPolicyFacebook from 'pages/service/DetailPolicyFacebook'
import DetailPolicyJoin from 'pages/service/DetailPolicyJoin'
import DetailPolicyVoice from 'pages/service/DetailPolicyVoice'
import DetailRefund from 'pages/service/DetailRefund'
import DetailTerms from 'pages/service/DetailTerms'
import Device from 'pages/service/Device'
import FaqSearchResult from 'pages/service/faq/components/FaqSearchResult'
import FaqMain from 'pages/service/faq/index'
import Notice from 'pages/service/Notice'
import Official from 'pages/service/Official'
import TempTerms from 'pages/service/TempTerms'
import { Service } from 'router/OutletStore'

import PrivateRoute from 'common/PrivateRoute'

const ServiceRoutes = [
  {
    path: 'service/*',
    element: <Service />,
    children: [
      {
        path: 'device',
        element: (
          <PrivateRoute>
            <Device />
          </PrivateRoute>
        )
      },
      { path: 'center', element: <CustomerServiceCenter /> },
      { path: 'notice/:currentSeq', element: <Notice /> },
      { path: 'notice/detail/:currentSeq', element: <DetailNotice /> },
      { path: 'official/:currentSeq', element: <Official /> },
      { path: 'official/detail/:currentSeq', element: <DetailOfficial /> },
      { path: 'faq/search/:currentSeq', element: <FaqSearchResult /> },
      { path: 'faq', element: <FaqMain /> },
      { path: 'faq/ai', element: <AiFaq /> },
      { path: 'refunddetail', element: <DetailRefund /> },
      { path: 'termsdetail', element: <DetailTerms /> },
      { path: ':brandSeq/:itemSeq', element: <TempTerms /> },
      { path: 'tempTerms', element: <TempTerms /> },
      { path: 'policydetail', element: <DetailPolicy /> },
      { path: 'policydetailfacebook', element: <DetailPolicyFacebook /> },
      { path: 'policydetailjoin', element: <DetailPolicyJoin /> },
      { path: 'policyVoice', element: <DetailPolicyVoice /> }
    ]
  }
]

export default ServiceRoutes
