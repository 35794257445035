import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useAlertBeforeUnload from 'hooks/useAlertBeforeUnload'
import { v4 as uuid } from 'uuid'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

import Answer from './components/Answer'
import Top from './components/Top'

import { levelTestAiAnswerValues } from './config'
import styles from './styles/LevelTestAiAnswer.module.scss'

export default function LevelTestAiAnswer() {
  const navigate = useNavigate()
  useAlertBeforeUnload('페이지를 떠나시면 결과를 확인할 수 없습니다.\n 페이지를 떠나시겠습니까?')
  const answers = useLevelTestAiStore((state) => state.answers)
  useEffect(() => {
    if (!answers || answers.length === 0) {
      alert('잘못된 접근입니다.')
      navigate('/leveltestAi/start') // 이동할 경로
    }
  }, [answers, navigate])

  useEffect(() => {
    window['wingBannerDisplay']()
  }, [])

  return (
    <main className={styles.main}>
      <div className={styles.inner}>
        <Top />
        <section className={styles.section}>
          <h3>정답 확인</h3>

          {Object.values(levelTestAiAnswerValues).map((questionValue, idx) => (
            <Answer key={uuid()} idx={idx} questionValue={questionValue} userResult={answers} />
          ))}
        </section>
      </div>
    </main>
  )
}
