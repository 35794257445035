import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate, useParams } from 'react-router'

import cx from 'classnames'

import s from './SearchForm.module.scss'

export default function SearchForm({ classes, isWebview }) {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const key = queryParams.get('key')
  const [keyword, setKeyword] = useState(key || '')
  const { currentSeq } = useParams()
  const setCurrentSeq = currentSeq
    ? currentSeq
    : location.pathname === '/service/center' || location.pathname === '/webview/service/center'
    ? 1
    : 2

  const onSubmitSearch = (e) => {
    e.preventDefault()
    handleSearchKeyword()
  }

  const handleSearchKeyword = () => {
    if (keyword.length < 1) return
    if (isWebview) {
      navigate(`/webview/service/faq/search/${setCurrentSeq}?key=${keyword}`)
    } else {
      navigate(`/service/faq/search/${setCurrentSeq}?key=${keyword}`)
    }
  }

  useEffect(() => {
    setKeyword(key || '')
    return () => setKeyword('')
  }, [key])

  return (
    <form
      className={cx(s.csSearchForm, { [s.reverse]: isWebview }, classes)}
      onSubmit={onSubmitSearch}
    >
      <button onClick={handleSearchKeyword}>
        <IconSearch strokeColor={isMobile || isWebview ? '#000' : '#FFB900'} />
      </button>
      <input
        type="text"
        placeholder="키워드로 검색해보세요!"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
    </form>
  )
}

const IconSearch = ({ strokeColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <circle cx="15.5" cy="15.5" r="12" stroke={strokeColor} strokeWidth="3" />
      <path d="M25 25L36 36" stroke={strokeColor} strokeWidth="3" strokeLinecap="round" />
    </svg>
  )
}
