import s from './styles/ReviewAttachment.module.scss'
const filesLimit = 5

export default function ReviewAttachment({ fileImages, setFileImages }) {
  // 이미지 업로드 핸들러
  const handleImageUpload = (e) => {
    const fileArr = Array.from(e.target.files)
    const totalImages = fileArr.length + fileImages.length

    if (totalImages > filesLimit) {
      alert(`이미지는 최대 ${filesLimit}장까지만 등록 가능합니다.`)
      return
    }

    setFileImages((prev) => [...prev, ...fileArr])
  }

  // 이미지 삭제 핸들러
  const handleDeleteImage = (fileToDelete) => {
    setFileImages((prev) => prev.filter((file) => file !== fileToDelete))
  }

  return (
    <section className={s.reviewAttachment}>
      {fileImages.length > 0 && (
        <div className={s.filePreview}>
          <ul>
            {fileImages.map((file, index) => (
              <li key={index}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Preview ${index}`}
                  // style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
                <button onClick={() => handleDeleteImage(file)}>X</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={s.fileInput}>
        <label htmlFor="fileInput">사진은 최대 {filesLimit}장 까지 첨부됩니다.</label>
        <input
          type="file"
          accept="image/*"
          name="fileInput"
          id="fileInput"
          multiple
          onChange={handleImageUpload}
        />
      </div>
    </section>
  )
}
