import { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import styles from '../styles/ExamTimer.module.scss'

export default function ConversationTimer({ disabled, timeLimit, quizIndex, onTimeOver }) {
  const timeCheck = useRef(null)
  const isMounted = useRef(true)
  const [time, setTime] = useState(null)
  const [isTimeOver, setisTimeOver] = useState(null)

  useEffect(() => {
    resetTimer()
    setTimeout(() => {
      if (isMounted.current) {
        setTime(timeLimit)
      }
    }, 1700) // 바텀시트 애니메이션시간 + 1초정도 텀을 두고 타이머 시작
  }, [quizIndex, timeLimit])

  useEffect(() => {
    if (time !== null) {
      if (time > 0) {
        // 타이머 시작
        clearInterval(timeCheck.current)
        timeCheck.current = setInterval(() => {
          if (isMounted.current) {
            setTime((prev) => prev - 1)
          }
        }, 1000)
      }
      // 타이머 종료

      if (time === 0) {
        if (disabled) return
        setisTimeOver(true)
        clearInterval(timeCheck.current)
        timeCheck.current = null
        setTimeout(() => {
          setisTimeOver(false)
          setTime(null)
        }, 1800)
        setTimeout(() => {
          onTimeOver()
        }, 2600)
      }
    }
  }, [time])

  const resetTimer = () => {
    clearInterval(timeCheck.current)
    timeCheck.current = null
    setTime(null)
    setisTimeOver(null)
  }

  useEffect(() => {
    if (disabled) {
      resetTimer()
    }
  }, [disabled])

  useEffect(() => {
    return () => {
      isMounted.current = false
      clearInterval(timeCheck.current)
      timeCheck.current = null
    }
  }, [])

  return (
    <>
      {time !== null && (
        <p className={classNames(styles.timer, time < 3 && styles.timeOver)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
          >
            <path
              d="M18.3262 3.66309H25.6595M21.9928 25.6631L27.4928 20.1631M36.6595 25.6631C36.6595 33.7633 30.093 40.3298 21.9928 40.3298C13.8927 40.3298 7.32617 33.7633 7.32617 25.6631C7.32617 17.5629 13.8927 10.9964 21.9928 10.9964C30.093 10.9964 36.6595 17.5629 36.6595 25.6631Z"
              stroke="#5199FF"
              strokeWidth="3.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>{time}</span>
        </p>
      )}
      {isTimeOver !== null && (
        <p className={classNames(styles.toast, isTimeOver ? styles.slideDown : styles.slideUp)}>
          답변이 없어 다음 문제로 넘어갑니다. 😢
        </p>
      )}
    </>
  )
}
