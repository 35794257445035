import React, { useCallback, useEffect, useState } from 'react'

import CategoryNav from './components/CategoryNav'
import ProductList from './components/ProductList'

import s from './CategoryList.module.scss'
import { categoryListValues } from './config'
import { filterByLevel, filterByPeriod, filterByClassType, filterByPrice } from './utils'

export default function CategoryList() {
  const categoryData = categoryListValues.data

  const [selected, setSelected] = useState('level')
  const [listData, setListData] = useState([])

  const handleDataFilter = useCallback(
    (type) => {
      switch (type) {
        case 'level':
          setListData(filterByLevel(categoryData))
          break
        case 'period':
          setListData(filterByPeriod(categoryData))
          break
        case 'classType':
          setListData(filterByClassType(categoryData))
          break
        default:
          setListData(filterByPrice(categoryData))
          break
      }
      setSelected(type)
      window.scrollTo({
        left: 0,
        top: 0
      })
    },
    [categoryData]
  )

  useEffect(() => {
    if (categoryData) {
      setListData(filterByLevel(categoryData))
    }
  }, [categoryData])

  return (
    <article className={s.categoryList}>
      {/* 상단 버튼형 카테고리 메뉴 */}
      <CategoryNav type="button" selected={selected} handleDataFilter={handleDataFilter} />
      {/* 카테고리별 상품 리스트 */}
      {listData.map((group, index) => (
        <ProductList key={index} title={group.title} listData={group.items} />
      ))}
      {/* 하단 리스트형 카테고리 메뉴 */}
      <CategoryNav type="list" selected={selected} handleDataFilter={handleDataFilter} />
    </article>
  )
}
