import classNames from 'classnames'

import { levelTestAiResultValues } from '../config'
import { useSetGrade } from '../hooks/useSetGrade'
import styles from '../styles/RecommendSurvey.module.scss'

export default function RecommendSurvey({ level }) {
  const formattedLevel = level ? level.match(/\d+/)?.[0] : 'Unknown Level'

  const levelContent = levelTestAiResultValues.comentValues[level]

  return (
    <section className={styles.recommendSurvey}>
      <div className={styles.analytic}>
        <div className={classNames(styles.level, styles[`lv${formattedLevel}`])}>
          Level {formattedLevel}
        </div>
        {levelContent ? (
          <>
            <h4>{levelContent.title}</h4>
            <p>{levelContent.subTitle}</p>
          </>
        ) : (
          <p>레벨 정보를 불러올 수 없습니다.</p>
        )}
      </div>
    </section>
  )
}
