import ModalWrap from 'components/promotion/modal/ModalWrap'
import useLevelTestAiStore from 'store/useLevelTestAiStore'

import { levelTestAiValues } from '../config'
import styles from '../styles/ModalReCheck.module.scss'

export default function ModalReCheck({ onClickAgree, onClickCancel }) {
  const examType = useLevelTestAiStore((state) => state.examType)

  return (
    <ModalWrap>
      <div className={styles.modal}>
        <img
          src={`${levelTestAiValues.HOST_CDN}image-recheck-present-${examType}.png`}
          alt="present"
          className={styles.present}
        />
        <p className={styles.title}>
          야나두의 혜택 정보를
          <br />
          놓치고 있진 않으신가요?
        </p>
        <p className={styles.description}>신규 강의 정보와 할인 쿠폰 정보를 보내드릴게요!</p>
        <p className={styles.name}>
          <span>선택</span> SMS 광고 및 마케팅 이용 동의
        </p>
        <button className={styles.button} onClick={onClickAgree}>
          동의하기
        </button>
        <button className={styles.button} onClick={onClickCancel}>
          나중에
        </button>
      </div>
    </ModalWrap>
  )
}
