import { useCallback, useEffect, useRef, useState } from 'react'

import useScript from 'hooks/useScript'
import useSpeechToText from 'hooks/useSpeechToText'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

import { levelTestAiValues } from '../config'

export default function useLevelTestAiKlleon() {
  const avatarRef = useRef(null)

  const [loading, error] = useScript(levelTestAiValues.klleon.scriptSrc)
  const KlleonChat = window.KlleonChat
  const [minimumTimeCheck, setMinimumTimeCheck] = useState(true)
  const { handleRecognition } = useSpeechToText()

  const examType = useLevelTestAiStore((state) => state.examType)

  // 클레온 초기 세팅 & 스트리밍 시작
  const initKlleonChat = useCallback(async () => {
    if (KlleonChat) {
      await KlleonChat.init({
        sdk_key: levelTestAiValues.klleon.sdkKey,
        avatar_id: levelTestAiValues.klleon.avatarId[examType],
        enable_microphone: false,
        log_level: 'error',
        voice_code: 'en_us'
      })
    }
  }, [KlleonChat, examType])

  // 스크립트 불러오기 후 로딩이 끝나고 에러가 없을 때 클레온 초기 세팅
  useEffect(() => {
    if (examType === 'conversation') {
      handleRecognition.start()
    }
    if (!loading && !error) {
      initKlleonChat()
    }
  }, [loading, error, initKlleonChat, examType, handleRecognition])

  useEffect(() => {
    // 아바타 스트리밍 스타일 설정
    if (avatarRef.current) {
      avatarRef.current.videoStyle = {
        objectFit: 'cover'
      }
    }
    // 테스트 시작시 횟수 추가

    // 최소 3초 후에 로딩화면 제거
    setTimeout(() => {
      setMinimumTimeCheck(false)
    }, 3000)
  }, [])

  return { avatarRef, isLoading: loading || minimumTimeCheck }
}
