import React from 'react'

import cx from 'classnames'

import s from './ServiceTabNav.module.scss'

export default function ServiceTabNav({ handleTab, tab }) {
  return (
    <div className={s.csTabNav}>
      <button
        type="button"
        className={cx(s.csTabBtn, { [s.csTabActive]: tab.yanadoo })}
        onClick={() => handleTab('yanadoo')}
        disabled={tab.yanadoo}
      >
        야나두
      </button>
      <button
        type="button"
        className={cx(s.csTabBtn, { [s.csTabActive]: tab.yafit })}
        onClick={() => handleTab('yafit')}
        disabled={tab.yafit}
      >
        야핏 사이클
      </button>
    </div>
  )
}
