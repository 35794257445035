import React from 'react'
import { useNavigate } from 'react-router'

import InnerCol from 'components/service/InnerCol'

import SectionTitle from './common/SectionTitle'
import s from './styles/CustomerServiceCounsel.module.scss'

export default function CustomerServiceCounsel({ handleInquiry }) {
  const navigate = useNavigate()
  const onClickCounsel = (service) => {
    navigate(`/service/faq/ai?service=${service}`)
  }
  return (
    <section className={s.csHelp}>
      <InnerCol innerClass={s.counselInner}>
        <SectionTitle>
          <h3 className={s.counselTitle}>도움이 필요하신가요?</h3>
        </SectionTitle>

        <div className={s.counselBtn}>
          <p>원하는 답변을 찾지 못했나요?</p>
          <button type="button" onClick={() => handleInquiry()}>
            1:1 문의 등록
          </button>
        </div>
        <div className={s.helpAi}>
          <div className={s.helpAiInner}>
            <h5>365일, 24시간 언제든지</h5>
            <h3>
              <strong>AI 상담원</strong>에게 궁금한 점을 물어보세요!
            </h3>
            <div className={s.btns}>
              <button
                type="button"
                className={s.btnAiCounsel}
                onClick={() => onClickCounsel('YANADOO')}
              >
                야나두 AI 상담하기
              </button>
              <button
                type="button"
                className={s.btnAiCounsel}
                onClick={() => onClickCounsel('YAFIT')}
              >
                야핏 AI 상담하기
              </button>
            </div>
          </div>
        </div>
      </InnerCol>
    </section>
  )
}
