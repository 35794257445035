import { BrowserView, MobileView } from 'react-device-detect'

import MetaInfo from 'components/common/MetaInfo'

import ButtonSection from './components/ButtonSection'
import FeatureSection from './components/FeatureSection'
import InfoSection from './components/InfoSection'
import PCTopSection from './components/PCTopSection'
import TopSection from './components/TopSection'

import { levelTestAiValues } from '../exam/config'

export default function LevelTestAiStart() {
  return (
    <>
      <MetaInfo props={levelTestAiValues.metaInfo} />
      <MobileView>
        <TopSection />
      </MobileView>
      <BrowserView>
        <PCTopSection />
      </BrowserView>
      <ButtonSection />
      <FeatureSection />
      <InfoSection />
    </>
  )
}
