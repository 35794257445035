import React, { useState } from 'react'

import { v4 as uuid } from 'uuid'

import { LTA_HOST_CDN } from '../config'
import styles from '../styles/Answer.module.scss'

export default function Answer({ idx, questionValue, userResult }) {
  const questionNumber = idx + 1
  const { type, text1, text2, text3, question, buttonGroup, comment, answerType } = questionValue
  const isCorrect = userResult[idx] === buttonGroup.answer
  /** 코멘트 토글 */
  const [commentToggle, setCommentToggle] = useState(false)
  const handleToggleComment = () => {
    setCommentToggle((prev) => !prev)
  }
  const typeNames = {
    ox: 'OX 퀴즈',
    blank: '빈칸 채우기',
    translation: '번역'
  }
  return (
    <article className={styles.article}>
      <h4 className={styles.caption}>
        Q{questionNumber}. {typeNames[type]}
      </h4>
      <ul>
        <li>
          <p className={styles.th}>문제</p>
          <div className={styles.td}>
            <p className={styles.param01}>{question}</p>

            {/* 문제 타입별 스타일 상이 */}
            <div className={styles[type]}>
              {text1 && <p className={styles.param02}>{text1}</p>}
              {text2 && <p className={styles.param02}>{text2}</p>}
              {answerType !== 'type3' && text3 && <p className={styles.param03}>{text3}</p>}
            </div>
          </div>
        </li>

        <li>
          <p className={styles.th}>선택</p>
          <div className={styles.td}>
            <div className={`${styles.choice} ${answerType === 'type3' ? styles.block : ''}`}>
              {type === 'ox'
                ? buttonGroup.list.map((answer) => (
                    <p key={uuid()} className={answer === userResult[idx] ? styles.checked : ''}>
                      <img
                        src={`${LTA_HOST_CDN}ico_answer_${answer.toLowerCase()}.svg`}
                        alt={answer}
                        className={styles.oxImage}
                      />
                    </p>
                  ))
                : buttonGroup.list.map((answer) => (
                    <p key={uuid()} className={answer === userResult[idx] ? styles.checked : ''}>
                      {answer}
                    </p>
                  ))}
            </div>
          </div>
        </li>

        <li>
          <p className={styles.th}>정답</p>
          <div className={styles.td}>
            <div className={styles.answer}>
              {type === 'ox' ? (
                <img
                  src={`${LTA_HOST_CDN}ico_answer_${buttonGroup.answer.toLowerCase()}.svg`}
                  alt={buttonGroup.answer}
                  className={styles.oxImage}
                />
              ) : (
                <p className={styles.param03}>{buttonGroup.answer}</p>
              )}
              <button
                type="button"
                onClick={handleToggleComment}
                className={commentToggle ? styles.active : ''}
              >
                {commentToggle ? '해설 닫기' : '해설 보기'}
              </button>
            </div>
          </div>
        </li>

        {commentToggle && (
          <li className={styles.commentary}>
            <p className={styles.th}></p>
            <div className={styles.td}>
              <p className={styles.param04}>{comment}</p>
            </div>
          </li>
        )}

        <li className={`${styles.resultItem} ${isCorrect ? styles.correct : styles.incorrect}`}>
          <p className={styles.th}>채점</p>
          <div className={styles.td}>
            <div className={styles.marker}>
              <div className={styles.resultMessage}>
                {isCorrect ? '문제를 맞췄어요 👍' : '아쉽게 틀렸네요 😭'}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </article>
  )
}
