import React from 'react'
import { useNavigate } from 'react-router'

import cx from 'classnames'

import s from './BtnMore.module.scss'

export default function BtnMore({ link, title, btnClass }) {
  const navigate = useNavigate()

  const handleBtnClick = (url) => {
    navigate(url)
  }
  return (
    <button
      type="button"
      className={cx(s.btnMore, { [btnClass]: btnClass })}
      onClick={() => handleBtnClick(link)}
    >
      {title}
    </button>
  )
}
