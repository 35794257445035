import React from 'react'

import cx from 'classnames'

import s from './Pagination.module.scss'
import SvgArrow from '../../pages/service/SvgArrow'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const renderPageNumbers = () => {
    const pageNumbers = []
    let startPage = Math.max(1, currentPage - 2)
    let endPage = Math.min(totalPages, currentPage + 2)

    if (endPage - startPage < 4) {
      startPage = Math.max(1, endPage - 4)
    }

    if (endPage - startPage < 4) {
      endPage = Math.min(totalPages, startPage + 4)
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={cx(s.pagingBtn, s.number, { [s.active]: i === currentPage })}
          type="button"
        >
          {i}
        </button>
      )
    }

    return pageNumbers
  }

  if (totalPages < 2) return null

  return (
    <div className={s.paginationWrap}>
      <button
        onClick={handlePrevious}
        className={cx(s.pagingBtn, s.btnArrow, s.btnPrev)}
        disabled={currentPage === 1}
        type="button"
      >
        <SvgArrow stroke={currentPage === 1 ? '#A0A0B6' : '#28284B'} />
      </button>
      <div className={s.numbers}>{renderPageNumbers()}</div>
      <button
        onClick={handleNext}
        className={cx(s.pagingBtn, s.btnArrow, s.btnNext)}
        disabled={currentPage === totalPages}
        type="button"
      >
        <SvgArrow stroke={currentPage === totalPages ? '#A0A0B6' : '#28284B'} />
      </button>
    </div>
  )
}

export default Pagination
