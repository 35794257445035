import React from 'react'

import InnerCol from 'components/service/InnerCol'

import s from './styles/CustomerServiceSurvey.module.scss'

export default function CustomerServiceSurvey() {
  return (
    <section className={s.csSurvey}>
      <InnerCol>
        <div className={s.surveyCont}>
          <span>서비스 조사</span>
          <h4>
            더 나은 서비스를 위해
            <br />
            서비스 개선 의견을 작성해주세요.
          </h4>
          <a
            className={s.btnSurvey}
            href="https://forms.gle/A9R9otLYSYW2sFCd7"
            target="_blank"
            rel="noreferrer"
          >
            참여하기
          </a>
        </div>
      </InnerCol>
    </section>
  )
}
