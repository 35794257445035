import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

const initialState = {
  examType: null, // 테스트유형 : 'quiz'(퀴즈형) || 'conversation'(대화형)
  answers: [], // 정답리스트 ex) ["X",  "O",  "by",  "light",  "shot",  "baked",  "short",  "sea",  "나는 우울해",  "이제 운동하러 갈거야."]
  recommend: '', // 레벨별 추천상품 이름 ex) 'aiRealTalk'
  totalTime: 0, // 총 소요시간 ex) '05:10'
  feedbackData: null // 대화형 테스트 결과 데이터
}

const useLevelTestAiStore = create(
  devtools((set) => ({
    ...initialState,
    setExamType: (examType) => set({ examType }),
    setAnswers: (answers) => set({ answers }),
    setRecommend: (recommend) => set({ recommend }),
    setTotalTime: (totalTime) => set({ totalTime }),
    setFeedbackData: (feedbackData) => set({ feedbackData }),
    resetState: () => set({ ...initialState })
  }))
)

export default useLevelTestAiStore
