import useGetUser from 'hooks/useGetUser'

import { levelTestAiResultValues } from '../config'
import { useSetGrade } from '../hooks/useSetGrade'
import styles from '../styles/RecommendLevelProduct.module.scss'

export default function RecommendLevelProduct({ level }) {
  // level 값이 유효한지 확인하고, 숫자만 추출
  const formattedLevel = level ? level.match(/\d+/)?.[0] : 'Unknown Level'

  // levelTestAiResultValues.productRecommended에서 레벨에 맞는 상품 추천 정보 가져오기
  const product = levelTestAiResultValues.productRecommended[level]

  const { user } = useGetUser()

  return (
    <section className={styles.levelProduct}>
      <div className={styles.levelTitle}>
        <p>Level{formattedLevel} 진단을 받은</p>
        {user && (
          <h4>
            {user.userName}님을 위해 <br />
            추가로 상품을 추천해 드려요!
          </h4>
        )}
      </div>

      <div className={styles.recommendedProducts}>
        {product ? (
          <div className={styles.product}>
            {/* a 태그로 링크 설정 */}
            <a href={`/${product.productLink}`} target="_blank" rel="noopener noreferrer">
              <img src={product.jpgSrc} alt={product.productName} className={styles.productName} />
            </a>
            <div className={styles.productDetails}>
              <strong>👉 {product.productName}</strong>
              <p className={styles.productDescription}>{product.productDescription}</p>
            </div>
          </div>
        ) : (
          <p>추천 상품이 없습니다.</p>
        )}
      </div>
    </section>
  )
}
