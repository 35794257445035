import classNames from 'classnames'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

import { levelTestAiResultValues } from 'pages/levelTestAi/result/config'

import RecommendCounsel from './RecommendCounsel'
import RecommendProduct from './RecommendProduct'
import { levelTestAiValues } from '../config'
import styles from '../styles/StepConsultation.module.scss'

export default function StepConsultation({ survey }) {
  const examType = useLevelTestAiStore((state) => state.examType)
  const recommend = useLevelTestAiStore((state) => state.recommend)
  const recommendData = levelTestAiResultValues.recommended[recommend]

  return (
    <div className={classNames(styles.container, styles[examType])}>
      <img
        src={`${levelTestAiValues.HOST_CDN}image-consultation-${examType}.png`}
        alt=""
        className={styles.topImage}
      />
      <p className={styles.title}>
        전문가와 전화 상담 후, 구매하면
        <br />
        <b>최대 10만원</b>의 할인 혜택을 드려요!
      </p>

      {/* 상담 신청 폼 */}
      <RecommendCounsel survey={survey} />
      {/* 추천 상품 목록 */}
      {recommendData && <RecommendProduct recommendData={recommendData} />}
    </div>
  )
}
