import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { getCookie, setCookie } from 'common/Cookie'
import Loading from 'components/common/Loading'

import styles from './ModalPromotionEvent.module.scss'
import ModalWrap from './ModalWrap'

export default function ModalPromotionEvent({ popupInfo }) {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseDate = (date) => {
    const day = date === '오늘 하루 열지 않기' ? 1 : date === '7일간 열지 않기' ? 7 : 0
    if (day !== 0) {
      setCookie(`popupClose${popupInfo.seq}`, 'N', day)
    }
    handleClose()
  }

  const LinkContent = ({ link, children }) =>
    link ? (
      <Link to={link} onClick={handleClose}>
        {children}
      </Link>
    ) : (
      <button onClick={handleClose}>{children}</button>
    )

  const containerRef = useRef(null)
  const handleImageLoad = useCallback(() => {
    if (!popupInfo || !popupInfo.image) {
      console.warn('handleImageLoad: popupInfo 또는 image 정보가 유효하지 않습니다.')
      return
    }

    const img = new Image()

    img.onload = () => {
      if (containerRef.current) {
        containerRef.current.style.aspectRatio = `${img.width} / ${img.height}`
        setIsImageLoaded(true)
      } else {
        console.warn(
          'handleImageLoad: containerRef가 null입니다. DOM이 아직 렌더링되지 않았을 수 있습니다.'
        )
      }
    }

    img.onerror = (error) => {
      console.error('handleImageLoad: 이미지 로드에 실패했습니다.', error)
    }

    img.src = popupInfo.image
  }, [popupInfo])

  useEffect(() => {
    if (popupInfo !== null && popupInfo !== undefined && popupInfo.displayYn === 'Y') {
      const isPopupOpen = getCookie(`popupClose${popupInfo.seq}`) !== 'N'
      setOpen(isPopupOpen)

      if (isPopupOpen) {
        handleImageLoad()
      }
    }

    return () => setOpen(false)
  }, [popupInfo, handleImageLoad])

  return (
    <>
      {open ? (
        <ModalWrap
          onClose={handleClose}
          dateClose={
            popupInfo.closeType === '옵션 없음'
              ? null
              : { onClick: () => handleCloseDate(popupInfo.closeType), title: popupInfo.closeType }
          }
          btn
        >
          <div className={styles.container} ref={containerRef}>
            <LinkContent link={popupInfo.link ? popupInfo.link : null}>
              {isImageLoaded ? <img src={popupInfo.image} alt={popupInfo.title} /> : <Loading />}
            </LinkContent>
          </div>
        </ModalWrap>
      ) : null}
    </>
  )
}
