import React from 'react'

import { LTA_HOST_CDN } from 'pages/levelTestAdvanced/config'

import styles from '../styles/Top.module.scss'

export default function Top({ onClick }) {
  return (
    <hgroup className={styles.hgroup}>
      <button type="button" onClick={onClick}>
        <img src={`${LTA_HOST_CDN}ico_back.svg`} alt="뒤로가기" />
      </button>
      <h3>추천 상품</h3>
    </hgroup>
  )
}
