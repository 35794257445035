import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import useHeaderHeightStore from 'store/useHeaderHeightStore'

import { YANADOO_CDN_HOST_BENEFIT } from 'pages/promotion/newBenefit/config'

import styles from './HeaderBanner.module.scss'

export default function HeaderBanner() {
  const bannerRef = useRef(null)
  const isShowBanner = useHeaderHeightStore((state) => state.isShowBanner)
  const setIsShowBanner = useHeaderHeightStore((state) => state.setIsShowBanner)
  const headerBannerHeight = useHeaderHeightStore((state) => state.headerBannerHeight)
  const setHeaderBannerHeight = useHeaderHeightStore((state) => state.setHeaderBannerHeight)

  useLayoutEffect(() => {
    const hideUntil = localStorage.getItem('hideBannerUntil')
    if (hideUntil) {
      const hideUntilDate = new Date(hideUntil)
      const now = new Date()
      if (now < hideUntilDate) {
        setIsShowBanner(false)
      } else {
        localStorage.removeItem('hideBannerUntil')
        setIsShowBanner(true)
      }
    } else {
      setIsShowBanner(true)
    }
  })

  const handleClose = () => {
    const todayEnd = new Date()
    todayEnd.setHours(23, 59, 59, 999) // 오늘 23:59:59로 설정
    localStorage.setItem('hideBannerUntil', todayEnd.toISOString()) // 쿠키를 오늘 23:59:59에 만료되도록 설정
    setIsShowBanner(false)
  }

  // 헤더가 변경될 때 마다 높이값 가져와서 store 저장하는 함수
  useEffect(() => {
    const bannerElement = bannerRef.current
    if (!bannerElement) return
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0]
      const newHeight = entry.contentRect.height
      if (headerBannerHeight !== newHeight) {
        window.requestAnimationFrame(() => {
          setHeaderBannerHeight(newHeight)
        })
      }
    })
    resizeObserver.observe(bannerElement)
    return () => {
      resizeObserver.unobserve(bannerElement)
    }
  }, [headerBannerHeight, setHeaderBannerHeight])

  useEffect(() => {
    setHeaderBannerHeight(bannerRef.current?.clientHeight || 0)
  }, [isShowBanner, setHeaderBannerHeight])

  return (
    <>
      {isShowBanner && (
        <nav className={styles.nav} ref={bannerRef}>
          <div className={styles.inner}>
            <Link to="/promotion-landing/newBenefit" className={styles.text}>
              <img
                className={styles.gift}
                src={`${YANADOO_CDN_HOST_BENEFIT}ico_gift.svg`}
                alt="선물상자 아이콘"
              />
              <p>가입 시 100% 쿠폰 + 인기강의 무료 체험권 지급 </p>
              <img
                className={styles.arrow}
                src={`${YANADOO_CDN_HOST_BENEFIT}ico_arrow.svg`}
                alt="화살표"
              />
            </Link>

            <button
              className={styles.close}
              onClick={() => {
                handleClose()
              }}
            >
              닫기
            </button>
          </div>
        </nav>
      )}
    </>
  )
}
