import classNames from 'classnames'
import useGetUser from 'hooks/useGetUser'

import { levelTestAiValues } from '../config'
import styles from '../styles/RecommendProduct.module.scss'

export default function RecommendProduct({ recommendData }) {
  const { user } = useGetUser()

  return (
    <div className={styles.container}>
      <p className={styles.subTitle}>{recommendData.description}</p>
      <p className={styles.title}>
        {user?.userName?.slice(0, 4)}
        {user?.userName?.length > 4 && '...'}님에게 딱 맞는 추천 상품!
      </p>
      <ul
        className={classNames(
          styles.productList,
          recommendData.products.length === 1 && styles.full
        )}
      >
        {recommendData.products.map((item, index) => (
          <li key={index} className={styles[item.productName]}>
            <img
              src={
                item.productName
                  ? `${levelTestAiValues.HOST_CDN}image-recommend-${item.productName}.png`
                  : item.image
              }
              alt={item.name}
            />
            <div className={styles.textWrap} style={{ color: item.color }}>
              <p className={styles.name}>{item.name}</p>
              <p className={styles.description}>{item.description}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
