import React from 'react'

import useGetKeywords from 'pages/service/hooks/useGetKeywords'

import InnerCol from 'components/service/InnerCol'
import SearchForm from 'components/service/search/SearchForm'
import SearchNavigate from 'components/service/search/SearchNavigate'
import SearchPopular from 'components/service/search/SearchPopular'

import s from './FaqSearch.module.scss'

export default function FaqSearch({ isMobile }) {
  const { keywords } = useGetKeywords()
  return (
    <section className={s.faqSearchSection}>
      <InnerCol innerClass={s.csInner}>
        {!isMobile && <SearchNavigate />}
        <div className={s.faqSearchWrap}>
          <SearchForm classes={s.faqSearchBox} />
          <SearchPopular keys={keywords} />
        </div>
      </InnerCol>
    </section>
  )
}
