import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate, useParams } from 'react-router'

import CustomerServiceCounsel from 'pages/service/center/components/CustomerServiceCounsel'
import AuthService from 'services/authService'

import ModalMyInquiry from 'components/modal/ModalMyInquiry'
import MobileTitleBar from 'components/service/MobileTitleBar'
import useModalLogin from 'store/useModalLogin'

import FaqSearch from './FaqSearch'
import FaqSearchList from './FaqSearchList'
import s from './FaqSearchResult.module.scss'

export default function FaqSearchResult({ isPlatform }) {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const keyword = queryParams.get('key')
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const { currentSeq } = useParams()
  // const classSeq = queryParams.get('classSeq')
  // const lectureSeq = queryParams.get('lectureSeq')

  const [inquiry, setInquiry] = useState(false)

  const { setIsModalLogin } = useModalLogin()

  const handleInquiry = () => {
    if (!isPlatform) {
      if (!AuthService.getUserInfo()) {
        setIsModalLogin(true)
        return
      }
    }

    setInquiry(true)
  }

  const handleBackButton = () => {
    if (!isPlatform) {
      navigate(-1)
    } else {
      if (document.referrer === '') {
        console.log('닫기')
      } else {
        navigate(-1)
      }
    }
  }

  return (
    <>
      {isMobile && !isPlatform && (
        <MobileTitleBar
          title={currentSeq === '1' ? '고객센터' : '자주 묻는 질문'}
          onClick={handleBackButton}
        />
      )}
      <article className={s.faqSearchResult}>
        <FaqSearch isMobile={isMobile} />
        <FaqSearchList keyword={keyword} />
        <CustomerServiceCounsel handleInquiry={handleInquiry} />
      </article>
      {inquiry && <ModalMyInquiry btnClose handleCloseModal={() => setInquiry(false)} />}
    </>
  )
}
